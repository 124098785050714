import React, { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useTranslation } from "react-i18next";
import { OPTIONS_LANGUAGE, OPTIONS_LANGUAGE_LEVEL } from "@/constants";
import { AlertCircle, TrashIcon } from "lucide-react";
import { v4 } from "uuid";
import { MONTHLY_RATE as MONTHLY_RATES } from "@/profile.constants";
import AppBarForLogin from "@/components/app-bar-for-main-login-pages/AppBarForLogin";
import { getUser, touchConditionStage } from "@/service/weexpertService";
import { Combobox } from "@/components/ui/combobox";
import { toast } from "@/components/ui/use-toast";
import { Alert, AlertTitle } from "@/components/ui/alert";

export const LanguageAbility = ({
  onLanguageChange,
  onLanguageDelete,
  languageEntry,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { id, proficiency, language } = languageEntry;
  const [level, setLevel] = useState(proficiency);
  const [currentLanguage, setCurrentLangauge] = useState(language);

  useEffect(() => {
    onLanguageChange(id, currentLanguage, level);
  }, [currentLanguage, level]);

  return (
    <div key={id}>
      <div className="flex justify-between">
        <Combobox
          className={"w-1/2 mb-2"}
          label={t("survey:label_language")}
          value={currentLanguage}
          placeholder={t("survey:placeholder_languages")}
          disabled={disabled}
          options={OPTIONS_LANGUAGE().map((e) => {
            return {
              value: e.id,
              label: e.value,
            };
          })}
          onChange={(newValue) => {
            setCurrentLangauge(newValue);
          }}
        />
        <Combobox
          className={"w-1/3 mb-2"}
          label={t("survey:label_knowledge")}
          value={level}
          placeholder={t("survey:label_knowledge")}
          disabled={disabled}
          options={OPTIONS_LANGUAGE_LEVEL().map((e) => {
            return {
              value: e.id,
              label: e.value,
            };
          })}
          onChange={(newValue) => {
            setLevel(newValue);
          }}
        />
        {!disabled && (
          <div
            className={"flex items-center justify-center cursor-pointer"}
            onClick={() => onLanguageDelete(id)}
          >
            <TrashIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export const ProfileStepTwoPage = ({ back, next, updateState }) => {
  const { t } = useTranslation();
  const { data: userData, isLoading: loading } = useQuery({
    queryKey: ["userMeProfile"],
    queryFn: getUser,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { profile } = data.data;
      if (!profile) {
        return;
      }
      if (profile.monthlyRate) {
        setMonthlyRate({ value: profile.monthlyRate });
      }
      if (profile.hourlyRate) {
        setHourlyRate(profile.hourlyRate);
      }
      if (profile.languages) {
        const clientsSavedLanguages = profile.languages.map((e) => {
          const a = {
            id: v4(),
            language: {
              value:
                OPTIONS_LANGUAGE().filter((p) => p.id == e.code)[0]?.id ?? v4(),
              label: e.name,
            },
            proficiency:
              OPTIONS_LANGUAGE_LEVEL()
                .filter((p) => p.id == e.proficiency)
                .map((s) => {
                  return {
                    value: s.id,
                    label: s.value,
                  };
                })[0] ?? null,
          };
          return a;
        });
        setLanguagesData(
          clientsSavedLanguages.length != 0
            ? clientsSavedLanguages
            : [{ id: 1, language: null, proficiency: null }],
        );
      }
    },
  });
  const [monthlyRate, setMonthlyRate] = useState();
  const [hourlyRate, setHourlyRate] = useState(0);
  const [languagesData, setLanguagesData] = useState([]);
  const [inputChecked, setInputChecked] = useState(false);

  const onLanguageUpdate = (id, language, proficiency) => {
    if (
      languagesData.some(
        (l) =>
          l.language && l.language?.value === language?.value && id != l.id,
      )
    ) {
      toast({
        title: t("survey:error_duplicate_language"),
        variant: "destructive",
      });
    }

    setLanguagesData((prevState) => {
      return prevState.map((e) => {
        if (e.id == id) {
          return {
            id: id,
            language,
            proficiency,
          };
        }
        return e;
      });
    });
  };
  const onLanguageDelete = (id) => {
    setLanguagesData((prevState) => {
      return prevState.filter((e) => e.id != id);
    });
  };

  const checkErrorLanguage = () => {
    return (
      inputChecked &&
      languagesData.some((item) => !item.language && !item.proficiency)
    );
  };

  const languageItem = languagesData.map((item) => (
    <div key={item.id}>
      <LanguageAbility
        languageEntry={item}
        onLanguageChange={onLanguageUpdate}
        onLanguageDelete={onLanguageDelete}
      />
    </div>
  ));

  const addNextLanguageKnowleges = () => {
    setLanguagesData((prev) =>
      prev.concat({
        id: prev.length + 1,
        language: null,
        proficiency: null,
      }),
    );
  };

  const handleHourlyRateChange = (e) => {
    const value = Math.max(1, Math.min(300, e.target.value));
    setHourlyRate(value);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={"container"}>
      <AppBarForLogin isLogined={true} userData={userData.data} />
      <div className="pt-24 pb-28">
        <div className="text-3xl font-semibold w-96 pb-6">
          Step 2/4: Your additional information
        </div>
        <div className="pb-6 text-base">
          What’s your rate, localization and language skills?
        </div>
        <div className="w-1/3 mb-4">
          <Combobox
            className={"my-2"}
            key={monthlyRate?.value}
            label={"Monthly rate (USD)"}
            placeholder={"Monthly rate (USD)"}
            creatable
            value={monthlyRate}
            error={inputChecked && !monthlyRate}
            errorMessage={t("empty_data_invalid")}
            options={MONTHLY_RATES}
            onChange={(newValue) => {
              setMonthlyRate(newValue);
            }}
          />
          <InputWithAlert
            type="number"
            label={"Hourly rate (USD)"}
            placeholder={"Enter hourly rate"}
            value={hourlyRate}
            onChange={handleHourlyRateChange}
            error={inputChecked && !hourlyRate}
            errorMessage={t("empty_data_invalid")}
          />
          <div className="knowlege-of-language-wrapper pt-4">
            {languageItem}
          </div>
          {checkErrorLanguage() && (
            <div className="mt-1">
              <Alert
                variant="destructive"
                className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]"
              >
                <AlertCircle className="h-4 w-4 !static !mr-2" />
                <AlertTitle className="m-0 !p-0">
                  {t("empty_data_invalid")}
                </AlertTitle>
              </Alert>
            </div>
          )}
          <Button
            onClick={() => {
              addNextLanguageKnowleges(languagesData);
            }}
          >
            +
          </Button>
        </div>
      </div>
      <Progress value={50} />
      <div className=" flex flex-row justify-between	 pt-6">
        <Button
          className="w-36"
          variant="outline"
          onClick={() => {
            if (back) {
              back();
            }
          }}
        >
          Back
        </Button>
        <Button
          className="w-36"
          onClick={() => {
            setInputChecked(true);
            if (!hourlyRate || !monthlyRate || !languagesData) {
              return;
            }
            return touchConditionStage(
              +hourlyRate,
              monthlyRate.value,
              languagesData.map((e) => ({
                code: e.language.value,
                proficiency: e.proficiency.value,
              })),
            ).then(() => next());
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
