import * as React from 'react';
import { useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseDialog } from '@/components/popups/dialog';
import { Button } from '@/components/ui/button';
import { Cross1Icon } from '@radix-ui/react-icons';

export const UnpinClientFromContractor = (props) => {
    const { onConfirm,subHeader, className} = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    return (
        <>
            <BaseDialog
                dialogTitle={t("unassign_client_to_counterparty")}
                buttonText={""}
                open={open}
                setOpen={setOpen}
                className={className}
                icon={<Cross1Icon />}
            >
                <div className="w-[500px] ">
                    <div className='text-base'>{subHeader}</div>
                    <div className='w-full flex justify-end'>
                        <Button className='mr-2' onClick={() => setOpen(false)}>
                            {t("button_back")}
                        </Button>
                        <Button onClick={() => {
                            onConfirm()
                        }}>
                            {t("users:unpin")}
                        </Button>

                    </div>
                </div>
            </BaseDialog>
        </>
    )
}