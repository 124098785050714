import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PAGINATION_SETTINGS, rolesForUsersCreate } from '../constants';
import { getUsers } from '../service/weexpertService';
import { useTranslation } from "react-i18next";
import { DataTablePagination } from "@/components/ui/table-pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { Input } from '@/components/ui/input.jsx';
import { Button } from '@/components/ui/button.jsx';
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useQuery } from "react-query";
import { Skeleton } from "@mui/material";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";

const columns = (t) => {
  return [
    {
      accessorKey: "name",
      header: t('name_last_name'),
      cell: ({ row }) => {
        return (<Link to={`/admin/user/details/${row.original.id}`}>
          {row.original.firstName} {row.original.lastName}
        </Link>)
      },
      key: uuidv4()
    },
    {
      accessorKey: "email",
      header: 'Email',
      cell: ({ row }) => (
        <Link to={`/admin/user/details/${row.original.id}`}>
          {row.original.email}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "phone",
      header: t('phone'),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/admin/user/details/${row.original.id}`}>
          {row.original.phone}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "roles",
      header: t('users:role'),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/admin/user/details/${row.original.id}`}>
          {rolesForUsersCreate(t).filter(i => i.value === row.original.roles)[0]?.display ?? 'Role uknown'}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "status",
      header: t('status'),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/admin/user/details/${row.original.id}`}>
          {row.original.isActive ?
            (<Badge className={"bg-green-500"}>{t('active')}</Badge>) :
            (<Badge className={"bg-red-500"}>{t('unactive')}</Badge>)
          }
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "dateOfCreation",
      header: t('created'),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/admin/user/details/${row.original.id}`}>
          {format(new Date(row.original.createdAt), 'dd/MM/yyyy')}
        </Link>
      ),
      key: uuidv4()
    }
  ]
}

const UsersPage = () => {

  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_SETTINGS.users);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: rowsPerPage });
  const { data: users, isLoading } = useQuery({ queryKey: ['users', page, rowsPerPage], queryFn: () => { return getUsers(rowsPerPage, page * rowsPerPage) } });
  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
  }, [pagination])

  const table = useReactTable({
    data: isLoading ? Array(rowsPerPage).fill({}) : users?.data.users,
    columns: isLoading ? columns(t).map((column) => {
      return {
        ...column,
        cell: () => {
          return <Skeleton />
        }
      }
    }) : columns(t),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: users?.data.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">
              {t("users:user_title")}
            </div>
            <div className="flex w-5/6">
              <Input
                disabled
                placeholder={t('search')}
                onChange={(event) => {}}
                className="mr-2 ml-8 bg-white"
              />

              <Link to='/admin/create/user'>
                <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                  {t("users:button_add_user")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  );
}
export default UsersPage;
