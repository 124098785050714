import * as React from "react"
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table"
import { useQueryState } from "./invoices.page"
import { useState, useEffect } from "react";
import { PAGINATION_SETTINGS, SKILLS_TYPE, PROGRAMMING_LANGUAGE_TYPE, TOOLS_AND_TECHNOLOGY_TYPE, FRAMEWORK_TYPE, AVAILABILITY_HOURS } from "@/constants";
import { getFreelancersProfiles } from "@/service/weexpertService";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { BaseDialog } from "@/components/popups/dialog"
import MultipleSelector from "@/components/ui/multiple-selector"
import { DataTablePagination } from "@/components/ui/table-pagination"
//end import for invoicesPage
const resolveAvailabilityLabel = (hours, t) => {
    const options = AVAILABILITY_HOURS(t);
    const option = options.find(e => e.value == hours);
    return !option ? hours : option.label;
}


const FreelancersFilter = ({ onFilterChange, currentFilter }) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState([]);
    const [unprocessedFilters, setUnprocessedFilter] = useState({
        selectedSkills: [],
        selectedTools: [],
        selectedProgrammingLanguages: [],
        selectedFrameworks: []
    })
    const transformAutocompleteValueToFilter = (valueArray, field) => {
        return {
            field: field,
            value: valueArray.map(e => e.label)
        }
    }

    const [open, setOpen] = useState(false)

    const [selectedSkills, setSelectedSkills] = useState(currentFilter?.selectedSkills ?? []);
    const [selectedTools, setSelectedTools] = useState(currentFilter?.selecteTools ?? []);
    const [selectedProgrammingLanguages, setSelectedProgrammingLanguages] = useState(currentFilter?.selectedProgrammingLanguages ?? []);
    const [selectedFrameworks, setSelectedFrameworks] = useState(currentFilter?.selectedFrameworks ?? []);

    useEffect(() => {
        const abilities = [].concat([...selectedSkills,
        ...selectedTools,
        ...selectedProgrammingLanguages,
        ...selectedFrameworks
        ]);
        const selectedAbilities = transformAutocompleteValueToFilter(abilities, 'abilities');
        setFilters({
            abilities: selectedAbilities
        })
        setUnprocessedFilter({
            selectedSkills,
            selectedTools,
            selectedProgrammingLanguages,
            selectedFrameworks
        })
    }, [selectedSkills, selectedTools, selectedProgrammingLanguages, selectedFrameworks])

    return <BaseDialog
        dialogTitle={t('filter')}
        buttonText={t('filter')}
        open={open}
        setOpen={(open) => {
            setOpen(open)
            if (!open) { // if closed we want to trigger filters
                onFilterChange(filters, unprocessedFilters)
            }
        }
        }


    >
        <div className="w-[420px] p-4">
            <div>
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:label_Skills")}
                    badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                    value={selectedSkills}
                    inputClassName={'h-12 my-2 focus-visible:border-[0px]'}
                    errorMessage={t("survey:error_select")}
                    label={t("survey:label_Skills")}
                    onChange={(newSelected) => {
                        setSelectedSkills(newSelected)
                    }}
                    options={SKILLS_TYPE()}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:placeholder_languages")}
                    badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                    value={selectedProgrammingLanguages}
                    label={t("survey:label_programming_languages")}
                    onChange={(newSelected) => {
                        setSelectedProgrammingLanguages(newSelected)
                    }}
                    options={PROGRAMMING_LANGUAGE_TYPE()}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:placeholder_frameworks")}
                    badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                    value={selectedFrameworks}
                    label={t("survey:label_frameworks")}
                    options={FRAMEWORK_TYPE()}
                    onChange={(newSelected) => {
                        setSelectedFrameworks(newSelected)
                    }}
                />
                <MultipleSelector
                    creatable={true}
                    className="min-h-12 mt-2"
                    placeholder={t("survey:placeholder_tools")}
                    badgeClassName={"bg-emerald-100 hover:bg-emerald-100 text-black"}
                    value={selectedTools}
                    errorMessage={'please select 1 skill'}
                    label={t("survey:label_tools")}
                    options={TOOLS_AND_TECHNOLOGY_TYPE()}
                    onChange={(newSelected) => { setSelectedTools(newSelected) }}
                />
                <div className="w-full flex justify-end pt-6">
                    <div className="w-[79%] flex justify-between">
                        <Button variant="outline" className="w-[150px]" onClick={() => {
                            onFilterChange([], {})
                            setOpen(false)
                        }}>{t("common:button_clean_filter")}</Button>
                        <Button className="w-[150px]" onClick={() => {
                            onFilterChange(filters, unprocessedFilters)
                            setOpen(false)
                        }}>{t("common:button_save")}</Button>
                    </div>
                </div>

            </div>
        </div>
    </BaseDialog>
}

export function FreelancersPage(props) {


    //states & logics for invoicesPage
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [searchQuery, setSearchQuery] = useState(null);

    const [sharedFilter, setSharedFilter] = useQueryState('f')
    const [filters, setFilters] = useState(JSON.parse(sharedFilter ?? '[]').f ?? []);
    const [unprocessedFilter, setUnprocessedFilter] = useState(JSON.parse(sharedFilter ?? '{}').uf ?? {})

    const [realData, setRealData] = useState('');
    const [page, setPage] = useState(0);
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState([]);
    const [visible, setVisible] = useState(false);

    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [rowsPerPage, setRowsPerPage] = useState(pagination.pageSize);
    const [total, setTotal] = useState(null);

    useEffect(() => {
        getFreelancersProfiles(rowsPerPage, page * rowsPerPage, searchQuery, Object.values(filters))
            .then(res => {
                setRealData(res.data.profiles);
                setTotal(res.data.total)
            })
    }, [rowsPerPage, page, filters, searchQuery]);

    useEffect(() => {
        if (selectedItems.length > 0) {
            setVisible(true)
            console.log(selectedItems)
        }
        else {
            setVisible(false)
        }
    }, [selectedItems.length])
    const columns = [
        {
            accessorKey: "client",
            header: t("common:client"),
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {`${row.getValue("client").firstName} ${row.getValue("client").lastName}`}
                </Link>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "state",
            header: t("common:status"),
            cell: ({ row }) => (
                <div className={`capitalize w-24 ${row.getValue("state") === 'NOT_APPROVED' ? 'bg-pirpleLightBg rounded text-pirpleText text-center' : 'bg-greenBgLite rounded text-center text-greenText'}`}>{row.getValue("state") === 'NOT_APPROVED' ? t("invoices:status_in_progress") : t("invoices:status_complete")}</div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "Email",
            header: "Email",
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {row.getValue("client").email}
                </Link>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "currentJobTitle",
            header: "Current job Title/Role",
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {row.getValue("currentJobTitle")}
                </Link>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "currentExperience",
            header: "Experience",
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {row.getValue("currentExperience")}
                </Link>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "abilities",
            header: "Skills",
            cell: ({ row }) => {
                const skillNames = [];
                row.original.abilities.forEach(item => {
                    if (item.abilityType === "Skill") {
                        skillNames.push(item.ability.name);
                    }
                });
                return (
                    <Link className="capitalize" to={`/admin/freelancer/details/${row.original.client.id}`}>
                        {skillNames.join(", ")}
                    </Link>
                );
            },
            key: uuidv4()
        },

        {
            accessorKey: "abilities",
            header: "Tools",
            cell: ({ row }) => {
                const skillNames = [];
                row.original.abilities.forEach(item => {
                    if (item.abilityType === "ToolsAndTechnologies") {
                        skillNames.push(item.ability.name);
                    }
                });
                return (
                    <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                        {skillNames.join(", ")}
                    </Link>
                );
            },
            key: uuidv4()
        },
        {
            accessorKey: "interestedInProjects",
            header: "Consent",
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {row.getValue("interestedInProjects") ? "Yes" : "No"}
                </Link>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "availability",
            header: "Availability",
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {resolveAvailabilityLabel(row.original.availability, t)}
                </Link>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "rate",
            header: "Rate",
            cell: ({ row }) => (
                <Link className="capitalize" to={`/admin/freelancer/details/${row.getValue("client").id}`}>
                    {row.getValue("rate")}
                </Link>
            ),
            key: uuidv4()
        },
    ]

    useEffect(() => {
        setPage(pagination.pageIndex);
        setRowsPerPage(pagination.pageSize);
    }, [pagination])

    const data = realData
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        manualPagination: true,
        rowCount: total,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
            pagination
        },
        initialState: {
            pagination
        }
    });
    return (
        <>
            <div className="relative">
                <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
                    <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
                        <div className="ml-8 text-4xl ">Freelancers</div>
                        <div className="flex w-5/6">
                            <Input
                                placeholder={"Search"}
                                onChange={(event) => setSearchQuery(event.target.value)}
                                className="mr-2 ml-8 bg-white"
                            />
                            <FreelancersFilter
                                currentFilter={unprocessedFilter}
                                onFilterChange={(filters, unprocessedFilters) => {
                                    setFilters(filters)
                                    setUnprocessedFilter(unprocessedFilters)
                                    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilters }))
                                }} />
                        </div>
                    </div>
                </div>
                <div className="w-full px-8 py-4 bg-grayLightMainBg">
                    <div className="rounded-md border">
                        {realData && (
                            <Table>
                                <TableHeader>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <TableRow key={uuidv4()}>
                                            {headerGroup.headers.map(header => {
                                                return (
                                                    <TableHead className="pt-3 pb-3"
                                                        key={uuidv4()}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </TableHead>
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {table.getRowModel().rows?.length ? (
                                        table.getRowModel().rows.map(row => (
                                            <TableRow
                                                key={row.id}
                                                data-state={row.getIsSelected() && "selected"}
                                            >
                                                {row.getVisibleCells().map(cell => (
                                                    <TableCell className="relative text-sm pt-3 pb-3"
                                                        key={uuidv4()}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length}
                                                className="h-24 text-center m-[0px]"
                                            >
                                                No results.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </div>
                    <DataTablePagination table={table} />
                </div>
            </div>
        </>
    )
}
