import React from "react";
import { useState } from "react";
import AppBarForLogin from "../app-bar-for-main-login-pages/AppBarForLogin";
import { restorePasswordRequest } from '../../service/weexpertService';
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../constants";

import logo_login from '../../assets/img/logo_full.png';
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";


export const LoginRestore = () => {

  const { t } = useTranslation();
  const [alertMessage, setAllertMessage] = useState();
  const [mail, setMail] = useState('');

  const alertMessagehandler = (value) => {
    setAllertMessage(value)
  }

  const setAndValidateEmail = (newValue) => {
    const isValid = validateEmail(newValue);
    setMail(newValue)
    if (!isValid && newValue.length !== 0) {
      setAllertMessage(t("alerts:error_format_email"))
    } else
      setAllertMessage('')
  };

  const cleanValues = (email) => {
    email('');
  }

  const onClickSendButtonHandler = () => {
    try {
      restorePasswordRequest(mail)
        .then((response) => {
          if (response.data.status === true) {
            cleanValues(setMail);
            alertMessagehandler(t("alerts:success_send_message_on_mail") + ': ' + mail)
          }
          else {
            alertMessagehandler(t("alerts:error_not_found_mail"));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <AppBarForLogin />
      <form  onSubmit={(e) => {e.preventDefault();}} className="flex flex-row justify-between	">
        <div className="h-screen flex justify-center w-3/6 bg-[url('../src/assets/img/login_bg.png')] bg-no-repeat	bg-cover bg-center	items-center text-white">
          <div className=" w-4/6">
            <div className="text-6xl py-10	">
            {t("login:tagline_capture")}
            </div>
            <div className="text-lg	opacity-70		">
            {t("login:tagline_description")}
            </div>
          </div>
        </div>
        <div className="h-screen w-3/6 flex flex-col justify-center items-center">
          <div><img src={logo_login} alt="" /></div>
          <div className="pb-8 opacity-70">{t("login:reset_password")}</div>
          <div className="w-3/6 pb-4">
            <div className="pb-1">
              <Label htmlFor="email">E-mail</Label>
            </div>
            <Input
              value={mail}
              onChange={(e) => setAndValidateEmail(e.target.value)}
              className="h-12"
            />
          </div>
          {alertMessage && (
            <div className="w-3/6 pb-4">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />

                <AlertDescription>
                  {alertMessage}
                </AlertDescription>
              </Alert>
            </div>
          )}
          <div className="pt-5 w-3/6 flex flex-row justify-start items-center ">
            <Button type="submit" className='w-full' onClick={() => {
              onClickSendButtonHandler();
            }}>{t("users:restore")}</Button>
          </div>
        </div>
      </form>
    </>
  );
}
