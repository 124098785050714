import { BaseDialog } from "./dialog"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { DownloadIcon } from "@radix-ui/react-icons";
import { PAGINATION_SETTINGS } from "@/constants";
import { getInvoices, connectInvoice } from "@/service/weexpertService";
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from '@/components/ui/checkbox';
import { formatDateString } from "@/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable
} from "@tanstack/react-table";
import { DataTablePagination } from "../ui/table-pagination";

export default function InvoiceBindPopup({ clientId, id, className, icon }) {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [realData, setRealData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_SETTINGS.invoices);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: rowsPerPage });
    const [refreshData, setRefreshData] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            const updatedItems = prevSelectedItems.includes(itemId)
                ? prevSelectedItems.filter((id) => id !== itemId)
                : [...prevSelectedItems, itemId];
            return updatedItems;
        });
    };

    useEffect(() => {
        getInvoices(rowsPerPage, page * rowsPerPage, null, [
            { field: "invoiceType", value: ["PREPAID_INVOICE"] },
            { field: "client", value: [clientId] }
        ])
            .then(res => {
                setRealData(res.data);
            })
    }, [rowsPerPage, page, refreshData]);

    const handleBindInvoice = (invoicesArray, id) => {
        if (invoicesArray.length > 0) {
            invoicesArray.map((item) => (
                connectInvoice(item, id)
                    .then(res => {
                        setTimeout(() => {
                            setOpen(false)
                        }, 1500)
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 400) {
                            if (error.response.data && error.response.data.message === "Invoices alredy have connection") {
                               console.log("Invoices alredy have connection")
                            } else {
                                console.log("error", error.message);
                            }
                        } else {
                            console.error("Произошла ошибка при подключении к серверу:", error.message);
                        }
                    })
            ))
        } else {
            console.log('')
        }
    };
    const columns = [
        {
            id: "select",
            cell: ({ row }) => (
                <Checkbox className='flex flex-row items-center justify-center py-0'
                    checked={selectedItems.includes(row.getValue("id"))}
                    onCheckedChange={() => handleCheckboxChange(row.getValue("id"))}
                    aria-label="Select row"
                />
            ),
            enableSorting: false,
            enableHiding: false
        },
        {
            accessorKey: "client",
            header: t("common:client"),
            cell: ({ row }) => (
                <div>
                    {row.original.client.firstName} {row.original.client.lastName}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "id",
            header: t("invoices:document"),
            cell: ({ row }) => {
                return <div className="inline-block">
                    <div>
                        {row.original.invoiceId}
                    </div>
                </div>
            },
            key: uuidv4()
        },
        {
            accessorKey: "Invoicetype",
            header: t("common:type"),
            cell: ({ row }) => (
                <div>
                    {row.original.invoiceType}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "counterparty",
            header: t("common:counterparty"),
            cell: ({ row }) => (
                <div className="capitalize">
                    {row.original.counterparty}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "state",
            header: t("common:status"),
            cell: ({ row }) => (
                <div className={`capitalize w-24 ${row.getValue("state") === 'NOT_APPROVED' ? 'bg-pirpleLightBg rounded text-pirpleText text-center' : 'bg-greenBgLite rounded text-center text-greenText'}`}>
                    {row.getValue("state") === 'NOT_APPROVED' ? t("invoices:status_in_progress") : t("invoices:status_complete")}
                </div>
            ),
            key: uuidv4()
        },
        {
            accessorKey: "date of creation",
            header: t("invoices:date_of_creation"),
            cell: ({ row }) => {
                return <div className="inline-block	">
                    <div>
                        {formatDateString(row.original.createdAt)}
                    </div>
                </div>
            },
            key: uuidv4()
        }
    ]

    const table = useReactTable({
        data: realData?.invoices ?? [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        manualPagination: true,
        rowCount: realData?.total ?? 0,
        state: {
            pagination
        },
        initialState: {
            pagination
        }
    });

    return <BaseDialog
        dialogTitle={t('invoices:title')}
        buttonText={t("invoices:button_bind")}
        open={open}
        setOpen={setOpen}
        className={className}
        icon={icon}
    >
        <div className="w-[800px] flex flex-col items-end ">
            <div className="w-full py-4 bg-grayLightMainBg">
                <div className="rounded-md border p-2">
                    {realData && (
                        <Table>
                            <TableHeader>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <TableRow key={uuidv4()}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <TableHead className="pt-3 pb-3"
                                                    key={uuidv4()}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </TableHead>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map(row => (
                                        <TableRow
                                            key={row.id}
                                            data-state={row.getIsSelected() && "selected"}
                                        >
                                            {row.getVisibleCells().map(cell => (
                                                <TableCell className="relative text-sm pt-3 pb-3"
                                                    key={uuidv4()}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            className="h-24 text-center"
                                        >
                                             {t('no_results')}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                </div>
                <DataTablePagination table={table} />
            </div>
            <Button onClick={() => handleBindInvoice(selectedItems, id)} ><DownloadIcon className="h-[16px] mr-2" />
                {t("invoices:button_bind")}
            </Button>
        </div>
    </BaseDialog>
}
