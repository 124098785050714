import { BaseDialog } from "./dialog"
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { connectInvoiceToTransaction, getInvoicesForReport} from "@/service/weexpertService";
import { toast } from "../ui/use-toast";
import { Search } from "../ui/combobox-search";

export default function TransactionConnect({ transactionId, reportId }) {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();

  return <BaseDialog
    dialogTitle={t('reports:transaction_connect')}
    buttonText={t('reports:transaction_connect')}
    open={open}
    setOpen={setOpen}
  >
    <div className="w-[500px] flex flex-col items-end ">
      <div className="w-full py-4 bg-grayLightMainBg">
        <Search
          fetchOption={(q) => {
            return getInvoicesForReport(reportId, 10, 0, q, [], 'all')
              .then((result) => {
                return result.data.invoices
                  .map(invoice => {
                    return {
                      label: `${invoice.invoiceId}`,
                      value: `${invoice.invoiceId}`,
                      id: invoice.id
                    }
                  })
              });
          }}
          className="min-h-10 mt-1 mb-2"
          placeholder={t("selected")}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedInvoice?.label ?? ''}
          label={t("invoices:title")}
          onChange={(newSelected) => {
            setSelectedInvoice(newSelected)
          }}
        />
      </div>
      <div>
        <Button
          className="mr-4"
          variant="outline"
          onClick={() => {
            setOpen(false)
          }} >
          {t('button_back')}
        </Button>
        <Button onClick={() => {
          if (selectedInvoice.id) {
            connectInvoiceToTransaction(transactionId, selectedInvoice.id)
              .then((response) => {
                if (response.status === 200) {
                  toast({
                    title: t("alerts:toast_succes_invoice_create_description"),
                    description: t("alerts:toast_succes_invoice_create_description"),
                  });
                  setSelectedInvoice(false)
                  setOpen(false)
                }
              }
              )
              .catch((error) => {
                toast({
                  title: t("alerts:toast_error_counterparty_title"),
                  description: t("alerts:toast_error_invoice_description"),
                  variant: "destructive"
                })
              });
          }
        }} >
          {t('invoices:button_bind')}
        </Button>
      </div>
    </div>
  </BaseDialog>
}
