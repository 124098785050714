import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { toast } from "@/components/ui/use-toast";
import {
    REPRESENTATIVE_COMPANY_LIST,
    transformFromIdValueToValueLabel,
} from "@/constants";
import { createReport, getUser } from "@/service/weexpertService";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";

export function ReportCreatePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [representativeCompany, setRepresentativeCompany] = useState(null);

  const onSave = () => {
    if (!fromDate || !endDate || !representativeCompany || title === "") {
      toast({
        title: t("alerts:toast_error_counterparty_title"),
        description: t("alerts:toast_error_counterparty_description"),
        variant: "destructive",
      });
      return;
    }
    createReport(
      title,
      fromDate ? format(fromDate, "yyyy-MM-dd") : null,
      endDate ? format(endDate, "yyyy-MM-dd") : null,
      representativeCompany,
    )
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: t("alerts:toast_succes_invoice_create_description"),
            description: t("alerts:toast_succes_invoice_create_description"),
          });
          setTimeout(() => {
            navigate("/reports");
          }, 1000);
        }
      })
      .catch(() => {
        toast({
          title: t("alerts:toast_error_counterparty_title"),
          description: t("alerts:toast_error_invoice_description"),
          variant: "destructive",
        });
      });
  };

  const { data: user } = useQuery({
    queryKey: "userMe",
    queryFn: getUser,
    retry: false,
  });
  if (user?.data.roles === "CLIENT") {
    navigate("/404");
  }

  return (
    <div className="w-full relative bg-grayLightMainBg px-8">
      <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
        <div className="flex justify-between pt-8  items-center ">
          <div className=" w-5/12  text-4xl ">{t("reports:new_report")}</div>
          <div>
            <div className="flex justify-between">
              <Link to="/reports" className="mr-4">
                <Button className="min-w-20">{t("button_back")}</Button>
              </Link>
              <Button
                className="min-w-20 mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                onClick={() => {
                  onSave();
                }}
              >
                {t("button_add")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-16">
        <div className="w-4/12 pb-4">
          <InputWithAlert
            label={t("counterparties:name")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="w-4/12 pb-4">
          <DatePicker
            date={fromDate}
            setDate={(e) => setFromDate(e)}
            label={t("reports:date_from")}
            inputClassName={"h-12"}
          />
        </div>
        <div className="w-4/12 pb-4">
          <DatePicker
            date={endDate}
            setDate={(e) => setEndDate(e)}
            label={t("reports:date_to")}
            inputClassName={"h-12"}
          />
        </div>
        <div className="w-4/12 pb-4">
          <SelectWithAlert
            label={t("clients:representative_company") + "*"}
            value={representativeCompany}
            onChangeValue={(e) => setRepresentativeCompany(e)}
            options={REPRESENTATIVE_COMPANY_LIST.map(
              transformFromIdValueToValueLabel,
            )}
            inputClassName={"h-12 mt-2 mb-4 bg-white"}
          />
        </div>
      </div>
    </div>
  );
}
