import React from "react";
import LanguageSelector from "../language-selector/LanguageSelector";

const AppBarForLogin = (props) => {
    return (
        <div className="w-full h-16 fixed top-0 left-0 z-30 bg-white flex justify-between items-center border-b-[1px]">
            <LanguageSelector isLogined={props.isLogined} userData={props.userData} />
        </div>
    )

}
export default AppBarForLogin
