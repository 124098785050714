
import { Alert, Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserClientTable from "@/components/clients/RelatedClientsTable";
import UsersForm, { USERS_FORM_ACTION_EDIT } from "../components/users/UsersForm";
import { rolesForUsersCreate, validateOnlyLatin, validatePhone } from "../constants";
import { changeUser, connectUserToClinet, getUserById, unassignClient } from '../service/weexpertService';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import AssignClientTablePopup from '../components/popups/user.assign.clients.popup';

export const UserEditPage = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [refreshData, setRefreshData] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [inputChecked, setInputChecked] = useState(false);
  const [user, setUser] = useState('');
  const [rolesUser, setRolesUser] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [inputValueName, setInputValueName] = useState('');
  const [inputValueSurname, setInputValueSurname] = useState('');
  const [mail, setMail] = useState('');
  const [tel, setTel] = useState('');

  const setAndValidateName = (newValue) => {
    const isValid = validateOnlyLatin(newValue)
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorIncorrectText)
    }
    else {
      setAlertMessage('')
    }
    setInputValueName(newValue)
  };

  const setAndValidateSurname = (newValue) => {
    const isValid = validateOnlyLatin(newValue)
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorIncorrectText)
    }
    else {
      setAlertMessage('')
    }
    setInputValueSurname(newValue)
  };

  const setAndValidatePhone = (newValue) => {
    const isValid = validatePhone(newValue)
    if (!isValid && newValue.length !== 0) {
      setAlertMessage(alertErrorTel)
    }
    else {
      setAlertMessage('')
    }
    setTel(newValue)
  };

  const handleRefreshData = () => {
    setRefreshData(prevRefreshData => !prevRefreshData);
  };

  useEffect(() => {
    getUserById(id)
      .then(res => {
        if (res.data.user === null) {
          navigate('/404')
        }
        setUser(res.data.user);
        setInputValueName(res.data.user.firstName);
        setInputValueSurname(res.data.user.lastName);
        setMail(res.data.user.email);
        setTel(res.data.user.phone);
        setRolesUser(res.data.user.roles);
      }).then(() => {
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          navigate('/404')
        } else {
          console.error("Error:", error);
        }
      });
  }, [id, refreshData]);

  useEffect(() => {
    if (
      alertMessage === '' &&
      inputValueName.trim() !== '' &&
      inputValueSurname.trim() !== '' &&
      tel.trim() !== '' &&
      mail.trim() !== '' &&
      rolesUser !== ''
    ) {
      setIsFormValid(true);

    } else {
      setIsFormValid(false);
    }
  }, [
    inputValueName,
    inputValueSurname,
    tel,
    mail,
    rolesUser
  ]);

  const updateUserHandler = () => {
    if (!isFormValid) {
      setAlertMessage(alertErrorSend);
      setInputChecked(true);
    }
    else {
      changeUser(inputValueName, inputValueSurname, tel, mail, rolesUser, user.id)
        .then((response) => {
          setAlertMessage(alertSuccess)
          setTimeout(() => {
            handleRefreshData();
            setAlertMessage('');
            setInputChecked(false);
            navigate('/admin/administration')
          }, 1000);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setAlertMessage(alertErrorAccess)
            console.log('Ошибка: Неверные данные, код 400');

          } else {
            console.error(error);
          }
        });
    }
  };

  const alertErrorIncorrectText = (<Alert severity="error">{t("alerts:error_words_lang_wrong_input")}</Alert>);
  const alertSuccess = <Alert severity="success">{t("alerts:success_change_data")}</Alert>;
  const alertErrorAccess = <Alert severity="error">{t("alerts:error_send_fail")}</Alert>;
  const alertErrorTel = <Alert severity="error">{t("alerts:error_phone_validation")}</Alert>;
  const alertErrorSend = <Alert severity="error">{t("alerts:error_invoice_state")}</Alert>;

  return (
    <Box>
      {loading ? (
        <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center ">
              <div className=" w-5/12  text-4xl ">
                {t("users:edit_title")}
              </div>
              <div className='w-6/12 flex justify-end'>
                <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                  onClick={updateUserHandler}>
                  {t("button_save")}
                </Button>
              </div>
            </div>
          </div>
          <div className='w-full py-8'>
            <div className=" text-base ">{t("user:user")}</div>
            <div className=" text-2xl ">{user && `${user.firstName} ${user.lastName}`}</div>
          </div>
          <div className='w-full'>
            <div className='py-2'>{alertMessage}</div>
            <UsersForm
              action={USERS_FORM_ACTION_EDIT}
              user={user}
              inputChecked={inputChecked}
              firstName={inputValueName}
              setFirstName={setAndValidateName}
              lastName={inputValueSurname}
              setLastName={setAndValidateSurname}
              email={user.email}
              phone={tel}
              setPhone={setAndValidatePhone}
              role={rolesUser}
              setRole={setRolesUser}
              rolesData={rolesForUsersCreate}
            />
          </div>
          <div className='w-full flex justify-between items-center pb-8'>
            <div className="w-full text-xl font-medium ">
              {t("clients")}
            </div>
            <AssignClientTablePopup
              header={t("users:add_client_from_user")}
              handleRefreshData={handleRefreshData}
              person={user}
              onSelect={(clientId) => {
                connectUserToClinet(clientId, user.id)
                  .then(() => {
                    handleRefreshData()
                  })
              }}
              onUnselect={(clientId) => {
                unassignClient(clientId)
                  .then(() => {
                    handleRefreshData()
                  })
              }}
              initialSelection={user.clients.map(e => e.id)}
            />
          </div>
          <UserClientTable entity={user} handleRefreshData={handleRefreshData}/>
        </div>)}
    </Box>
  )
}
export default UserEditPage;
