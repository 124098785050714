import React from "react";
import { createSpecifiation, getUser } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { toast } from "@/components/ui/use-toast";
import { SpecificationForm } from "@/components/forms/specifications.form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export const SpecificationCreatePage = (props) => {

    const { t } = useTranslation();

    const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
    const navigate = useNavigate()
    const onSaved = (
        contract,
        description,
        functionality,
        signatory,
        stages
    ) => {
        if (!contract
            || !description
            || !functionality || stages.length < 1
            || contract.specifications.length >= 1) {
            return
        }
        return createSpecifiation({
            contract: contract.id,
            description,
            functionality,
            signatory,
            stages
        }).then((response) => {
            if (response.status === 200) {
                toast({
                    title: t("alerts:success"),
                    description: t("alerts:success_change_data"),
                })
                navigate('/specifications/')
            }
        }).catch((error) => {
            toast({
                title: t("alerts:toast_error_invoice_title"),
                description: "alerts:toast_error_invoice_description",
                variant: "destructive"
            })
        });
    }
    if (isUserLoading) {
        return <Loader />
    }
    if (user.data.roles === 'CLIENT') {
        navigate('/404')
    }


    return (
        <SpecificationForm onSaved={onSaved} />
    );
};
