import { Loader } from "@/components/ProtectedRoute";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { getSharedClientPools } from "@/service/weexpertService";
import { useReactTable } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from 'uuid';

import { format } from "date-fns";
import { flexRender, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { DataTablePagination } from "@/components/ui/table-pagination";
import { Skeleton } from "@/components/ui/skeleton";

export default function SharedClientPools() {

  const { data: sharedClientPools, isLoading } = useQuery({ queryKey: 'sharedPools', queryFn: getSharedClientPools });
  const { t } = useTranslation();

  const columns = (t) => {
    return [
      {
        accessorKey: "name",
        header: t('name_last_name'),
        cell: ({ row }) => {
          return (<Link to={`/admin/user/client-shared-pool/${row.original.id}`}>
            {row.original.name}
          </Link>)
        },
        key: uuidv4()
      },
      {
        accessorKey: "author",
        header: t('author'),
        cell: ({ row }) => (
          <Link to={`/admin/user/client-shared-pool/${row.original.id}`}>
            {row.original.author.firstName} {row.original.author.lastName}
          </Link>
        ),
        key: uuidv4()
      },
      {
        accessorKey: "user",
        header: t('users:user'),
        cell: ({ row }) => (
          <Link className="capitalize" to={`/admin/user/client-shared-pool/${row.original.id}`}>
            {row.original.user.firstName} {row.original.user.lastName}
          </Link>
        ),
        key: uuidv4()
      },
      {
        accessorKey: "user_amount",
        header: t('invoices:positions_amount'),
        cell: ({ row }) => (
          <Link className="capitalize" to={`/admin/user/client-shared-pool/${row.original.id}`}>
            {JSON.parse(row.original.payload).length}
          </Link>
        ),
        key: uuidv4()
      },
      {
        accessorKey: "dateOfCreation",
        header: t('created'),
        cell: ({ row }) => (
          <Link className="capitalize" to={`/admin/user/client-shared-pool/${row.original.id}`}>
            {format(new Date(row.original.createdAt), 'dd/MM/yyyy')}
          </Link>
        ),
        key: uuidv4()
      }
    ]
  }

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : sharedClientPools.data,
    columns: isLoading ? columns(t).map((column) => {
      return {
        ...column,
        cell: () => {
          return <Skeleton />
        }
      }
    }) : columns(t),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 50
      }
    }
  });

  if (isLoading) {
    return <Loader />
  }

  return <>
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-3xl ">
              {t("client_access_transfer")}
            </div>
            <div className="flex w-4/6">
            </div>
          </div>
        </div>
        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  </>;
}
