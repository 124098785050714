import { Check, ChevronsUpDown } from "lucide-react"
import * as React from "react"
import { useEffect } from "react"

import { Button } from "@/components/ui/button"
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { CommandList } from "cmdk"

import { AlertCircle } from "lucide-react"
import { Alert, AlertTitle } from "./alert"
import { Label } from "./label"
import { useDebounce } from "./multiple-selector"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"

const queryClient = new QueryClient()
const SearchResult = ({ fetchOption, query, selectedResult, onSelectResult, queryKey }) => {
  const debouncedSearchQuery = query;
  const enabled = !!debouncedSearchQuery || true;
  const {
    data,
    isLoading: isLoadingOrig,
    isError,
  } = useQuery({
    queryKey: ['search', queryKey, debouncedSearchQuery],
    queryFn: () => fetchOption(debouncedSearchQuery),
    enabled,
  });

  // To get around this https://github.com/TanStack/query/issues/3584
  const isLoading = enabled && isLoadingOrig;

  if (!enabled) return null;

  return (
    <CommandList>
      {/* TODO: these should have proper loading aria */}
      {isLoading && <div className="p-4 text-sm">Searching...</div>}
      {!isError && !isLoading && !data?.length && (
        <div className="p-4 text-sm">No products found</div>
      )}
      {isError && <div className="p-4 text-sm">Something went wrong</div>}
      {data?.map(({ value, label, ...data }) => {
        return (
          <CommandItem
            key={value.toString().replace(/"/g, '').replace(/'/g, "")}
            onSelect={() => onSelectResult({ value, label, ...data })}
            value={label.toString().replace(/"/g, '').replace(/'/g, "")}
          >
            <Check
              className={cn(
                'mr-2 h-4 w-4',
                selectedResult?.value === value ? 'opacity-100' : 'opacity-0'
              )}
            />
            {label}
          </CommandItem>
        );
      })}
    </CommandList>
  );
}
export function Search({ fetchOption,
  onChange,
  placeholder,
  label,
  error,
  errorMessage,
  value,
  delay,
  disabled,
  className,
  queryKey = 'cache_key'
}) {

  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState("")
  const debouncedSearchQuery = useDebounce(inputValue, 500);
  return (

    <QueryClientProvider client={queryClient} contextSharing={true}>
      <div className={cn('flex flex-col relative pb-4 w-full', className ?? '')}>
        <Label htmlFor="inputId" className="pb-2">{label}</Label>
        <Popover open={open} onOpenChange={setOpen} className="w-full ">
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              //aria-expanded={open}
              className={`w-full justify-between h-12 ${disabled ? "bg-inherit hover:bg-inherit cursor-default" :"bg-white"}`}
            >
              {value ?? placeholder}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          {!disabled && (
            <PopoverContent className="w-80" align="start">
              <Command
                shouldFilter={false}
              >
                <CommandList className="absolute top-0 left-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
                  <CommandInput
                    placeholder={placeholder ?? ''}
                    value={inputValue}
                    onValueChange={setInputValue}
                  />
                  <CommandGroup>
                    <SearchResult
                      key={inputValue}
                      fetchOption={fetchOption}
                      query={debouncedSearchQuery}
                      selectedResult={value}
                      queryKey={queryKey}
                      onSelectResult={(e) => {
                        onChange(e)
                        setOpen(false)
                        setInputValue('')
                      }
                      }
                    />
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          )}
        </Popover>
        {error && (<div className="mt-4">
          <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
            <AlertCircle className="h-4 w-4 !static !mr-2" />
            <AlertTitle className="m-0 !p-0">
              {errorMessage}
            </AlertTitle>
          </Alert>
        </div>)
        }

      </div>
    </QueryClientProvider>
  )
}
