export const JOB_TITLE_OPTIONS = [
  { value: "Software Developer", label: "Software Developer" },
  { value: "Front-end Developer", label: "Front-end Developer" },
  { value: "Back-end Developer", label: "Back-end Developer" },
  { value: "Full-stack Developer", label: "Full-stack Developer" },
  { value: "Mobile Developer", label: "Mobile Developer" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Cloud Engineer", label: "Cloud Engineer" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Data Engineer", label: "Data Engineer" },
  { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
  { value: "Security Engineer", label: "Security Engineer" },
  { value: "QA Engineer", label: "QA Engineer" },
  { value: "Automation Tester", label: "Automation Tester" },
  { value: "Network Engineer", label: "Network Engineer" },
  { value: "Solutions Architect", label: "Solutions Architect" },
  { value: "Enterprise Architect", label: "Enterprise Architect" },
  { value: "Cloud Architect", label: "Cloud Architect" },
  { value: "System Administrator", label: "System Administrator" },
  { value: "IT Support Specialist", label: "IT Support Specialist" },
  { value: "IT Consultant", label: "IT Consultant" },
  { value: "Product Manager (PM)", label: "Product Manager (PM)" },
  { value: "Business Analyst (BA)", label: "Business Analyst (BA)" },
  { value: "Product Owner (PO)", label: "Product Owner (PO)" },
  { value: "Scrum Master", label: "Scrum Master" },
  { value: "Agile Coach", label: "Agile Coach" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "Program Manager", label: "Program Manager" },
  {
    value: "CTO (Chief Technology Officer)",
    label: "CTO (Chief Technology Officer)",
  },
  {
    value: "COO (Chief Operating Officer)",
    label: "COO (Chief Operating Officer)",
  },
  {
    value: "CEO (Chief Executive Officer)",
    label: "CEO (Chief Executive Officer)",
  },
  { value: "Digital Marketing Manager", label: "Digital Marketing Manager" },
  { value: "Growth Hacker", label: "Growth Hacker" },
  { value: "Content Strategist", label: "Content Strategist" },
  { value: "SEO Specialist", label: "SEO Specialist" },
  { value: "Social Media Manager", label: "Social Media Manager" },
  { value: "Sales Manager", label: "Sales Manager" },
  { value: "Sales Executive", label: "Sales Executive" },
  { value: "Account Manager", label: "Account Manager" },
  { value: "Customer Success Manager", label: "Customer Success Manager" },
  { value: "Marketing Coordinator", label: "Marketing Coordinator" },
  { value: "Product Marketing Manager", label: "Product Marketing Manager" },
  { value: "Graphic Designer", label: "Graphic Designer" },
  { value: "Motion Graphics Designer", label: "Motion Graphics Designer" },
  { value: "UI/UX Designer", label: "UI/UX Designer" },
  { value: "Visual Designer", label: "Visual Designer" },
  { value: "3D Designer", label: "3D Designer" },
  { value: "Interaction Designer", label: "Interaction Designer" },
  { value: "Web Designer", label: "Web Designer" },
  { value: "Art Director", label: "Art Director" },
  { value: "Creative Director", label: "Creative Director" },
  { value: "Video Producer", label: "Video Producer" },
  { value: "Content Producer", label: "Content Producer" },
  { value: "Storyboard Artist", label: "Storyboard Artist" },
  { value: "Multimedia Designer", label: "Multimedia Designer" },
  { value: "Game Designer", label: "Game Designer" },
  { value: "Animation Designer", label: "Animation Designer" },
  {
    value: "Storyteller/Content Creator",
    label: "Storyteller/Content Creator",
  },
  { value: "Copywriter", label: "Copywriter" },
  { value: "User Researcher", label: "User Researcher" },
  { value: "Brand Designer", label: "Brand Designer" },
  { value: "Experience Designer", label: "Experience Designer" },
  { value: "Web3 Developer", label: "Web3 Developer" },
  { value: "Blockchain Developer", label: "Blockchain Developer" },
  { value: "Smart Contract Developer", label: "Smart Contract Developer" },
  { value: "Crypto Engineer", label: "Crypto Engineer" },
  { value: "Blockchain Architect", label: "Blockchain Architect" },
  { value: "Metaverse Engineer", label: "Metaverse Engineer" },
  { value: "Tokenomics Expert", label: "Tokenomics Expert" },
  { value: "Crypto Analyst", label: "Crypto Analyst" },
  { value: "DeFi Analyst", label: "DeFi Analyst" },
  { value: "Blockchain Consultant", label: "Blockchain Consultant" },
  { value: "Web3 Community Manager", label: "Web3 Community Manager" },
  { value: "Web3 Marketing Specialist", label: "Web3 Marketing Specialist" },
  { value: "AI Engineer", label: "AI Engineer" },
  { value: "AI Researcher", label: "AI Researcher" },
  { value: "NLP Engineer", label: "NLP Engineer" },
  { value: "Deep Learning Engineer", label: "Deep Learning Engineer" },
  { value: "AI Architect", label: "AI Architect" },
  { value: "AI Ethics Specialist", label: "AI Ethics Specialist" },
  { value: "AI Product Manager", label: "AI Product Manager" },
  { value: "AI Data Analyst", label: "AI Data Analyst" },
  { value: "AI Trainer", label: "AI Trainer" },
];
export const TOOLS_SKILLS = [
  { label: "3ds Max", value: "3ds Max" },
  { label: "AIOHTTP", value: "AIOHTTP" },
  { label: "AWS", value: "AWS" },
  { label: "AWS Lambda", value: "AWS Lambda" },
  { label: "AWS Redshift", value: "AWS Redshift" },
  { label: "AWS SageMaker", value: "AWS SageMaker" },
  { label: "ActiveCampaign", value: "ActiveCampaign" },
  { label: "Adobe After Effects", value: "Adobe After Effects" },
  { label: "Adobe Fresco", value: "Adobe Fresco" },
  { label: "Adobe Illustrator", value: "Adobe Illustrator" },
  { label: "Adobe Photoshop", value: "Adobe Photoshop" },
  { label: "Adobe Premiere Pro", value: "Adobe Premiere Pro" },
  { label: "Adobe XD", value: "Adobe XD" },
  { label: "Affinity Designer", value: "Affinity Designer" },
  { label: "Ahrefs", value: "Ahrefs" },
  { label: "Air", value: "Air" },
  { label: "Airtable", value: "Airtable" },
  { label: "Alchemy", value: "Alchemy" },
  { label: "Alteryx", value: "Alteryx" },
  { label: "Amplitude", value: "Amplitude" },
  { label: "Angular", value: "Angular" },
  { label: "Ansible", value: "Ansible" },
  { label: "Apache Kafka", value: "Apache Kafka" },
  { label: "Apache NiFi", value: "Apache NiFi" },
  { label: "Apache Spark", value: "Apache Spark" },
  { label: "Apache Wicket", value: "Apache Wicket" },
  { label: "Appium", value: "Appium" },
  { label: "Arweave", value: "Arweave" },
  { label: "Asana", value: "Asana" },
  { label: "Aseprite", value: "Aseprite" },
  { label: "Aura", value: "Aura" },
  { label: "Auth0", value: "Auth0" },
  { label: "AutoCAD", value: "AutoCAD" },
  { label: "Autodesk Maya", value: "Autodesk Maya" },
  { label: "Axure RP", value: "Axure RP" },
  { label: "Azure Functions", value: "Azure Functions" },
  { label: "Azure Synapse Analytics", value: "Azure Synapse Analytics" },
  { label: "Backbone.js", value: "Backbone.js" },
  { label: "Balsamiq", value: "Balsamiq" },
  { label: "Bamboo", value: "Bamboo" },
  { label: "Band Protocol", value: "Band Protocol" },
  { label: "Beego", value: "Beego" },
  { label: "BigQuery", value: "BigQuery" },
  { label: "Binance Smart Chain", value: "Binance Smart Chain" },
  { label: "Bitbucket", value: "Bitbucket" },
  { label: "Bitbucket Pipelines", value: "Bitbucket Pipelines" },
  { label: "Blade", value: "Blade" },
  { label: "Blender", value: "Blender" },
  { label: "BlueBream", value: "BlueBream" },
  { label: "Bottle", value: "Bottle" },
  { label: "BrightEdge", value: "BrightEdge" },
  { label: "Brownie", value: "Brownie" },
  { label: "BrowserStack", value: "BrowserStack" },
  { label: "Bubble", value: "Bubble" },
  { label: "Buffalo", value: "Buffalo" },
  { label: "Buffer", value: "Buffer" },
  { label: "C#", value: "C#" },
  { label: "C++", value: "C++" },
  { label: "CSS", value: "CSS" },
  { label: "CakePHP", value: "CakePHP" },
  { label: "Camping", value: "Camping" },
  { label: "Cassandra", value: "Cassandra" },
  { label: "Chainlink", value: "Chainlink" },
  { label: "CherryPy", value: "CherryPy" },
  { label: "Chi", value: "Chi" },
  { label: "Cinema 4D", value: "Cinema 4D" },
  { label: "CircleCI", value: "CircleCI" },
  { label: "ClickUp", value: "ClickUp" },
  { label: "Cloud Firestore", value: "Cloud Firestore" },
  { label: "Cloud Functions", value: "Cloud Functions" },
  { label: "Cloud Run", value: "Cloud Run" },
  { label: "Cloud SQL", value: "Cloud SQL" },
  { label: "Cloud Spanner", value: "Cloud Spanner" },
  { label: "CloudFormation", value: "CloudFormation" },
  { label: "CodeIgniter", value: "CodeIgniter" },
  { label: "CodeShip", value: "CodeShip" },
  { label: "CorelDRAW", value: "CorelDRAW" },
  { label: "Couchbase", value: "Couchbase" },
  { label: "Crazy Egg", value: "Crazy Egg" },
  { label: "Cuba", value: "Cuba" },
  { label: "CubicWeb", value: "CubicWeb" },
  { label: "Cucumber", value: "Cucumber" },
  { label: "Cypress", value: "Cypress" },
  { label: "DaVinci Resolve", value: "DaVinci Resolve" },
  { label: "DataRobot", value: "DataRobot" },
  { label: "Datadog", value: "Datadog" },
  { label: "DigitalOcean", value: "DigitalOcean" },
  { label: "Django", value: "Django" },
  { label: "Docker", value: "Docker" },
  { label: "Dropwizard", value: "Dropwizard" },
  { label: "Drupal", value: "Drupal" },
  { label: "ELK Stack", value: "ELK Stack" },
  { label: "Echo", value: "Echo" },
  { label: "Ember.js", value: "Ember.js" },
  { label: "Ethereum", value: "Ethereum" },
  { label: "Express.js", value: "Express.js" },
  { label: "Falcon", value: "Falcon" },
  { label: "Fast.ai", value: "Fast.ai" },
  { label: "FastAPI", value: "FastAPI" },
  { label: "Fastify", value: "Fastify" },
  { label: "Fat-Free Framework", value: "Fat-Free Framework" },
  { label: "Fiber", value: "Fiber" },
  { label: "Figma", value: "Figma" },
  { label: "Filecoin", value: "Filecoin" },
  { label: "Final Cut Pro", value: "Final Cut Pro" },
  { label: "Firebase", value: "Firebase" },
  { label: "Firebase Authentication", value: "Firebase Authentication" },
  { label: "Firebase Cloud Messaging", value: "Firebase Cloud Messaging" },
  { label: "Firebase Firestore", value: "Firebase Firestore" },
  { label: "Firebase Hosting", value: "Firebase Hosting" },
  { label: "Firebase Realtime Database", value: "Firebase Realtime Database" },
  { label: "Flamingo", value: "Flamingo" },
  { label: "Flask", value: "Flask" },
  { label: "Flask-RESTful", value: "Flask-RESTful" },
  { label: "Flutter", value: "Flutter" },
  { label: "Flutterflow", value: "Flutterflow" },
  { label: "Framer", value: "Framer" },
  { label: "FuelPHP", value: "FuelPHP" },
  { label: "GCP Services", value: "GCP Services" },
  { label: "GIMP", value: "GIMP" },
  { label: "GWT", value: "GWT" },
  { label: "Gin", value: "Gin" },
  { label: "Git", value: "Git" },
  { label: "GitHub", value: "GitHub" },
  { label: "GitLab", value: "GitLab" },
  { label: "GitLab CI", value: "GitLab CI" },
  { label: "Gizmo", value: "Gizmo" },
  { label: "Go", value: "Go" },
  { label: "Goa", value: "Goa" },
  { label: "Goji", value: "Goji" },
  { label: "Google AI", value: "Google AI" },
  { label: "Google Analytics", value: "Google Analytics" },
  { label: "Google App Engine", value: "Google App Engine" },
  { label: "Google AutoML", value: "Google AutoML" },
  { label: "Google BigQuery", value: "Google BigQuery" },
  { label: "Google Cloud AI Platform", value: "Google Cloud AI Platform" },
  { label: "Google Cloud Functions", value: "Google Cloud Functions" },
  { label: "Google Cloud Platform", value: "Google Cloud Platform" },
  { label: "Google Cloud Storage", value: "Google Cloud Storage" },
  { label: "Google Compute Engine", value: "Google Compute Engine" },
  { label: "Google Kubernetes Engine", value: "Google Kubernetes Engine" },
  { label: "Google Meet", value: "Google Meet" },
  { label: "Google Search Console", value: "Google Search Console" },
  { label: "Google Workspace", value: "Google Workspace" },
  { label: "Gorilla", value: "Gorilla" },
  { label: "Grafana", value: "Grafana" },
  { label: "Grails", value: "Grails" },
  { label: "Grape", value: "Grape" },
  { label: "H2O.ai", value: "H2O.ai" },
  { label: "HBase", value: "HBase" },
  { label: "HTML", value: "HTML" },
  { label: "Hadoop", value: "Hadoop" },
  { label: "Hanami", value: "Hanami" },
  { label: "Hapi.js", value: "Hapi.js" },
  { label: "Hardhat", value: "Hardhat" },
  { label: "Heroku", value: "Heroku" },
  { label: "Hibernate", value: "Hibernate" },
  { label: "Hive", value: "Hive" },
  { label: "Hootsuite", value: "Hootsuite" },
  { label: "Hotjar", value: "Hotjar" },
  { label: "Houdini", value: "Houdini" },
  { label: "HubSpot", value: "HubSpot" },
  { label: "HubSpot", value: "HubSpot" },
  { label: "Hug", value: "Hug" },
  { label: "Hugging Face", value: "Hugging Face" },
  { label: "Hyperledger", value: "Hyperledger" },
  { label: "IBM Watson", value: "IBM Watson" },
  { label: "IPFS", value: "IPFS" },
  { label: "InVision", value: "InVision" },
  { label: "Infura", value: "Infura" },
  { label: "Inkscape", value: "Inkscape" },
  { label: "Iris", value: "Iris" },
  { label: "JHipster", value: "JHipster" },
  { label: "JSF", value: "JSF" },
  { label: "JUnit", value: "JUnit" },
  { label: "Java", value: "Java" },
  { label: "JavaScript", value: "JavaScript" },
  { label: "Jenkins", value: "Jenkins" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jest", value: "Jest" },
  { label: "Jira", value: "Jira" },
  { label: "Justinmind", value: "Justinmind" },
  { label: "Katalon Studio", value: "Katalon Studio" },
  { label: "Keras", value: "Keras" },
  { label: "Klaviyo", value: "Klaviyo" },
  { label: "Koa.js", value: "Koa.js" },
  { label: "Kotlin", value: "Kotlin" },
  { label: "KrakenD", value: "KrakenD" },
  { label: "Kubernetes", value: "Kubernetes" },
  { label: "Laravel", value: "Laravel" },
  { label: "Linode", value: "Linode" },
  { label: "Looker", value: "Looker" },
  { label: "Lumen", value: "Lumen" },
  { label: "Macaron", value: "Macaron" },
  { label: "Mack", value: "Mack" },
  { label: "Magento", value: "Magento" },
  { label: "Mailchimp", value: "Mailchimp" },
  { label: "MailerLite", value: "MailerLite" },
  { label: "Majestic", value: "Majestic" },
  { label: "Marketo", value: "Marketo" },
  { label: "Marmoset Toolbag", value: "Marmoset Toolbag" },
  { label: "Martini", value: "Martini" },
  { label: "Marvel", value: "Marvel" },
  { label: "Masonite", value: "Masonite" },
  { label: "Merb", value: "Merb" },
  { label: "MetaMask", value: "MetaMask" },
  { label: "Meteor", value: "Meteor" },
  { label: "Microsoft Azure", value: "Microsoft Azure" },
  { label: "Microsoft Office", value: "Microsoft Office" },
  { label: "Microsoft Teams", value: "Microsoft Teams" },
  { label: "Miro", value: "Miro" },
  { label: "Mixpanel", value: "Mixpanel" },
  { label: "Mocha", value: "Mocha" },
  { label: "Monday.com", value: "Monday.com" },
  { label: "MongoDB", value: "MongoDB" },
  { label: "Moz", value: "Moz" },
  { label: "Mux", value: "Mux" },
  { label: "MySQL", value: "MySQL" },
  { label: "NLTK", value: "NLTK" },
  { label: "Nancy", value: "Nancy" },
  { label: "Nest.js", value: "Nest.js" },
  { label: "Netlify", value: "Netlify" },
  { label: "Nette", value: "Nette" },
  { label: "New Relic", value: "New Relic" },
  { label: "Next.js", value: "Next.js" },
  { label: "Node.js", value: "Node.js" },
  { label: "Notion", value: "Notion" },
  { label: "NumPy", value: "NumPy" },
  { label: "Nuxt.js", value: "Nuxt.js" },
  { label: "ONNX", value: "ONNX" },
  { label: "Okta", value: "Okta" },
  { label: "OneLogin", value: "OneLogin" },
  { label: "OpenAI GPT Models", value: "OpenAI GPT Models" },
  { label: "OpenShift", value: "OpenShift" },
  { label: "Optimizely", value: "Optimizely" },
  { label: "Origami Studio", value: "Origami Studio" },
  { label: "PHP", value: "PHP" },
  { label: "PHPixie", value: "PHPixie" },
  { label: "Padrino", value: "Padrino" },
  { label: "PagerDuty", value: "PagerDuty" },
  { label: "Pandas", value: "Pandas" },
  { label: "Pardot", value: "Pardot" },
  { label: "Pecan", value: "Pecan" },
  { label: "Phalcon", value: "Phalcon" },
  { label: "Pipedrive", value: "Pipedrive" },
  { label: "Play Framework", value: "Play Framework" },
  { label: "Polkadot", value: "Polkadot" },
  { label: "PostgreSQL", value: "PostgreSQL" },
  { label: "Postman", value: "Postman" },
  { label: "Power BI", value: "Power BI" },
  { label: "Procreate", value: "Procreate" },
  { label: "Prometheus", value: "Prometheus" },
  { label: "ProtoPie", value: "ProtoPie" },
  { label: "Pub/Sub", value: "Pub/Sub" },
  { label: "PyTorch", value: "PyTorch" },
  { label: "Pyramid", value: "Pyramid" },
  { label: "Python", value: "Python" },
  { label: "Qlik", value: "Qlik" },
  { label: "Quarkus", value: "Quarkus" },
  { label: "Quart", value: "Quart" },
  { label: "Rack", value: "Rack" },
  { label: "Ramaze", value: "Ramaze" },
  { label: "React Native", value: "React Native" },
  { label: "React.js", value: "React.js" },
  { label: "Redis", value: "Redis" },
  { label: "Remix IDE", value: "Remix IDE" },
  { label: "Resque", value: "Resque" },
  { label: "Revel", value: "Revel" },
  { label: "Rhino", value: "Rhino" },
  { label: "Roda", value: "Roda" },
  { label: "Ruby", value: "Ruby" },
  { label: "Ruby on Rails", value: "Ruby on Rails" },
  { label: "Rust", value: "Rust" },
  { label: "SAP", value: "SAP" },
  { label: "SAP HANA", value: "SAP HANA" },
  { label: "SAP S/4HANA", value: "SAP S/4HANA" },
  { label: "SE Ranking", value: "SE Ranking" },
  { label: "SEMrush", value: "SEMrush" },
  { label: "SQL", value: "SQL" },
  { label: "Salesforce", value: "Salesforce" },
  { label: "Sanic", value: "Sanic" },
  { label: "Sauce Labs", value: "Sauce Labs" },
  { label: "SciPy", value: "SciPy" },
  { label: "Scikit-learn", value: "Scikit-learn" },
  { label: "Scorched", value: "Scorched" },
  { label: "Screaming Frog", value: "Screaming Frog" },
  { label: "Selenium", value: "Selenium" },
  { label: "SendGrid", value: "SendGrid" },
  { label: "Sentry", value: "Sentry" },
  { label: "SharePoint", value: "SharePoint" },
  { label: "Shopify", value: "Shopify" },
  { label: "Silex", value: "Silex" },
  { label: "Sinatra", value: "Sinatra" },
  { label: "Sketch", value: "Sketch" },
  { label: "Slack", value: "Slack" },
  { label: "Slim", value: "Slim" },
  { label: "Snowflake", value: "Snowflake" },
  { label: "Solidity", value: "Solidity" },
  { label: "Spark Java", value: "Spark Java" },
  { label: "Splunk", value: "Splunk" },
  { label: "Spring Boot", value: "Spring Boot" },
  { label: "Spring Framework", value: "Spring Framework" },
  { label: "Sprout Social", value: "Sprout Social" },
  { label: "SpyFu", value: "SpyFu" },
  { label: "Struts", value: "Struts" },
  { label: "Surfer SEO", value: "Surfer SEO" },
  { label: "Svelte", value: "Svelte" },
  { label: "Swift", value: "Swift" },
  { label: "Symfony", value: "Symfony" },
  { label: "TFX", value: "TFX" },
  { label: "Tableau", value: "Tableau" },
  { label: "Talend", value: "Talend" },
  { label: "TensorFlow", value: "TensorFlow" },
  { label: "TensorFlow Serving", value: "TensorFlow Serving" },
  { label: "Terraform", value: "Terraform" },
  { label: "TestRail", value: "TestRail" },
  { label: "Tornado", value: "Tornado" },
  { label: "Travis CI", value: "Travis CI" },
  { label: "Trello", value: "Trello" },
  { label: "Truffle", value: "Truffle" },
  { label: "Trust Wallet", value: "Trust Wallet" },
  { label: "TurboGears", value: "TurboGears" },
  { label: "TypeScript", value: "TypeScript" },
  { label: "Unbounce", value: "Unbounce" },
  { label: "Unity", value: "Unity" },
  { label: "Unreal Engine", value: "Unreal Engine" },
  { label: "Vaadin", value: "Vaadin" },
  { label: "Vercel", value: "Vercel" },
  { label: "Volt", value: "Volt" },
  { label: "Vue.js", value: "Vue.js" },
  { label: "Vugu", value: "Vugu" },
  { label: "Vyper", value: "Vyper" },
  { label: "WalletConnect", value: "WalletConnect" },
  { label: "Web2py", value: "Web2py" },
  { label: "Webflow", value: "Webflow" },
  { label: "Wix", value: "Wix" },
  { label: "WordPress", value: "WordPress" },
  { label: "Yii", value: "Yii" },
  { label: "Yoast SEO", value: "Yoast SEO" },
  { label: "ZBrush", value: "ZBrush" },
  { label: "Zapier", value: "Zapier" },
  { label: "Zend Framework", value: "Zend Framework" },
  { label: "Zoho CRM", value: "Zoho CRM" },
  { label: "Zoom", value: "Zoom" },
  { label: "Zope", value: "Zope" },
  { label: "spaCy", value: "spaCy" },
];


export const MONTHLY_RATE =  [
    {
      value: "0_1000",
      label: "0-1,000 USD"
    },
    {
      value: "1000_2000",
      label: "1,000-2,000 USD"
    },
    {
      value: "2000_3000",
      label: "2,000-3,000 USD"
    },
    {
      value: "3000_4000",
      label: "3,000-4,000 USD"
    },
    {
      value: "4000_5000",
      label: "4,000-5,000 USD"
    },
    {
      value: "5000_6000",
      label: "5,000-6,000 USD"
    },
    {
      value: "6000_7000",
      label: "6,000-7,000 USD"
    },
    {
      value: "7000_8000",
      label: "7,000-8,000 USD"
    },
    {
      value: "8000_10000",
      label: "8,000-10,000 USD"
    },
    {
      value: "10000",
      label: "10,000+ USD"
    }
  ];
