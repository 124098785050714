import * as React from "react"
import { Input } from "@/components/ui/input"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { CONTRACT_TYPE, PAGINATION_SETTINGS } from "@/constants";
import { useQuery, useQueryClient } from "react-query";
import { useQueryState } from "./invoices.page";
import { useDebounce } from "@/components/ui/multiple-selector";
import { formatDateString } from "@/styles";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from "@/components/ui/table";
import { getSpecificaitons, getUser } from "@/service/weexpertService";
import { v4 as uuidv4 } from 'uuid';
import { Skeleton } from "@/components/ui/skeleton";
import { DataTablePagination } from "@/components/ui/table-pagination";
import { Loader } from "@/components/ProtectedRoute";
import { Badge } from "@/components/ui/badge";

export function SpecificationsPage(props) {

    const { t } = useTranslation();
    const [sharedFilter, setSharedFilter] = useQueryState('f', 'specifications_filter')
    const [searchQuery, setSearchQuery] = useState(null);
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const [page, setPage] = useState(JSON.parse(sharedFilter ?? '{}').page ?? 0);
    const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(sharedFilter ?? '{}').rowsPerPage ?? PAGINATION_SETTINGS.specifications);


    const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

    const { data: specifications, isLoading } = useQuery({
        queryKey: ['specifications', page, rowsPerPage, debouncedSearchQuery],
        queryFn: () => getSpecificaitons(rowsPerPage, page * rowsPerPage, debouncedSearchQuery)
    })


    useEffect(() => {
        setPage(pagination.pageIndex)
        setRowsPerPage(pagination.pageSize)
        setSharedFilter(JSON.stringify({ f: {}, uf: {}, page: pagination.pageIndex, rowsPerPage: pagination.pageSize }))
    }, [pagination])


    const columns = [
        {
            accessorKey: "contractId",
            header: t("contracts:contract"),
            cell: ({ row }) => {
                return <Link className="capitalize" to={`/specification/view/${row.original.id}`}>
                    {`${row.original.contract.contractId}`}
                </Link>
            },
            key: uuidv4()
        },
        {
            header: t("client"),
            cell: ({ row }) => {
                return <Link className="capitalize" to={`/specification/view/${row.original.id}`}>
                    {row.original.contract.client.firstName}  {row.original.contract.client.lastName}
                </Link>
            },
            key: uuidv4()
        },
        {
            accessorKey: "contractType",
            header: t("contracts:contract_type"),
            cell: ({ row }) => {
                return <Link className="capitalize" to={`/specification/view/${row.original.id}`}>
                    {row.original.contract.contractType == CONTRACT_TYPE.UMOWA_O_DZIELO ? <Badge className={'bg-emerald-600'}> Umowa o dzielo </Badge> : <Badge > Umowa zlecenia</Badge>}
                </Link>
            },
            key: uuidv4()
        },
        {
            accessorKey: "createdAt",
            header: t("specifications:spec_createdAt"),
            cell: ({ row }) => {
                return <Link className="capitalize" to={`/specification/view/${row.original.id}`}>
                    {`${formatDateString(row.original.createdAt)}`}
                </Link>
            },
            key: uuidv4()
        },
    ]

    const table = useReactTable({
        data: isLoading ? Array(rowsPerPage).fill({}) : specifications.data.specifications,
        columns: isLoading ? columns.map(column => {
            return {
                ...column,
                cell: () => <Skeleton className={"h-4 w-[150px]"} />
            }
        }) : columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        manualPagination: true,
        rowCount: specifications?.data.total ?? 0,
        state: {
            pagination
        },
        initialState: {
            pagination
        }
    });

    useEffect(() => {
        setPage(0);
    }, [debouncedSearchQuery]);

    const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
    if (isUserLoading) {
        return <Loader />
    }
    return (
        <>
            <div className="relative">
                <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
                    <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
                        <div className="ml-8 text-4xl ">{t("specifications:spec_title")}</div>
                        <div className="flex w-5/6">
                            <Input
                                placeholder={t("search")}
                                onChange={(event) => setSearchQuery(event.target.value)}
                                className="mr-2 ml-8 bg-white"
                            />
                        </div>
                        {user.data.roles !== 'CLIENT' &&
                            <Link to='/specification/create'>
                                <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                                    {t("button_create")}
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
                <div className="w-full px-8 py-4 bg-grayLightMainBg">
                    <div className="rounded-md border">
                        {!isLoading && (
                            <Table>
                                <TableHeader>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <TableRow key={uuidv4()}>
                                            {headerGroup.headers.map(header => {
                                                return (
                                                    <TableHead className="pt-3 pb-3"
                                                        key={uuidv4()}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </TableHead>
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {table.getRowModel().rows?.length ? (
                                        table.getRowModel().rows.map(row => (
                                            <TableRow
                                                key={row.id}
                                                data-state={row.getIsSelected() && "selected"}
                                            >
                                                {row.getVisibleCells().map(cell => (
                                                    <TableCell className="relative text-sm pt-3 pb-3"
                                                        key={uuidv4()}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length}
                                                className="h-24 text-center m-[0px]"
                                            >
                                                {t("no_results")}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </div>
                    <DataTablePagination key={page + 'page'} table={table} />
                </div>
            </div>
        </>
    )
}
