import { useParams } from "react-router-dom";
import { ReportInvoiceTable } from "./bank-report-invoices-table.component";
import { getInvoicesForReport } from "@/service/weexpertService";
import { BankReportTitle } from "./bank-report-transactions.pages";

export const MatchedReportInvoicesPage = (props) => {
  const { id } = useParams();
  return (
    <div className="relative">
      <div className="sticky left-0 z-10 w-full bg-grayLightMainBg">
        <BankReportTitle reportId={id} />
        <ReportInvoiceTable
          reportId={id}
          fetchFunction={(id, take, skip) => {
            return getInvoicesForReport(id, take, skip, null, [], "matched");
          }}
          cacheKey="invoice_matched"
        />
      </div>
    </div>
  );
};
export const UnmatchedReportInvoicesPage = (props) => {
  const { id } = useParams();
  return (
    <div className="relative">
      <div className="sticky left-0 z-10 w-full bg-grayLightMainBg pl-8">
        <BankReportTitle reportId={id} />
        <ReportInvoiceTable
          reportId={id}
          fetchFunction={(id, take, skip) => {
            return getInvoicesForReport(id, take, skip, null, [], "unmatched");
          }}
          cacheKey="invoice_all"
        />
      </div>
    </div>
  );
};
