import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../constants';
import { SelectWithAlert } from '../ui/select-with-alert';
import { InputWithAlert } from '../ui/input-with-alert';
import PhoneInput from 'react-phone-input-2';
import { Alert, AlertTitle } from '../ui/alert';
import { AlertCircle } from 'lucide-react';
export const USERS_FORM_ACTION_EDIT = 'EDIT';
export const USERS_FORM_ACTION_VIEW = 'VIEW';
export const USERS_FORM_ACTION_CREATE = 'CREATE';
export const UsersForm = (props) => {
    const { t } = useTranslation();
    const {
        action,
        user,
        inputChecked,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phone,
        setPhone,
        role,
        setRole,
        rolesData = () => { return [] },
        mailEdit
    } = props;

    const editable = (
        <div className='w-full'>
            <div className="w-full text-xl font-medium pb-6 ">
                {t("main_information_title")}
            </div>
            <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                    <InputWithAlert
                        error={(inputChecked && !firstName)}
                        errorMessage={t('empty_data_invalid')}
                        label={t("name") + '*'}
                        placeholder={t("name") + '*'}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
                <div className='w-[49%]'>
                    <InputWithAlert
                        error={(inputChecked && !lastName)}
                        errorMessage={t('empty_data_invalid')}
                        placeholder={t("last_name") + '*'}
                        label={t("last_name") + '*'}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
            </div>
            <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                    <InputWithAlert
                        error={(inputChecked && (!email || !validateEmail(email)))}
                        errorMessage={t('empty_data_invalid')}
                        placeholder='Email*'
                        label='Email*'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={!mailEdit}
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
                <div className='w-[49%]'>
                    <label htmlFor="phone-input" className="block text-black-700 mb-2 text-sm font-medium pb-[4px]">
                        {t('phone') + "*"}
                    </label>
                    <PhoneInput
                        country={'pl'}
                        value={phone}
                        onChange={(value) => setPhone(value)}
                        inputStyle={{ paddingLeft: '50px', width: '100%', height: '48px', border: '1px solid #e7e7e7' }}
                        buttonStyle={{ width: '40px', height: '48px', background: 'inherit', border: '1px solid #e7e7e7' }}
                        dropdownStyle={{ maxHeight: '200px', overflowY: 'scroll', border: '1px solid #e7e7e7', borderRadius: '6px' }}
                        searchStyle={{ borderRadius: '5px', border: '1px solid black' }}
                    />
                    {(inputChecked && (!phone)) && (<div className="mt-4">
                        <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
                            <AlertCircle className="h-4 w-4 !static !mr-2" />
                            <AlertTitle className="m-0 !p-0">
                                {!phone ? t('empty_data_invalid') : t('format_data_invalid')}
                            </AlertTitle>
                        </Alert>
                    </div>)}
                </div>
            </div>
            <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                    <SelectWithAlert
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                        label={t("users:role")}
                        placeholder={t("users:role")}
                        value={role}
                        onChangeValue={(e) => setRole(e)}
                        options={rolesData(t).map(({ value, display }) => {
                            return {
                                value: value,
                                label: display
                            }
                        })}
                    />
                </div>
            </div>
        </div>
    );
    const readable = (
        <div className='w-full'>
            <div className="w-full text-xl font-medium pb-6 ">
                {t("main_information_title")}
            </div>
            <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                    <InputWithAlert
                        error={(inputChecked && !firstName)}
                        errorMessage={t('empty_data_invalid')}
                        label={t("name") + '*'}
                        placeholder={t("name") + '*'}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
                <div className='w-[49%]'>
                    <InputWithAlert
                        placeholder={t("last_name")}
                        label={t("last_name")}
                        value={lastName}
                        onChange={(e) => { }}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
            </div>
            <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                    <InputWithAlert
                        placeholder={"Email"}
                        label={"Email"}
                        value={email}
                        onChange={(e) => { }}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
                <div className='w-[49%]'>
                    <InputWithAlert
                        placeholder={t("phone")}
                        label={t("phone")}
                        value={phone}
                        onChange={(e) => { }}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
            </div>
            <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                    <InputWithAlert
                        placeholder={t("users:role")}
                        label={t("users:role")}
                        onChange={(e) => { }}
                        value={(role === 'SUPER_ADMIN' ? t("users:SUPER_ADMIN") : (role === 'ADMIN' ? t('users:ADMIN') : (role === 'HEAD_OF_DEPARTMENT' ? t("users:HEAD_OF_DEPARTMENT") : t("users:ASSISTANT"))))}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                </div>
            </div>
        </div>
    );
    return action == USERS_FORM_ACTION_VIEW ? readable : editable;
}
export default UsersForm
