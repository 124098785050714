import * as React from "react"
import { CalendarIcon } from "@radix-ui/react-icons"
import { format } from "date-fns"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Label } from "@radix-ui/react-dropdown-menu"
import { AlertCircle } from "lucide-react"
import { Alert, AlertTitle } from "./alert"

export function DatePicker({ date, setDate, label, error = false, errorMessage = null, inputClassName, data_testid = null, disabled = false, ...props }) {
  const readOnly = typeof disabled === "boolean" && disabled
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div>
          <Label htmlFor="inputId" className="pb-1 text-sm font-medium">{label}</Label>
          <Button
            variant={"outline"}
            className={cn(
              `w-full justify-start text-left font-normal ${inputClassName}`,
              !date && "text-muted-foreground",
              readOnly && "bg-inherit focus:outline-none focus:shadow-none hover:bg-white hover:cursor-default:"
            )}
            data-testid={data_testid}
            style={readOnly ? { cursor: 'default', pointerEvents: 'none' } : {}}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "dd/MM/yyyy") : <span>{label}</span>}
          </Button>
          {error && (<div className="mt-4">
            <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
              <AlertCircle className="h-4 w-4 !static !mr-2" />
              <AlertTitle className="m-0 !p-0">
                {errorMessage}
              </AlertTitle>
            </Alert>
          </div>)}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start" >
        {!readOnly && (
          <Calendar
            mode="single"
            selected={date}
            fromYear={1940}
            toYear={2040}
            onSelect={setDate}
            captionLayout="dropdown-buttons"
            initialFocus
            data-testid={data_testid + '_calendar'}
            weekStartsOn={1}
            disabled={disabled}
            {...props}
          />
        )}
      </PopoverContent>
    </Popover>
  )
}
