import { BillForm } from "@/components/forms/bill.form";
import { Loader } from "@/components/ProtectedRoute";
import { toast } from "@/components/ui/use-toast";
import { createBill, getUser } from "@/service/weexpertService";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
export function BillsCreatePage(props) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
    const onSaved = (
        contract,
        contractType,
        taxDebuctible,
        pit,
        grossAmount,
        emerytalneRentowe,
        chorobowe,
        zdrowotna,
        status,
        bankAccount,
        conclusionDate,
        acceptedDate,
        specificationStage,
        signatory,
        comment,
        billid,
        currency,
        currencyDate
    ) => {
        if (!contract &&
            !bankAccount ||
            (+grossAmount) <= 0) {
            return
        }
        return createBill(contract.id,
            status,
            contractType,
            taxDebuctible,
            pit,
            format(conclusionDate, 'yyyy-MM-dd'),
            +grossAmount,
            emerytalneRentowe,
            chorobowe,
            zdrowotna,
            bankAccount,
            format(acceptedDate, 'yyyy-MM-dd'),
            signatory,
            comment,
            specificationStage ? specificationStage : null,
            currency ? currency : null,
            currencyDate ? format(currencyDate, 'yyyy-MM-dd') : null,
        ).then((res) => {
            toast({
                title: t("alerts:succes"),
            })
            navigate('/bills')
        }).catch((res) => {
            toast({
                title: t("alerts:error_send_fail"),
                variant: "destructive"
            })
        })
    }
    if (isUserLoading) {
        return <Loader />
    }
    if (user.data.roles === 'CLIENT') {
        navigate('/404')
    }

    return (
        <div className="w-full relative bg-grayLightMainBg px-8">
            <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
                <div className="flex justify-between pt-8  items-center ">
                    <div className=" w-5/12  text-4xl ">
                        {t("bills:create_bill")}
                    </div>
                </div>
            </div>
            <BillForm
                className={'w-10/12'}
                onSaved={onSaved}
            />
        </div >
    )
}
