import * as React from 'react';



const ZgodaPage = () => {


  return (
    <div className="max-w-[1300px] mx-auto bg-white shadow-md rounded-md  p-8 mt-36 ">
      <div class="p-8">
        <p class="text-lg font-bold mb-4">Zgoda na udostępnienie danych osobowych przez BIZNES EXPERT SP. Z O.O.</p>
        <p class="mb-2">Ja, na podstawie art. 6 ust. 1 lit. a) i b) RODO wyrażam zgodę na udostępnienie przez</p>
        <p class="mb-2">BIZNES EXPERT SP. Z O.O., z siedzibą przy ul. Gdańska 89/1, 85-022 Bydgoszcz, w</p>
        <p class="mb-2">ramach UMOWY WSPÓŁPRACY, a także jako administratora moich danych osobowych w</p>
        <p class="mb-2">ramach powyższej umowy, następujących danych osobowych:</p>
        <ul class="list-disc pl-6 mb-4">
          <li>imię i nazwisko,</li>
          <li>numer telefonu,</li>
          <li>email,</li>
          <li>CV,</li>
          <li>dostępność w godzinach tygodniowo,</li>
          <li>stawka godzinowa,</li>
        </ul>
        <p class="mb-4">potencjalnym podmiotom, które zgłoszą się do BIZNES EXPERT SP. Z O.O. w celu podjęcia z powyższą współpracy.</p>
        <p class="mb-4">Jestem świadomy, że mam prawo wycofać udzieloną zgodę, jednakże jest ona niezbędna do prawidłowego realizowania profilu freelancera.</p>
      </div>
    </div>
  );

}
export default ZgodaPage;