import { getClients, getUser } from "@/service/weexpertService";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultipleSelector from "../ui/multiple-selector";
import { BaseDialog } from "./dialog"
import { COMPANY_TYPE, COUNTRIES_LIST, ROLES } from '@/constants'
import { Button } from "@/components/ui/button"
import { useQuery } from "react-query";
import { Loader } from "../ProtectedRoute";
import { CheckIcon } from "@radix-ui/react-icons";
import MultipleSelectorWithSearch from "../ui/multiple-selector-with-search";
export const CounterpartyFilter = ({
  onFilterChange,
  currentFilter
}) => {
  const { t } = useTranslation();

  const { data: user, isLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const [filters, setFilters] = useState([]);
  const [unprocessedFilters, setUnprocessedFilter] = useState({
    selectedClients: [],
    selectedCounterpartiesCountry: [],
    selectedCompanyType: []
  });

  const transformAutocompleteValueToFilter = (valueArray, field) => {
    return {
      field: field,
      value: valueArray.map(e => e.id)
    }
  }
  const [selectedClients, setSelectedClients] = useState(currentFilter?.selectedClients ?? []);
  const [selectedCompanyType, setSelectedCompanyType] = useState(currentFilter?.selectedCompanyType ?? []);
  const [selectedCounterpartiesCountry, setSelectorCounterpartiesCountry] = useState(currentFilter?.selectedCounterpartiesCountry ?? []);


  useEffect(() => {
    const clientsFilter = transformAutocompleteValueToFilter(selectedClients, 'client');
    const companyType = transformAutocompleteValueToFilter(selectedCompanyType, 'counterpartyType')
    const countryFilter = transformAutocompleteValueToFilter(selectedCounterpartiesCountry, 'country');
    setUnprocessedFilter({
      selectedClients,
      selectedCounterpartiesCountry,
      selectedCompanyType,
    })
    setFilters({
      client: clientsFilter,
      country: countryFilter,
      counterpartyType: companyType,
    });
  }, [selectedClients, selectedCompanyType, selectedCounterpartiesCountry]);

  const [open, setOpen] = useState(false)

  if (isLoading) {
    return <Loader />
  }

  const isFilterNotEmpty = (filterData) => {
    return (Object.values(filterData).filter(e => e && (e && e.length > 0)).length > 0)
  }

  return <BaseDialog
    dialogTitle={t('filter')}
    buttonText={t('filter')}
    open={open}
    setOpen={(open) => {
      setOpen(open)
      if (!open) { // if closed we want to trigger filters
        onFilterChange(filters, unprocessedFilters)
      }
    }
    }

    icon={isFilterNotEmpty(currentFilter) ? <CheckIcon /> : null}
    ommitStyles={isFilterNotEmpty(currentFilter)}
  >
    <div className="w-[500px] pt-2 px-2 pb-[88px]">
      {
        user.data.roles !== ROLES.CLIENT && (
          <div>
            <MultipleSelectorWithSearch
              fetchOption={(q) => {
                return getClients(20, 0, q)
                  .then((result) => {
                    return result.data.clients
                      .map(client => {
                        return {
                          label: `${client.firstName} ${client.lastName}`,
                          value: `${client.firstName} ${client.lastName}`,
                          id: client.id
                        }
                      })
                  });
              }}
              className="min-h-12"
              placeholder={t("selected")}
              badgeClassName="bg-emerald-100 text-black"
              value={selectedClients}
              label={t("clients")}
              onChange={(newSelected) => {
                setSelectedClients(newSelected)
              }}
            />
          </div>)
      }
      <div>
        <MultipleSelector
          className="min-h-12"
          placeholder={t('selected')}
          badgeClassName="bg-emerald-100 text-black"
          value={selectedCompanyType}
          label={t('type')}
          onChange={(newSelected) => {
            setSelectedCompanyType(newSelected)
          }}
          options={COMPANY_TYPE(t).map(i => {
            // TODO rename keys
            return {
              value: i.id,
              label: i.value,
              id: i.id
            }
          })}
        />
      </div>
      <div>
        <MultipleSelector
          className="min-h-12"
          placeholder={t('selected')}
          badgeClassName="bg-emerald-100 text-black"
          value={selectedCounterpartiesCountry}
          label={t('country')}
          onChange={(newSelected) => {
            setSelectorCounterpartiesCountry(newSelected)
          }}
          options={COUNTRIES_LIST.map(i => {
            // TODO rename keys
            return {
              value: i.value,
              label: i.value,
              id: i.id
            }
          })}
        />
      </div>
    </div>
    <div className="px-2">
      <div className="w-full flex flex-col items-end">
        <div className="w-full flex justify-end">
          <Button className="mr-4" variant="outline" onClick={() => {
            onFilterChange([], {})
            setOpen(false)
          }}>{t("common:button_clean_filter")}</Button>
          <Button onClick={() => {
            onFilterChange(filters, unprocessedFilters);
            setOpen(false)
          }}>{t('button_save')}
          </Button>
        </div>
      </div>
    </div>
  </BaseDialog>
}
