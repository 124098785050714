import { getContracts } from "@/service/weexpertService";
import { SelectWithAlert } from "../ui/select-with-alert";
import { Button } from "../ui/button";
import { TrashIcon } from "lucide-react";
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "../ui/combobox-search";
import { InputWithAlert } from "../ui/input-with-alert";
import { toast } from "../ui/use-toast";
import format from "date-fns/format";
import { DatePicker } from "../ui/date-picker";
import { ANNEX_STATES, REPRESENTATIVE_COMPANY_LIST, Signatories, parseNumber, transformFromIdValueToValueLabel } from "@/constants";
const FieldTypes = {
  'grossAmount': 'grossAmount',
  'endDate': 'endDate',
};

const AnnexPosition = ({ id, field, value, onDelete, onChange }) => {
  const { t } = useTranslation();
  const [currentField, setField] = useState(field);
  const [currentValue, setValue] = useState(value);

  useEffect(() => {
    if (currentField === FieldTypes.endDate && currentValue) {
      const t = new Date(currentValue);
      onChange({ id, field: currentField, value: new Date(t.getTime() - (t.getTimezoneOffset() * 60000)) });
      return;
    }
    onChange({ id, field: currentField, value: currentValue});
  }, [currentField, currentValue]);

  return <div className="w-full flex justify-between items-center">
    <div className="w-2/5">
      <SelectWithAlert
        label={t("contracts:annex_quota")}
        options={[{
          value: FieldTypes.grossAmount,
          label: 'Kwota brutto'
        }, {
          value: FieldTypes.endDate,
          label: 'End date'
        }]}
        value={currentField ?? ''}
        onChangeValue={setField}
        inputClassName={'h-12  mb-4 bg-white'}
      />
    </div>
    <div className="w-2/5">
      {currentField == FieldTypes.grossAmount &&
        <InputWithAlert
          label={t("contracts:annex_new_value")}
          value={currentValue}
          onChange={(e) => setValue(parseNumber(e.target.value))}
          inputClassName={'h-12 mb-4 bg-white'}
        />
      }
      {currentField == FieldTypes.endDate &&
        <DatePicker
          label={t("specifications:spec_end_date")}
          date={currentValue ? new Date(currentValue) : null}
          defaultMonth={currentValue ? new Date(currentValue) : new Date()}
          setDate={setValue}
          inputClassName={'h-[48px] min-w-[110px] text-base mb-[16px]'}
        />}

    </div>
    <Button className="h-12 w-12" onClick={() => onDelete(id)}>
      <TrashIcon />
    </Button>
  </div>
}

export const AnnexForm = ({ onSaved, annex, initialContract = null }) => {
  const [contract, setContract] = useState(initialContract);
  const [changes, setChanges] = useState(annex ? JSON.parse(annex.changesAsJson).map(e => {
    return {
      ...e,
      id: uuidv4()
    }
  }) : [])
  const [acceptedDate, setAcceptedDate] = useState(annex ? new Date(annex.acceptedDate) : new Date());
  const [signatory, setSignatory] = useState(annex ? annex.signatory : '');
  const [representativeCompany, setRepresentativeCompany] = useState(annex ? (annex.representativeCompany ??  initialContract.representativeCompany) : initialContract.representativeCompany);
  const [status, setStatus] = useState(annex ? annex.state : 'Pending');
  const { t } = useTranslation()
  const onDelete = (id) => {
    setChanges((prev) => {
      return prev.filter(e => e.id !== id);
    })
  }
  return (
    <>
      <div className="pt-5 flex justify-end">
        <Button className="w-36"
          onClick={() => {
            if (changes.length <= 0 || changes.filter((e) => !!e.field).length <= 0 || changes.filter((e) => !!e.value).length !== changes.filter((e) => !!e.field).length) {
              toast({
                title: t('empty_data_invalid'),
                variant: 'destructive'
              })
              return
            }
            onSaved(
              contract.id,
              changes.map(e => {
                return {
                  field: e.field,
                  value: e.value
                }
              }),
              status,
              format(acceptedDate, 'yyyy-MM-dd'),
              signatory,
              representativeCompany
            )
          }}
        >
          {t("button_save")}
        </Button>
      </div>
      <div className={initialContract ? 'w-full' : 'w-5/12'}>
        <div className="text-xl font-medium ">
          {t("contracts:annex")}
        </div>
        <div className='w-full flex-wrap flex justify-between pt-6'>
          <div className='w-full'>
            <Search
              fetchOption={(query) => {
                return getContracts(100, 0, query, [])
                  .then((res) => {
                    return res.data.contracts
                      .map(contract => {
                        return {
                          label: contract.contractId,
                          value: contract.id,
                          contract: contract
                        }
                      })
                  })
              }}
              placeholder={t('contracts:contract')}
              label={t('contracts:contract')}
              disabled={true}
              readOnly={true}
              value={contract ? contract.contractId : null}
              onChange={
                (e) => {
                  setContract(e.contract);
                  setContractType(e.contract.contractType)
                  setPit(e.contract.pit);
                  setTaxDeductible(e.contract.taxDeductible);
                }
              }
            />
          </div>
          {contract && (<SelectWithAlert
            label={t("bills:bills_signatory")}
            options={Signatories[contract.representativeCompany]}
            value={signatory}
            onChangeValue={setSignatory}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />)
          }
          {contract && (<SelectWithAlert
            label={t("status")}
            options={ANNEX_STATES(t)}
            value={status}
            onChangeValue={setStatus}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />)
          }
          {contract && (
            <SelectWithAlert
              label={t('clients:representative_company') + "*"}
              value={representativeCompany}
              onChangeValue={(e) => setRepresentativeCompany(e)}
              options={REPRESENTATIVE_COMPANY_LIST.map(transformFromIdValueToValueLabel)}
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          )
          }

          <div className="w-full">
            <DatePicker
              label={t("contracts:conclusion_date")}
              date={acceptedDate}
              defaultMonth={acceptedDate}
              setDate={(date) => {
                if (!date) return
                setAcceptedDate(date)
              }
              }
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          </div>
          <div className='w-full'>
            {changes.map(e => <AnnexPosition key={e.value + e.id} id={e.id} field={e.field}
              value={e.value} onDelete={onDelete}
              onChange={({ id, field, value }) => {
                setChanges((prev) => {
                  prev[prev.findIndex(el => el.id == id)] = { id, field, value }
                  return prev
                })
              }} />)}
          </div>
          <div className="w-full flex justify-end">
            <Button className="w-1/4 h-12" variant="outline" onClick={() => {
              setChanges((prev) => {
                return prev.concat({ id: uuidv4(), field: FieldTypes.grossAmount, value: '' })
              })
            }}>
              {t('button_add')}
            </Button>
          </div>
        </div>
      </div>
    </>)

}
