import * as React from 'react';
import { useState } from 'react';
import { unassignClient } from '../../../service/weexpertService';
import { useTranslation } from 'react-i18next';
import { Cross1Icon } from '@radix-ui/react-icons';
import { BaseDialog } from '@/components/popups/dialog';
import { Button } from '@/components/ui/button';

const UnpinClientFromUser = (props) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const { name, id, handleRefreshData } = props
    const unpinClient = () => {
        if (id) {
            unassignClient(id)
                .then((response) => {
                    handleRefreshData();
                    setOpen(false)
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        console.log(error);
                    } else {
                        console.error(error);
                    }
                });
        } else {
            console.log('?');
        }
    };

    return (
        <>
            <BaseDialog
                dialogTitle={t("users:unpin_question")}
                buttonText={""}
                open={open}
                setOpen={setOpen}
                icon={<Cross1Icon />}
            >
                <div className="w-[500px] ">
                    <div className='text-base'>{name}</div>
                    <div className='w-full flex justify-end'>
                        <Button className='mr-2'
                            onClick={() => setOpen(false)}>
                            {t("button_back")}
                        </Button>
                        <Button onClick={() => {
                            unpinClient();
                        }}>
                            {t("users:unpin")}
                        </Button>
                    </div>
                </div>
            </BaseDialog>
        </>
    )
}
export default UnpinClientFromUser
