import { Loader } from "@/components/ProtectedRoute";
import { Button } from "@/components/ui/button"
import { InputWithAlert } from "@/components/ui/input-with-alert"
import { toast } from "@/components/ui/use-toast";
import { CONTRACT_TAX_DEDUCTIBLES, CONTRACT_TYPE } from "@/constants";
import { getBill, getBillPDF, getUser } from "@/service/weexpertService";
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
export const BillViewPage = () => {
    const { t } = useTranslation()
    const { id } = useParams();
    const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
    const userRole = user.data.roles;
    const { data: bill, isLoading, isFetched } = useQuery({
        queryKey: ['bill', id], queryFn: () => {
            return getBill(id)
        }
    })

    if (isLoading || isUserLoading) {
        return <Loader />
    }
    if (!isFetched) {
        return <Loader />
    }
    const data = bill.data.bill
    const taxDeductibleString = CONTRACT_TAX_DEDUCTIBLES(t).find(e => e.value === data.taxDeductible).label ?? 'Not known';
    const billType = data.billType;

    return (
        <>
            <div className="relative bg-grayLightMainBg px-8">
                <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
                    <div className="flex justify-between py-8 items-center ">
                        <div className=" w-5/12  text-3xl ">
                            {t("bills:bills_details")} {data.billId}
                        </div>
                        <div className='w-6/12 flex justify-end'>
                            <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                                onClick={() => {
                                    toast({
                                        title: t("generation_pdf_started")
                                    })
                                    getBillPDF(id, data.billId)
                                        .then(() => {
                                            toast({
                                                title: t("generation_pdf_success")
                                            })
                                        })
                                        .catch((res) => {
                                            Sentry.captureException(res);
                                            toast({
                                                title: t("generation_pdf_error"),
                                                variant: "destructive"
                                            })
                                        })
                                }}
                            >
                                PDF
                            </Button>
                            {userRole !== 'CLIENT' && (
                                <Link to={`/bill/edit/${id}`}>
                                    <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                                        {t('button_change')}
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                <div className='w-full py-8'>
                    <div className='w-full flex justify-between'>
                        <div className='w-[49%]'>
                            {userRole !== 'CLIENT' &&
                                <InputWithAlert
                                    label={t('client')}
                                    value={`${data.contract.client.firstName} ${data.contract.client.lastName}`}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            }
                            <InputWithAlert
                                label={t("contracts:subject_metter")}
                                value={`${data.specificationItem ? data.specificationItem.description : data.contract.subjectMatter}`}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            <InputWithAlert
                                label={t('bills:bills_signatory')}
                                value={`${data.signatory}`}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />

                            <InputWithAlert
                                label={t('bills:bills_brutto')}
                                value={data.grossAmount}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />

                            <InputWithAlert
                                label={t("invoices:currency")}
                                value={data.currency}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            {data.currency && data.currency != 'PLN' && (<>
                                <InputWithAlert
                                    label={t('invoices:currency_rate_PLN')}
                                    value={data.currencyRate}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                                <InputWithAlert
                                    label={t("invoices:payment_currency_date")}
                                    value={format(new Date(data.currencyRateDate), 'dd.MM.yyyy')}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            </>)}
                            <InputWithAlert
                                label={`${t('bills:bills_kup')} ${taxDeductibleString}`}
                                value={data.taxDeductibleAmount}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            <InputWithAlert
                                label={t("bills:bills_income")}
                                value={data.income}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                <InputWithAlert
                                    label={t("bills:bills_insuranse")}
                                    value={data.totalContribution}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            }
                            {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                <InputWithAlert
                                    label={t("bills:bills_pension")}
                                    value={data.pensionContributionEmployer}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />

                            }
                            {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                <InputWithAlert
                                    label={t("bills:bills_anuity")}
                                    value={data.disabilityContributionEmployer}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            }
                        </div>
                        <div className='w-[49%]'>
                            {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                <InputWithAlert
                                    label={t("bills:bills_sick_leave")}
                                    value={data.sicknessContribution}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            }
                            <InputWithAlert
                                label={t("bills:bills_taxes")}
                                value={data.taxationBase}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            <InputWithAlert
                                label={t("bills:bills_income_tax")}
                                value={data.taxAmount}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                <InputWithAlert
                                    label={t("bills:bills_medical_insurance")}
                                    value={data.healthInsuranceContribution}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            }
                            {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                <InputWithAlert
                                    label={t("bills:bills_tax_advance")}
                                    value={data.taxPrepay}
                                    readOnly={true}
                                    disabled
                                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                />
                            }
                            <InputWithAlert
                                label={t("bills:bills_pay")}
                                value={data.toPay}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                            <InputWithAlert
                                label={"Comment"}
                                value={data.comment}
                                readOnly={true}
                                disabled
                                inputClassName={'h-12 mt-2 mb-4 bg-white'}
                            />
                        </div>
                    </div>
                    {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                        <>
                            <div className=" text-2xl pb-4">
                                {t("bills:bills_resume")}
                            </div>
                            <div className='w-full flex justify-between'>
                                {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                    <div className='w-[49%]'>
                                        <InputWithAlert
                                            label={t("bills:bills_pension_insurance")}
                                            value={data.pensionContributionEmployer}
                                            readOnly={true}
                                            disabled
                                            inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                        />
                                        <InputWithAlert
                                            label={t("bills:bills_disability_insurance")}
                                            value={data.disabilityContributionEmployer}
                                            readOnly={true}
                                            disabled
                                            inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                        />
                                        <InputWithAlert
                                            label={t("bills:bills_accident_insurance")}
                                            value={data.accedentInsurenseEmployer}
                                            readOnly={true}
                                            disabled
                                            inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                        />
                                        <InputWithAlert
                                            label={t("bills:bills_labor_fund")}
                                            value={data.laborFund}
                                            readOnly={true}
                                            disabled
                                            inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                        />
                                        <InputWithAlert
                                            label={'FGSP'}
                                            value={data.FGSP}
                                            readOnly={true}
                                            disabled
                                            inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                        />
                                    </div>
                                }
                                <div className='w-[49%]'>
                                    <InputWithAlert
                                        label={t("bills:bills_net_amount")}
                                        value={data.toPay}
                                        readOnly={true}
                                        disabled
                                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                    />

                                    {billType != CONTRACT_TYPE.UMOWA_O_DZIELO &&
                                        <InputWithAlert
                                            label={t("bills:bills_deductions")}
                                            value={data.employeeCost}
                                            readOnly={true}
                                            disabled
                                            inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                        />
                                    }
                                    <InputWithAlert
                                        label={t("bills:bills_total_amount")}
                                        value={data.grossAmount}
                                        readOnly={true}
                                        disabled
                                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                    />
                                    <InputWithAlert
                                        label={t("bills:bills_expenses")}
                                        value={data.employerCost}
                                        readOnly={true}
                                        disabled
                                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                    />
                                    <InputWithAlert
                                        label={t("bills:bills_amount_of_fees")}
                                        value={(+data.employeeCost + +data.employerCost)}
                                        readOnly={true}
                                        disabled
                                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>)
}

