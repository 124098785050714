
import { CounterpartyFilter } from '@/components/popups/counterparty.filter.popup';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { DataTablePagination } from '@/components/ui/table-pagination';
import { GET_COUNTRY_NAME_BY_CODE, PAGINATION_SETTINGS, ROLES } from "@/constants";
import { getContractors, getCounterpartyExcel, getUser } from '@/service/weexpertService';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useQueryState } from './invoices.page';
import { useDebounce } from "@/components/ui/multiple-selector";
import { Badge } from '@/components/ui/badge';
import { toast } from '@/components/ui/use-toast';

const CounterpartiesPage = () => {

  const { t } = useTranslation();
  const [sharedFilter, setSharedFilter] = useQueryState('f', 'counterparty_filter')
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [filters, setFilters] = useState(JSON.parse(sharedFilter ?? '[]').f ?? []);
  const [unprocessedFilter, setUnprocessedFilter] = useState(JSON.parse(sharedFilter ?? '{}').uf ?? {});
  const [page, setPage] = useState(JSON.parse(sharedFilter ?? '{}').page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(sharedFilter ?? '{}').rowsPerPage ?? PAGINATION_SETTINGS.invoices);

  const { data: counterparties, isLoading } = useQuery({
    queryKey: ['counterparties', filters, debouncedSearchQuery, page, rowsPerPage],
    queryFn: () => getContractors(rowsPerPage, page * rowsPerPage, debouncedSearchQuery, Object.values(filters))
  })

  const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const columns = [
    {
      accessorKey: "name",
      header: t('counterparty'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/admin/contractors/details/${row.original.id}`}>
          {row.getValue("name")}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "NIP",
      header: 'NIP',
      cell: ({ row }) => (

        <Link className="capitalize w-full block h-full" to={`/admin/contractors/details/${row.original.id}`}>
          {row.getValue("NIP")}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "address",
      header: t('address'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/admin/contractors/details/${row.original.id}`}>
          {row.getValue("address")}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "city",
      header: t('city'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/admin/contractors/details/${row.original.id}`}>
          {row.getValue("city")}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "country",
      header: t('country'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/admin/contractors/details/${row.original.id}`}>
          {GET_COUNTRY_NAME_BY_CODE(row.getValue("country"))}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "active",
      header: t('status'),
      cell: ({ row }) => (
        row.original.active ?
          (<Badge className={"bg-green-500"}>{t('active')}</Badge>) :
          (<Badge className={"bg-rose-500"}>{t('unactive')}</Badge>)
      ),
      key: uuidv4()
    },
  ]

  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: pagination.pageIndex, rowsPerPage: pagination.pageSize }))
  }, [pagination])

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : counterparties.data.counterparties,
    columns: isLoading ? columns.map((column) => {
      return {
        ...column,
        cell: () => {
          return <Skeleton className='h-4 w-[200px]' />
        }
      }
    }) : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: counterparties?.data.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery]);

  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">{t('counterparties')}</div>
            <div className="flex w-5/6">
              <Input
                placeholder={t('search')}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mr-2 ml-8 bg-white"
              />
              <Link to='/admin/contractors/create'>
                <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                  {t('counterparties:button_add')}
                </Button>
              </Link>
              {<CounterpartyFilter
                key={JSON.stringify(filters)}
                onFilterChange={(filters, unprocessedFilter) => {
                  setFilters(filters);
                  setUnprocessedFilter(unprocessedFilter)
                  setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: 0, rowsPerPage: pagination.pageSize }))
                  setPagination({ pageIndex: 0, pageSize: rowsPerPage })
                }}
                currentFilter={unprocessedFilter}
              />}
              {!isUserLoading && user.data.roles !== ROLES.ASSISTANT &&
                <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                  onClick={() => {
                    getCounterpartyExcel()
                      .then(() => {
                        toast({
                          title: "Success"
                        })
                      })
                  }}
                >
                  Excel
                </Button>
              }
            </div>

          </div>
        </div>
        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.original.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  );

}
export default CounterpartiesPage;
