import * as React from "react";
import { cn } from "@/lib/utils";
import { AlertCircle } from "lucide-react";
import { Alert, AlertTitle } from "./alert";

const Textarea = React.forwardRef(({ className, error, errorMessage, disabled = false, ...props }, ref) => {
  return (
    <div className="relative">
      <textarea
        readOnly={disabled}
        className={cn(
          `flex min-h-[60px] w-full rounded-md border px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none ${disabled ? 'bg-inherit focus-visible:ring-0' : 'focus-visible:ring-1'} disabled:cursor-not-allowed disabled:opacity-50`,
          className
        )}
        ref={ref}
        {...props}
      />
      {error && (<div className="mt-4">
        <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
          <AlertCircle className="h-4 w-4 !static !mr-2" />
          <AlertTitle className="m-0 !p-0">
            {errorMessage}
          </AlertTitle>
        </Alert>
      </div>)
      }
    </div>
  );
});
Textarea.displayName = "Textarea";

export { Textarea };