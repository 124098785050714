import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';

import mainImg from '../assets/img/Cover.png';

const MainPage = () => {
  const bgStyleForMain = {
    padding: 0,
    height: '94.4vh',
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundImage: `url(${mainImg})`,
    backgroundSize: 'cover',
    backgroundPosition: ' 0 center',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <Grid container  sx={bgStyleForMain} />
  );

}
export default MainPage;
