import { BaseDialog } from "./dialog"
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Combobox } from "../ui/combobox";
import { createSharedClientPool, getUsers } from "@/service/weexpertService";
import { toast } from "../ui/use-toast";
import { useQuery } from "react-query";
import { InputWithAlert } from "../ui/input-with-alert";

export default function CreateSharedPoolPopup({ clientIds }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [user, setUser] = useState(null);
  const { data: assistans, isLoading } = useQuery({ queryKey: 'assistants', queryFn: () => getUsers(1000, 0), retry: false })
  const inputChecked = false
  const onClickSendButtonHandler = () => {
    createSharedClientPool(user.content.id, name, clientIds)
      .then((_) => {
        toast({
          title: t('success')
        })
      })
      .catch(e => {
        toast({
          title: t('success'),
          variant: 'destructive'
        })

      })
      .finally(() => {
        setOpen(false)
      })
  };
  if (isLoading) {
    return
  }
  return <BaseDialog
    dialogTitle={t("client_access_transfer")}
    buttonText={t("client_access_transfer")}
    open={open}
    setOpen={setOpen}
  >
    <div className="w-[500px] flex flex-col items-end ">
      <div className="w-full py-4 bg-grayLightMainBg">
        <InputWithAlert
          data-testid="client_name"
          error={(inputChecked && (!name || !validateNoneCyrillic(name)))}
          errorMessage={!name ? t('empty_data_invalid') : t('format_data_invalid')}
          label={t('name') + "*"}
          placeholder={t('name') + "*"}
          value={name}
          onChange={(e) => { setName(e.target.value) }}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        <Combobox
          className="min-h-12 mt-2  bg-white"
          placeholder={t('selected')}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={user}
          label={t('assistant')}
          readOnly={false}
          error={inputChecked && !selectedAssistant}
          errorMessage={t('empty_data_invalid')}
          onChange={(newSelected) => {
            setUser(newSelected);
          }}
          options={assistans.data.users.map(u => {
            return {
              content: u,
              value: `${u.firstName} ${u.lastName}`,
              label: `${u.firstName} ${u.lastName}`,
            }
          })}
        />
      </div>
      <Button onClick={() => { onClickSendButtonHandler(); }} >
        {t("invoices:button_clone")}
      </Button>
    </div>
  </BaseDialog>
}
