import * as React from 'react';
import { useState, useEffect } from 'react';
import { getClients } from '../../service/weexpertService';
import { PAGINATION_SETTINGS } from '../../constants';
import { formatDateString } from '../../styles';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import { memo } from "react";
import { v4 as uuidv4 } from 'uuid';
import { DataTablePagination } from '@/components/ui/table-pagination';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { BaseDialog } from '@/components/popups/dialog';
import { useDebounce } from "@/components/ui/multiple-selector";

export default function AssignClientTablePopup(props) {

  const { header, person, initialSelection, onSelect, onUnselect } = props;

  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0);
  const [clients, setClients] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_SETTINGS.clients);
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [selected, setSelected] = useState(initialSelection);
  const [rows, setRows] = useState([]);
  const [realData, setRealData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: rowsPerPage });

  useEffect(() => {
    getClients(rowsPerPage, page * rowsPerPage, debouncedSearchQuery)
      .then(res => {
        setRealData(res.data);
      });
  }, [rowsPerPage, page, debouncedSearchQuery]);


  const handleClick = (event, clientId) => {
    const selected_without_client = selected.filter(e => e !== clientId);
    const is_removed = selected_without_client.length !== selected.length;
    if (is_removed) {
      onUnselect(clientId);
      setSelected(selected_without_client);
      return
    }
    onSelect(clientId);
    setSelected(selected.concat([clientId]));
  };



  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
  }, [pagination])

  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery]);
  const columns = [
    {
      id: "select",
      cell: ({ row }) => {
        const isItemSelected = initialSelection && initialSelection.some(client => client === row.original.id);
        return <Checkbox className=' flex flex-row items-center justify-center py-0'
          onClick={(e) => { handleClick(e, row.original.id) }}
          checked={isItemSelected}
          aria-label="Select row"
        />
      },
      enableSorting: false,
      enableHiding: false
    },
    {
      accessorKey: "name",
      header: t("name"),
      cell: ({ row }) => (
        <div>
          {row.original.firstName + ' ' + row.original.lastName}
        </div>
      ),
      key: uuidv4()
    },

    {
      accessorKey: "email",
      header: "email",
      cell: ({ row }) => (
        <div>
          {row.original.email}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "phone",
      header: t("phone"),
      cell: ({ row }) => (
        <div>
          {row.original.phone}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "status",
      header: t("status"),
      cell: ({ row }) => (
        <div>
          {row.original.isActive ? "true" : "false"}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "dateOfCreation",
      header: t("created"),
      cell: ({ row }) => (
        <div>
          {formatDateString(row.original.createdAt)}
        </div>
      ),
      key: uuidv4()
    },

  ]
  const table = useReactTable({
    data: realData.clients ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: realData?.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  return (
    <>
      <BaseDialog
        dialogTitle={header}
        buttonText={t('users:buttom_add_client')}
        open={open}
        setOpen={setOpen}
      >
        <div className="w-[1000px]  bg-grayLightMainBg">
          <div className='text-base'>
            {person.firstName ? `${person.firstName} ${person.lastName}` : person.name}
          </div>
          <div className='py-4'>
            <Input
              placeholder={t("search")}
              onChange={(event) => setSearchQuery(event.target.value)}
              className=" bg-white"
            />
          </div>
          <div className="rounded-md border p-2 h-[400px] box-border overflow-y-scroll	">
            {realData && (
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        {t("no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <div className='pt-4'>
            <DataTablePagination table={table} />
          </div>
        </div>
      </BaseDialog>
    </>
  );
}

