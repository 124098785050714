import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { InvoiceItem } from "./InvoiceItem";
import { grayButton } from "../../../../styles";
import { useTranslation } from "react-i18next";
import { Button } from '@/components/ui/button';


import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Alert, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";

export const PositionBlock = ({ rows, onRowsUpdate, error, errorMessage }) => {

    const { t } = useTranslation();
    const [localRows, setLocalRows] = useState(rows);
    const [stateHash, setStateHash] = useState([]);

    const transformRows = (item) => {
        return {
            id: item.id,
            name: item.name,
            pkwiu: item.PKWIU,
            quantity: +item.amount,
            GTU: item.GTU ?? '-',
            unit: item.unit,
            unitPriceNetto: +item.priceNetto,
            priceNetto: +item.totalNetto,
            vatRate: item.vatType,
            vat: +item.vat,
            priceBrutto: +item.totalBrutto,

        }
    }
    useEffect(() => {
        onRowsUpdate(localRows.map(e => {
            const tmp = transformRows(e)
            return tmp
        }));
    }, [stateHash])

    const handleItemUpdate = (item) => {
        let localCopy = localRows;
        const index = localCopy.findIndex(e => e.id == item.id);
        localCopy[index] = item;
        setLocalRows(localCopy);
        setStateHash(JSON.stringify(localCopy))
    }

    const handleItemDelete = (itemId) => {
        let localCopy = localRows.filter(e => e.id != itemId);
        setLocalRows(localCopy);
        setStateHash(JSON.stringify(localCopy))
    }

    return (
        <div className="w-full ovvv">
            <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '30px 0px 40px 0px', color: 'inherit', }}>{t("invoices:positions_title")}</Typography>
            <Table>
                <TableCaption>{t("invoices:positions_title")}</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead  >{t("invoices:positions_name")}</TableHead>
                        <TableHead align="left">GTU</TableHead>
                        <TableHead align="left">PKWIU</TableHead>
                        <TableHead align="left">{t("invoices:positions_amount")}</TableHead>
                        <TableHead align="left">{t("invoices:positions_unit")}</TableHead>
                        <TableHead align="left">{t("invoices:positions_price_netto")}</TableHead>
                        <TableHead align="left">{t("invoices:positions_summary_netto")}</TableHead>
                        <TableHead align="left">{t("invoices:positions_type_of_vat")}</TableHead>
                        <TableHead align="left">{t("invoices:positions_vat")}</TableHead>
                        <TableHead align="left">{t('invoices:positions_price_brutto')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {localRows && localRows.map((row) => (
                        <InvoiceItem id={row.id} key={row.id}
                            onDelete={(id) => handleItemDelete(id)}
                            onEdit={(e) => handleItemUpdate(e)}
                            item={
                                {
                                    name: row.name,
                                    GTU: row.GTU,
                                    pkwiu: row.pkwiu,
                                    amount: row.quantity,
                                    unit: row.unit,
                                    priceNetto: row.unitPriceNetto,
                                    vatType: row.vatRate,
                                    vat: row.vat,
                                    priceBrutto: row.priceBrutto,
                                }
                            }
                        />
                    ))}
                </TableBody>
            </Table>
            {error && (<div className="mt-4">
                <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
                    <AlertCircle className="h-4 w-4 !static !mr-2" />
                    <AlertTitle className="m-0 !p-0">
                        {errorMessage}
                    </AlertTitle>
                </Alert>
            </div>)
            }

            <div className="pt-8">
                <Button onClick={() => {
                    setLocalRows(prevRows => {
                        return prevRows.concat({
                            id: uuidv4(),
                            name: '',
                            pkwiu: '',
                            quantity: 0,
                            unit: '',
                            unitPriceNetto: 0,
                            priceNetto: 0,
                            vatRate: '23%',
                            vat: 0,
                            priceBrutto: 0,
                        })
                    })
                }
                }>
                    {"+ " + t("invoices:button_add_position")}
                </Button>
            </div>

        </div>
    )
}
