import React, { useEffect, useState } from "react"
import { IconButton } from '@mui/material';
import { vatData, GTUData, UNIT_VARIANTS } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { TrashIcon } from "lucide-react";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { TableCell, TableRow, } from "@/components/ui/table"
import { TextField } from "@radix-ui/themes";
import { Textarea } from "@/components/ui/textarea";

const toFixed = (num, size) => Number((+num).toFixed(size))

export const vatMap = {
  '23%': 23,
  "8%": 8,
  "5%": 5,
  "0%": 0,
  "zw.": 0,
  "np.": 0,
  "np.EU": 0
};

export const InvoiceItem = (props) => {

  const { t } = useTranslation();
  const { id, onDelete, onEdit, item } = props
  const [name, setName] = useState(item ? item.name : '');
  const [GTU, setGTU] = useState(item ? item.GTU : '-');
  const [PKWIU, setPKWIU] = useState(item ? item.pkwiu : '');
  const [amount, setAmount] = useState(item ? +item.amount != 0 ? +item.amount : 1.0 : 1.0);
  const [unit, setUnit] = useState(item ? item.unit !== '' ? item.unit : 'szt.' : 'szt.');
  const [priceNetto, setPriceNetto] = useState(item ? toFixed(+item.priceNetto, 2) : 0.0); // || totalNetto / amount
  const [vatType, setVatType] = useState(item ? item.vatType : '23%');
  const [totalBrutto, setTotalBrutto] = useState(item ? toFixed(+item.priceBrutto, 2) : 0.0);
  const [totalNetto, setTotalNetto] = useState(item ? toFixed(+item.priceNetto * amount, 2) : 0.0);
  const [vat, setVat] = useState(item ? +item.vat : 0.0);
  const [updatePriceNetto, setUpdatePriceNetto] = useState(false);
  const [updateTotalNetto, setUpdateTotalNetto] = useState(false);
  const [updateTotalBrutto, setUpdateTotalBrutto] = useState(false);
  const sanitazeInput = (value) => {
    const sanitizedValue = value.replace(/[^\d., ]/g, '');
    if (sanitizedValue.startsWith('.') || sanitizedValue.startsWith(',')) {
      return;
    }
    const spaceRemovedValue = sanitizedValue.replace(/\s/g, '');
    let dotCount = 0;
    let commaCount = 0;

    const formattedValue = spaceRemovedValue.replace(/[,.\s]/g, (match) => {
      if (match === '.') {
        dotCount++;
        return dotCount === 1 && commaCount === 0 ? '.' : '';
      } else if (match === ',') {
        commaCount++;
        return commaCount === 1 && dotCount === 0 ? '.' : '';
      } else {
        return match;
      }
    });
    return formattedValue
  };
  const handleAmountChange = (event) => {
    setAmount(sanitazeInput(event.target.value))
  };

  const handlePriceNettoChange = (event) => {
    setPriceNetto(sanitazeInput(event.target.value))
    setUpdatePriceNetto(true);
  };

  const handleTotalNettoChange = (event) => {
    setTotalNetto(sanitazeInput(event.target.value))
    setUpdateTotalNetto(true);
  };

  const handleTotalBruttoChange = (event) => {
    setTotalBrutto(sanitazeInput(event.target.value));
    setUpdateTotalBrutto(true)
  };
  useEffect(() => {
    const totalNetto = priceNetto * amount;
    const vat = (priceNetto / 100) * vatMap[vatType] * amount
    const totalBrutto = totalNetto + vat;
    setTotalBrutto(toFixed(totalBrutto, 2));
    setTotalNetto(toFixed(totalNetto, 2));
    setVat(toFixed(vat, 2));
    onEdit({
      id: id,
      name,
      GTU,
      PKWIU,
      amount,
      unit,
      priceNetto,
      totalBrutto,
      totalNetto,
      vatType,
      vat
    })
  }, [amount]);

  useEffect(() => {
    if (updatePriceNetto) {
      const totalNetto = priceNetto * amount;
      const vat = (priceNetto / 100) * vatMap[vatType] * amount
      const totalBrutto = totalNetto + vat;
      setVat(toFixed(vat, 2));
      setTotalBrutto(toFixed(totalBrutto, 2));
      setTotalNetto(toFixed(totalNetto, 2));
      setUpdatePriceNetto(false);
      onEdit({
        id: id,
        name,
        GTU,
        PKWIU,
        amount,
        unit,
        priceNetto,
        totalBrutto,
        totalNetto,
        vatType,
        vat
      })
    }
  }, [priceNetto]);

  useEffect(() => {
    if (amount > 0 && updateTotalNetto) {
      const priceNetto = totalNetto / amount;
      const vat = (priceNetto / 100) * vatMap[vatType] * amount
      const totalBrutto = +totalNetto + vat;
      setVat(toFixed(vat, 2));
      setTotalBrutto(toFixed(totalBrutto, 2));
      setPriceNetto(toFixed(+priceNetto, 2));
      setUpdateTotalNetto(false);
      onEdit({
        id: id,
        name,
        GTU,
        PKWIU,
        amount,
        unit,
        priceNetto,
        totalBrutto,
        totalNetto,
        vatType,
        vat
      })
    }
  }, [totalNetto]);

  useEffect(() => {
    if (amount > 0 && updateTotalBrutto) {
      const priceBrutto = totalBrutto / amount;
      const priceNetto = priceBrutto / ((1.0 + (vatMap[vatType] / 100.0)));
      const vat = (priceNetto / 100.0) * vatMap[vatType] * amount
      const totalNetto = priceNetto * amount;
      setVat(toFixed(vat, 2));
      setPriceNetto(toFixed(+priceNetto, 2))
      setTotalNetto(toFixed(+totalNetto, 2))
      setUpdateTotalBrutto(false)
      onEdit({
        id: id,
        name,
        GTU,
        PKWIU,
        amount,
        unit,
        priceNetto,
        totalBrutto,
        totalNetto,
        vatType,
        vat
      })

    }
  }, [totalBrutto]);

  useEffect(() => {
    onEdit({
      id: id,
      name,
      GTU,
      PKWIU,
      amount,
      unit,
      priceNetto,
      totalBrutto,
      totalNetto,
      vatType,
      vat
    })
  }, [name,
    GTU,
    PKWIU,])

  useEffect(() => {
    if (vatType === '23%' || vatType === 'zw.' || vatType === 'np.' || vatType === 'np.EU') {
      setPKWIU('');
    }
    const totalNetto = priceNetto * amount;
    const vat = (priceNetto / 100) * vatMap[vatType] * amount
    const totalBrutto = totalNetto + vat;
    setVat(toFixed(vat, 2));
    setTotalBrutto(toFixed(totalBrutto, 2));
    setTotalNetto(toFixed(totalNetto, 2));
    onEdit({
      id: id,
      name,
      GTU,
      PKWIU,
      amount,
      unit,
      priceNetto,
      totalBrutto,
      totalNetto,
      vatType,
      vat
    })

  }, [vatType])

  const handleDelete = () => {
    onDelete(id);
  }

  return (
    <TableRow key={id} >
      <TableCell className="pl-[0px] pr-[4px] pt-[14px] pb-[14px]">
        {/*
        <InputWithAlert
          placeholder={t("invoices:positions_name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-8 bg-white min-w-[150px]'}
        />
         */}
        <Textarea
          placeholder={t("invoices:positions_name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className=" bg-white min-w-[150px] h-auto text-xs p-[4px]"
          style={{ height: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        />

      </TableCell>
      <TableCell className="p-[4px]" >
        <SelectWithAlert
          value={GTU ? GTU : GTUData[0]}
          options={GTUData.map((option, index) => ({ value: option, label: option }))}
          onChangeValue={(e) => setGTU(e)}
          inputClassName={'h-8 w-[50px] bg-white'}
        />
      </TableCell>
      <TableCell className="p-[4px]">
        {(vatType === "8%" || vatType === "5%" || vatType === "0%") && (<>
          <InputWithAlert
            placeholder={'PKWIU'}
            value={PKWIU}
            onChange={(e) => setPKWIU(e.target.value)}
            errorMessage={t('format_data_invalid')}
            inputClassName={'h-8 text-xs p-[4px] bg-white'}
          />
        </>
        )}
      </TableCell>
      <TableCell className="p-[4px] min-w-10" >
        <InputWithAlert
          placeholder={t('invoices:positions_amount')}
          value={amount}
          onChange={handleAmountChange}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-8 text-xs p-[4px]  bg-white min-w-12  m-0'}
        />
      </TableCell>
      <TableCell className="p-[4px]" >
        <SelectWithAlert
          value={unit}
          options={UNIT_VARIANTS.map((option, index) => ({ value: option, label: option }))}
          onChangeValue={(e) => setUnit(e)}
          inputClassName={'h-8 text-xs p-[4px] min-w-12 bg-white'}
        />
      </TableCell>
      <TableCell className="p-[4px]" >
        <InputWithAlert
          placeholder={t('invoices:positions_price_netto')}
          value={priceNetto ? priceNetto : ''}
          onChange={handlePriceNettoChange}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-8 text-xs p-[4px] bg-white '}

        />
      </TableCell>
      <TableCell className="p-[4px]" >
        <InputWithAlert
          placeholder={t('invoices:price_netto')}
          value={totalNetto ? totalNetto : ''}
          onChange={handleTotalNettoChange}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-8 text-xs p-[4px] bg-white '}
        />
      </TableCell>
      <TableCell className="p-[4px]" >
        <SelectWithAlert
          value={vatType}
          options={vatData.map((option, index) => ({ value: option, label: option }))}
          onChangeValue={(e) => setVatType(e)}
          inputClassName={'h-8 text-xs p-[4px] bg-white '}
        />
      </TableCell>
      <TableCell className="p-[4px]" >
        <InputWithAlert
          placeholder={'VAT'}
          value={vat ? vat : ''}
          onChange={() => {}}
          inputClassName={'h-8 text-xs p-[4px] bg-white '}
          disabled
        />
      </TableCell>
      <TableCell className="p-[4px]" >
        <InputWithAlert
          placeholder={t('invoices:price_brutto')}
          value={totalBrutto ? totalBrutto : ''}
          onChange={handleTotalBruttoChange}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-8 text-xs p-[4px] bg-white '}
        />
      </TableCell>
      <TableCell className="p-[0px]" >
        <IconButton
          onClick={handleDelete}
        >
          <TrashIcon size={'20px'} />
        </IconButton>
      </TableCell>
    </TableRow>

  )
}
