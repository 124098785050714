import { Button } from "@/components/ui/button";
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { DataTablePagination } from '@/components/ui/table-pagination';
import { PAGINATION_SETTINGS } from "@/constants";
import { getReports, getUser } from '@/service/weexpertService';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from "@/components/ui/multiple-selector";
import { formatDateString } from "@/styles";
import { Loader } from "lucide-react";

const ReportsPage = () => {

  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION_SETTINGS.reports);
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });
  const navigate = useNavigate()
  const { data: reports, isLoading } = useQuery({
    queryKey: ['reports', debouncedSearchQuery, page, rowsPerPage],
    queryFn: () => getReports(rowsPerPage, page * rowsPerPage, debouncedSearchQuery, Object.values(page))
  });

  const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });

  const userRole = user.data.roles
  if (userRole === 'CLIENT') {
    navigate('/404')
  }
  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/report/view/${row.original.id}`}>
          {row.original.id}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "title",
      header: t("counterparties:name"),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/report/view/${row.original.id}`}>
          {row.original.title}
        </Link>
      ),
      key: uuidv4()
    },

    {
      accessorKey: "representativeCompany",
      header: t('clients:representative_company'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/report/view/${row.original.id}`}>
          {row.original.representativeCompany}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "createdAt",
      header: t('invoices:create_date'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/report/view/${row.original.id}`}>
          {formatDateString(row.original.createdAt)}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "dateFrom",
      header: t('reports:date_from'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/report/view/${row.original.id}`}>

          {formatDateString(row.original.fromDate)}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "dateTo",
      header: t('reports:date_to'),
      cell: ({ row }) => (
        <Link className="capitalize w-full block h-full" to={`/report/view/${row.original.id}`}>
          {formatDateString(row.original.toDate)}
        </Link>
      ),
      key: uuidv4()
    },
  ]

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)

  }, [pagination])

  const table = useReactTable({
    data: isLoading ? Array(rowsPerPage).fill({}) : reports.data.reports,
    columns: isLoading ? columns.map((column) => {
      return {
        ...column,
        cell: () => {
          return <Skeleton className='h-4 w-[200px]' />
        }
      }
    }) : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: reports?.data.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery]);

  return (
    <>
      {isUserLoading ? <Loader /> : (
        <div className="relative">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
              <div className="ml-8 text-4xl ">{t('reports:reports')}</div>
              <div className="flex justify-end w-5/6">
                {(userRole !== "ASSISTANT" && userRole !== "CLIENT") && (
                  <Link to='/report/create'>
                    <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                      {t('button_create')}
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-8 py-4 bg-grayLightMainBg">
            <div className="rounded-md border p-2">
              {(
                <Table>
                  <TableHeader>
                    {table.getHeaderGroups().map(headerGroup => (
                      <TableRow key={uuidv4()}>
                        {headerGroup.headers.map(header => {
                          return (
                            <TableHead className="pt-3 pb-3"
                              key={uuidv4()}>
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            </TableHead>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows?.length ? (
                      table.getRowModel().rows.map(row => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {row.getVisibleCells().map(cell => (
                            <TableCell className="relative text-sm pt-3 pb-3"
                              key={uuidv4()}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          className="h-24 text-center"
                        >
                          {t('no_results')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
            <DataTablePagination table={table} />
          </div>
        </div>
      )}

    </>
  );

}
export default ReportsPage;
