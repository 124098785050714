import React, { useState } from "react";
import { ProfileStepTwoPage } from "./profile.step_two.page";
import { ProfileStepOnePage } from "./profile.step_one.page";
import { ProfileArtifactsPage } from "./profile.step_four.page";
import { ProfileStepThreePage } from "./profile.step_three.page";

export const ProfileCreationPage = () => {
  const [stage, setStage] = useState(1);
  const getComponentDependsOnStage = (stage) => {
    switch (stage) {
      case 1:
        return <ProfileStepOnePage next={() => setStage(2)} />;
      case 2:
        return (
          <ProfileStepTwoPage
            back={() => setStage(1)}
            next={() => setStage(3)}
          />
        );
      case 3:
        return (
          <ProfileStepThreePage
            back={() => setStage(2)}
            next={() => setStage(4)}
          />
        );
      case 4:
        return <ProfileArtifactsPage back={() => setStage(3)} />;
      default:
        return <h2>Not implemented 5</h2>;
    }
  };
  return <>{getComponentDependsOnStage(stage)}</>;
};
