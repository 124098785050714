import { whiteButtonWithBorder } from "@/styles";
import { getUser, touchConcentStage } from "@/service/weexpertService";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Checkbox } from "@/components/ui/checkbox";
import { useQuery, useQueryClient } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useTranslation } from "react-i18next";
import AppBarForLogin from "@/components/app-bar-for-main-login-pages/AppBarForLogin";
import { useState } from "react";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { availability, AVAILABILITY_HOURS } from "@/constants";
import { toast } from "@/components/ui/use-toast";

export const ProfileArtifactsPage = ({back}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const { data: userData, isLoading: loading } = useQuery({
    queryKey: ["userMeProfile"],
    queryFn: getUser,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { profile } = data.data;
      if (!profile) {
        return
      }
      if (profile.availability) {
        setMaxHours(+profile.availability);
      }
      if (profile.consent) {
        setConcent(profile.consent);
      }
      if (profile.marketing) {
        setMarketing(profile.marketing);
      }
      if (profile.interestedInProjects) {
        setInterestedInProject(profile.interestedInProjects);
      }
    }
  });

  const [concent, setConcent] = useState(false);
  const [maxHours, setMaxHours] = useState();
  const [interestedInProject, setInterestedInProject] = useState(false);
  const [marketing, setMarketing] = useState(false);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className={"container"}>
        <AppBarForLogin isLogined={true} userData={userData.data} />
        <div className="w-full  pt-24 pb-28">
          <div className="w-2/5 text-3xl font-semibold  pb-6">
            Step 4/4: Join our talent pool list
          </div>
          <div className="w-2/5 ">
            <div className="w-9/12  text-base">
              We are getting job offers and additional freelancing projects on
              the daily basis, become a part of our talent pools and start
              getting the projects from our ecosystem hassle-free.
            </div>
            <div className="w-9/12 mr-8 flex justify-between pt-4">
              <div>I am interested to get job offers</div>
              <Switch
                onCheckedChange={(e) => {
                  setInterestedInProject(e);
                }}
                checked={interestedInProject}
              />
            </div>
          </div>
          <div className="mt-4" />
          <SelectWithAlert
            inputClassName={"h-12 w-4/12 mt-2 mb-4 bg-white"}
            label={"Availability (monthly in hours)"}
            placeholder={"Availability (monthly in hours)"}
            value={maxHours}
            onChangeValue={(e) => setMaxHours(e)}
            options={availability.map((e) => ({ label: e, value: e }))}
          />

          <div className="w-full flex  justify-start items-center pt-4">
            <Checkbox
              className="flex flex-row items-center justify-center py-0"
              checked={concent}
              onCheckedChange={() => setConcent(!concent)}
              aria-label="Select all"
            />
            <div className="pl-4">
              {t("survey:agreement_1")}{" "}
              <Link to="/client/zgoda" target="_blank" className="underline">
                {t("survey:agreement_2")}{" "}
              </Link>
            </div>
          </div>
          <div className="w-full flex  justify-start items-center pt-4">
            <Checkbox
              className="flex flex-row items-center justify-center py-0"
              checked={marketing}
              onCheckedChange={() => setMarketing(!marketing)}
            />
            <div className="pl-4">
              Wyrazam zgode na przetwarzanie moich danych osobowych w celach handlowych i
              marketingowych przez WeExpert sp. z o.o.
            </div>
          </div>

        </div>
        <Progress value={100} />
        <div className=" flex flex-row justify-between	 pt-6">
          <Button
            sx={{ ...whiteButtonWithBorder, width: "152px", margin: 0 }}
            onClick={() => {
              back();
            }}
          >
            Back
          </Button>
          <Button
            className="w-36"
            onClick={() => {
              if (!concent) {
                toast({
                  title: t("survey:error_agree"),
                  variant: "destructive",
                });
                return;
              }
              touchConcentStage(concent, interestedInProject, maxHours, marketing)
                .then(() => {
                  queryClient.invalidateQueries({queryKey: ['userMe']})
                  navigate('/')
              })
            }}
          >
            {t("survey:button_go_to_platform")}
          </Button>
        </div>
      </div>
    </>
  );
};
