import { BaseDialog } from "./dialog"
import { useState} from "react"
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Combobox } from "../ui/combobox";
import { INVOICE_TYPE_FOR_PROFORMA, INVOICE_TYPE_FOR_PREPAID_INVOICE } from "@/constants";
import { useNavigate } from 'react-router-dom';
import { inheritFromInvoice } from "@/service/weexpertService";
import { DatePicker } from "../ui/date-picker";
import { useMutation } from "react-query";
import { toast } from "../ui/use-toast";

export default function IssueInvoice({ invoiceType, id, invoiceId, className, icon }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState("");
    const [issueDate, setIssueDate] = useState(new Date());
    const mutation = useMutation({
      mutationFn: () => {
        return inheritFromInvoice(id, currentInvoice,issueDate)
                   .then((response) => {
                       const responseData = response.data;
                       setTimeout(() => {
                           navigate(`/admin/invoices/details/edit/${responseData.invoices.id}`)
                       }, 0)
                   })
                   .catch((error) => {
                       if (error.response && error.response.status === 400) {
                           const errorMessage = error.response.data.message;
                           if (errorMessage && errorMessage.relation) {
                               const relationElements = errorMessage.relation.split(' ');
                               const invoiceId = relationElements[relationElements.length - 5];
                               setTimeout(() => {
                                   navigate(`/admin/invoices/details/edit/${invoiceId}`);
                                   toast({
                                     title: "Фактура уже существует, открыта старинца редактирования"
                                   })
                               }, 0);
                           } else {
                           }
                       } else {
                       }
                   });
      }
    })
    const onClickSendButtonHandler = () => {
      mutation.mutate()
    };

    return <BaseDialog
        dialogTitle={`${t('invoices:exhibit_title') + ': '}   ${invoiceId}`}
        buttonText={t("invoices:button_exhibit")}
        open={open}
        setOpen={setOpen}
        className={className}
        icon={icon}
    >
        <div className="w-[500px] flex flex-col items-end ">
            <div className="w-full py-4 bg-grayLightMainBg">
                {invoiceType === 'PROFORMA' && (
                    <Combobox
                        className={'w-full mr-2'}
                        value={currentInvoice}
                        label={t("invoices:invoice_type")}
                        placeholder={t("invoices:invoice_type")}
                        options={INVOICE_TYPE_FOR_PROFORMA(t).map(e => {
                            return {
                                value: e.id,
                                label: e.value
                            }
                        })}
                        onChange={(newValue) => {
                            setCurrentInvoice(newValue.value)
                        }}
                        error={!currentInvoice}
                        errorMessage={t('empty_data_invalid')}
                 />)}
                {invoiceType === 'PREPAID_INVOICE' && (
                    <Combobox
                        className={'w-full mr-2'}
                        value={currentInvoice}
                        placeholder={t("invoices:invoice_type")}
                        options={INVOICE_TYPE_FOR_PREPAID_INVOICE(t).map(e => {
                            return {
                                value: e.id,
                                label: e.value
                            }
                        })}
                        onChange={(newValue) => {
                            if(!newValue) return
                            setCurrentInvoice(newValue.value)
                        }}
                        error={!currentInvoice}
                        errorMessage={t('empty_data_invalid')}
                    />
                )}
                <DatePicker
                    label={t("invoices:date_of_creation")}
                    date={issueDate}
                    setDate={(date) => {
                        if (!date) return
                        setIssueDate(date)
                    }
                    }
                    inputClassName={'h-12 text-base mb-4'}
                />
            </div>
            <Button disabled={mutation.isLoading || !currentInvoice} onClick={() => { onClickSendButtonHandler(); }} >
                {t("invoices:button_exhibit")}
            </Button>
        </div>
    </BaseDialog>
}
