import React from "react";
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-full px-8 py-10 flex justify-center align-center">
      <div className="w-full pt-40">
        <div className="text-6xl text-center">
          404 - {t("page_not_found")}
        </div>
        <div className="text-2xl text-center pt-6">
          {t("page_not_found_description")}
        </div>
        <div className="w-full flex justify-center align-center pt-8">
          <Button onClick={() => navigate('/')}>
            {t("button_to_main")}
          </Button>
        </div>
      </div>
    </div>

  )

}
