import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { validateEmail } from "@/constants";
import Cookies from 'js-cookie';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from 'react-router-dom';
import logo_login from '../../assets/img/logo_ligin.png';
import { login } from "../../service/weexpertService";
import AppBarForLogin from "../app-bar-for-main-login-pages/AppBarForLogin";
import { InputWithAlert } from "../ui/input-with-alert";
import { toast } from "../ui/use-toast";

export const Login = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [alertMessage, setAllertMessage] = useState();
  const [mail, setMail] = useState('');
  const [mailError, setMailError] = useState('');
  const [pass, setPass] = useState('');

    const setPassHandler = (newValue) => {
    setPass(newValue);
  };

  const setAndVaidateEmail = (newValue) => {
    const isValid = validateEmail(newValue);
    setMail(newValue)
    if (!isValid && newValue.length !== 0) {
      setMailError(t("alerts:error_format_email"))
    } else
      setMailError('')
  };

  const cleanValues = (mail, password) => {
    mail('');
    password('');
  };

  const onClickSendButtonHandler = () => {
    login(mail, pass)
      .then((response) => {
        const responseData = response.data;
        if (responseData.access_token) {
          Cookies.set('token', `${responseData.access_token}`, { expires: 1 });
          Cookies.set('refresh_token', `${responseData.refresh_token}`, { expires: 1 });
          queryClient.clear();
          navigate(0);
          cleanValues(setMail, setPass);
        }
      })
      .catch((error) => {
        if (error.code == "ERR_NETWORK") {
          toast({
            title: t("alerts:error"),
            description: t("alerts:error_send_fail"),
            variant: 'destructive'
          })
          return;
        }
        if (error.response && error.response.status === 400) {
          toast({
            title: t("alerts:error"),
            description: t("alerts:toast_error_password"),
            variant: 'destructive'
          })
        }
        if (error.response && error.response.status === 429) {
          toast({
            title: t("alerts:error"),
            description: "Too much request try later",
            variant: 'destructive'
          })
        }
      });
  };

  return (
    <>
      <AppBarForLogin />
      <form onSubmit={(e) => { e.preventDefault(); }} className="flex flex-row justify-between">
        <div className="h-screen flex justify-center w-3/6 bg-[url('../src/assets/img/login_bg.png')] bg-no-repeat bg-cover bg-center	items-center text-white">
          <div className=" w-4/6">
            <div className="text-6xl py-10	">
              {t("login:tagline_capture")}
            </div>
            <div className="text-lg opacity-70">
              {t("login:tagline_description")}
            </div>
          </div>
        </div>
        <div className="h-screen w-3/6 flex flex-col justify-center items-center">
          <div><img src={logo_login} alt="" /></div>
          <div className="text-4xl font-medium pb-2">{t("login:logo_description")}</div>
          <div className="pb-8 opacity-70">{t("login:form_description")}</div>
          <div className="w-3/6 pb-4">
            <InputWithAlert
              label="E-mail"
              name="email"
              onChange={(e) => setAndVaidateEmail(e.target.value)}
              value={mail}
              error={mailError}
              errorMessage={mailError}
            />
          </div>
          <div className="w-3/6 pb-4">
            <InputWithAlert
              label={t("login:login_label")}
              name="password"
              onChange={(e) => setPassHandler(e.target.value)}
              value={pass}
              error={alertMessage}
              errorMessage={alertMessage}
              type='password'
            />
            <Link className="text-sky-500 pt-2 block" to={'/restore'}>{t("login:button_fogot_password")}</Link>
          </div>
          <div className="w-3/6 flex pb-5 flex-row justify-start items-center space-x-2">
            <Checkbox id="terms" />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t("login:checkbox_remember")}
            </label>
          </div>
          <div className="pt-5 w-3/6 flex flex-row justify-start items-center ">
            <Button type="submit" className='w-full' onClick={() => {
              onClickSendButtonHandler();
            }}>{t("login:button_login")}</Button>
          </div>
        </div>
      </form>
    </>
  );
}
