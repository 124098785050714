import React from 'react'

import { Box } from '@mui/material';
import { Combobox } from "@/components/ui/combobox";
import { ACTIVE_STATUS, COMPANY_TYPE, COMPANY_TYPE_COMPANY, COUNTRIES_LIST, COUNTRY_TYPE, GET_COUNTRY_NAME_BY_CODE, transformFromIdValueToValueLabel, validateEmail, validateNoneCyrillic } from "../../constants";
import { useTranslation } from "react-i18next";
import { InputWithAlert } from '../ui/input-with-alert';

export const CONTRACTORS_FORM_ACTION_EDIT = 'EDIT';
export const CONTRACTORS_FORM_ACTION_VIEW = 'VIEW';
export const CONTRACTORS_FORM_ACTION_CREATE = 'CREATE';

export const ContractorsForm = (props) => {
  const { t } = useTranslation()
  const {
    action,
    inputChecked,
    companyType,
    setCompanyType,
    NIP,
    setNIP,
    companyName,
    setCompanyName,
    companyOrigin,
    setCompanyOrigin,
    companyCountry,
    setCompanyCountry,
    mail,
    setMail,
    city,
    setCity,
    address,
    setAddress,
    postIndex,
    setPostIndex,
    currentStatus,
    setCurrentStatus,
  } = props;

  const editable = (
    <div className='w-full'>
      <div className="text-xl font-medium ">{t('main_information_title')}</div>
      <div className='w-full flex justify-between pt-6'>
        <div className='w-[49%]'>
          <Combobox
            className={'w-full mr-2'}
            label={t('type') + "*"}
            value={companyType}
            placeholder={t('type') + "*"}
            error={inputChecked && (!companyType)}
            errorMessage={t('empty_data_invalid')}
            options={COMPANY_TYPE(t).map(e => {
              return {
                value: e.id,
                label: e.value
              }
            })}
            onChange={(newValue) => {
              setCompanyType(newValue)
            }}
          />
          {companyType?.value === COMPANY_TYPE_COMPANY && (
            <InputWithAlert
              label="NIP"
              value={NIP}
              onChange={(e) => setNIP(e.target.value)}
              error={inputChecked && (NIP && !validateNoneCyrillic(NIP))}
              errorMessage={t('format_data_invalid')}
              placeholder={"NIP"}
              inputClassName={'h-12 mt-1 mb-5 bg-white'}
            />
          )
          }
          {action == CONTRACTORS_FORM_ACTION_EDIT &&
            <Combobox
              className={'w-full mt-2 mb-4'}
              label={t('status')}
              value={currentStatus}
              placeholder={t('status')}
              error={inputChecked && !currentStatus}
              errorMessage={t('empty_data_invalid')}
              options={ACTIVE_STATUS(t).map(transformFromIdValueToValueLabel)}
              onChange={(newValue) => {
                setCurrentStatus(newValue)
              }}
            />
          }
        </div>
        <div className='w-[49%]'>
          <Combobox
            className={'w-full mr-2'}
            label={t('counterparties:origin') + '*'}
            value={companyOrigin}
            placeholder={t('counterparties:origin') + '*'}
            error={inputChecked && !companyOrigin}
            errorMessage={t('empty_data_invalid')}
            options={COUNTRY_TYPE(t).map(transformFromIdValueToValueLabel)}
            onChange={(newValue) => {
              setCompanyOrigin(newValue)
            }}
          />
          <InputWithAlert
            label={t('counterparties:name') + "*"}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            error={inputChecked && (!companyName || !validateNoneCyrillic(companyName))}
            errorMessage={!companyName ? t('empty_data_invalid') : t('format_data_invalid')}
            placeholder={t('counterparties:name') + "*"}
            inputClassName={'h-12 mt-1 bg-white'}
          />

        </div>
      </div>
      <div className="text-xl font-medium pt-8 ">{t("address")}</div>
      <div className='w-full flex justify-between pt-6'>
        <div className='w-[49%]'>
          <InputWithAlert
            label={t('address') + "*"}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            error={inputChecked && (!address || !validateNoneCyrillic(address))}
            errorMessage={!address ? t('empty_data_invalid') : t('format_data_invalid')}
            placeholder={t('address')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
          <InputWithAlert
            label={t('city') + "*"}
            value={city}
            error={inputChecked && (!city || !validateNoneCyrillic(city))}
            errorMessage={!city ? t('empty_data_invalid') : t('format_data_invalid')}
            onChange={(e) => setCity(e.target.value)}
            placeholder={t('city')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />

          <InputWithAlert
            label={"Email"}
            value={mail}
            onChange={(event) => setMail(event.target.value)}
            placeholder={"Email"}
            error={inputChecked && (mail && !validateEmail(mail))}
            errorMessage={t('format_data_invalid')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />

        </div>
        <div className='w-[49%]'>
          <InputWithAlert
            label={t('post_index')}
            value={postIndex}
            onChange={(event) => setPostIndex(event.target.value)}
            error={inputChecked && (postIndex && !validateNoneCyrillic(postIndex))}
            errorMessage={!postIndex ? t('empty_data_invalid') : t('format_data_invalid')}
            placeholder={t('post_index')}
            inputClassName={'h-12 mt-2 bg-white'}
          />
          <Combobox
            className={'w-full mt-[26px] mb-4'}
            label={t('country') + '*'}
            value={companyCountry}
            placeholder={t('country')}
            error={inputChecked && (!companyCountry)}
            errorMessage={t('empty_data_invalid')}
            options={COUNTRIES_LIST.map(transformFromIdValueToValueLabel).map((item) => {
              return {
                ...item,
                content: item,
                value: item.label,
              }
            })}
            onChange={(newValue) => {
              setCompanyCountry(newValue)
            }}
          />
        </div>
      </div>
    </div>)

  const readable = (
    <div className='w-full'>
      <div className="text-xl font-medium ">{t("main_information_title")}</div>
      <div className='w-full flex justify-between'>
        <div className='w-[49%]'>
          <Box sx={{ paddingTop: '20px' }}>
            <InputWithAlert
              label={t('type')}
              value={companyType === 'INDIVIDUAL' ? t('company_type_individual') : t('company_type_company')}
              readOnly={true}
              disabled
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          </Box>
        </div>
        <div className='w-[49%]'>
          <Box sx={{ paddingTop: '20px' }}>
            <InputWithAlert
              label={t('counterparties:origin')}
              value={companyOrigin === 'OTHER' ? t('counterparties:rest_of_the_world') :
                (companyOrigin === 'EU' ? t("counterparties:eu") : t("counterparties:poland"))}
              readOnly={true}
              disabled
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          </Box>
        </div>
      </div>
      <div className='w-full flex justify-between'>
        <div className='w-[49%]'>
          <InputWithAlert
            label='NIP'
            value={NIP ? NIP : ''}
            readOnly={true}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
        <div className='w-[49%]'>
          <InputWithAlert
            label={t('counterparties:name')}
            value={companyName}
            readOnly={true}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      </div>
      <Box>
        <div className='w-[49%]'>
          <InputWithAlert
            label={t('status')}
            value={currentStatus ? t('constants:blocked') : t('constants:unblocked')}
            readOnly={true}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      </Box>
      <div className="text-xl pt-6 font-medium ">{t('address')}</div>
      <div className='w-full flex justify-between'>
        <div className='w-[49%]'>
          <Box sx={{ paddingTop: '20px' }}>
            <InputWithAlert
              label={t('address')}
              value={address}
              readOnly={true}
              disabled
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          </Box>
        </div>
        <div className='w-[49%]'>
          <Box sx={{ paddingTop: '20px' }}>
            <InputWithAlert
              label={t('post_index')}
              readOnly={true}
              value={postIndex}
              disabled
              inputClassName={'h-12 mt-2 mb-4 bg-white'}
            />
          </Box>
        </div>
      </div>
      <div className='w-full flex justify-between'>
        <div className='w-[49%]'>
          <InputWithAlert
            label={t('city')}
            readOnly={true}
            value={city}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
        <div className='w-[49%]'>
          <InputWithAlert
            label={t('country')}
            readOnly={true}
            value={GET_COUNTRY_NAME_BY_CODE(companyCountry)}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      </div>
      <div className='w-full flex justify-between'>
        <div className='w-[49%]'>
          <InputWithAlert
            label='Email'
            readOnly={true}
            value={mail ? mail : ''}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      </div>
    </div>)
  return action == CONTRACTORS_FORM_ACTION_VIEW ? readable : editable;

}
export default ContractorsForm
