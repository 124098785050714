import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssignClientTablePopup from "../components/popups/user.assign.clients.popup";
import RelatedClientsTable from "@/components/clients/RelatedClientsTable";
import UsersForm, { USERS_FORM_ACTION_VIEW } from "../components/users/UsersForm";
import { connectUserToClinet, getUserById, unassignClient } from '../service/weexpertService';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { UserDeletePopup } from '@/components/popups/user.delete.popup';
import CreateSharedPoolPopup from '@/components/popups/create-shared-pool.popup';

export const UserViewPage = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const [selectedClients, setSelectedClients] = useState([])
  const redirectToUserDetailsEdit = (id) => {
    navigate(`/admin/user/details/edit/${id}`);
  }
  const handleRefreshData = () => {
    setRefreshData(prevRefreshData => !prevRefreshData); //update data list
  };

  useEffect(() => {
    getUserById(id)
      .then(res => {
        if (res.data.user === null) {
          navigate('/404')
        }
        setUser(res.data.user);

      }).then(() => {
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          navigate('/404')
        } else {
          console.error("Error:", error);
        }
      });
  }, [id, refreshData]);

  return (<>
    {loading ? (
      <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
          <CircularProgress color="grey" />
        </Stack>
      </Box>
    ) : (
      <div className="relative bg-grayLightMainBg px-8">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 items-center ">
            <div className=" w-5/12  text-4xl ">
              {t("users:details_title")}
            </div>
            <div className='w-6/12 flex justify-end'>
              <UserDeletePopup
                user={user}
              />
              {selectedClients.length > 0 && (
                <CreateSharedPoolPopup
                  clientIds={selectedClients}
                />
              )}
              <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                onClick={() => {
                  redirectToUserDetailsEdit(id);
                }}>
                {t("button_change")}
              </Button>
            </div>
          </div>
        </div>
        <div className='w-full py-8'>
          <div className=" text-base ">{t("users:user")}</div>
          <div className=" text-2xl ">{`${user.firstName} ${user.lastName}`}</div>
        </div>
        <div className='w-full'>
          <UsersForm
            action={USERS_FORM_ACTION_VIEW}
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            phone={user.phone}
            role={user.roles}
          />
        </div>
        <div className='w-full flex justify-between items-center pb-8'>
          <div className="w-full text-xl font-medium ">
            {t("clients")}
          </div>
          <AssignClientTablePopup
            header={t("users:add_client_from_user")}
            handleRefreshData={handleRefreshData}
            person={user}
            onSelect={(clientId) => {
              connectUserToClinet(clientId, user.id)
                .then(() => {
                  handleRefreshData()
                })
            }}
            onUnselect={(clientId) => {
              unassignClient(clientId)
                .then(() => {
                  handleRefreshData()
                })
            }}
            initialSelection={user.clients.map(e => e.id)}
          />
        </div>
        <div className='w-full pb-16'>
          <RelatedClientsTable entity={user} handleRefreshData={handleRefreshData}
            showSelect={true}
            onSelectChanged={(e) => setSelectedClients(e)} showAction={true} />
        </div>
      </div>)}
  </>
  )
}
export default UserViewPage
