import { BaseDialog } from "./dialog"
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { changeClient } from "@/service/weexpertService";
import { Button } from "../ui/button";

export default function ClientBlockPopup(props) {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const { client } = props;
    const bankAccounts = client.bankAccounts
    const incubatorAccounts = bankAccounts.filter(account => account.accountType === 'INCUBATOR');
    const clientAccounts = bankAccounts.filter(account => account.accountType === 'CLIENT');

    const blockCurrentClient = (status) => {
        changeClient(
            client.firstName,
            client.lastName,
            client.email,
            client.phone,
            client.pesel,
            incubatorAccounts,
            clientAccounts,
            client.state,
            client.city,
            client.postIndex,
            client.passport,
            client.street,
            client.houseNumber,
            status,
            client.flat,
            client.dateOfBirth,
            client.citizenship,
            client.id,
            client.representativeCompany
        )
            .then((response) => {
                props.handleRefreshData();
                setOpen(false)
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    console.log(error);
                } else {
                    console.error(error);
                }
            });
    };
    return <BaseDialog
        dialogTitle={client.isActive ? `${t('clients:question_blocked_user')}` : `${t('clients:question_unblocked_user')}`}
        buttonText={client.isActive ? t('clients:button_block') : t('clients:button_unblock')}
        open={open}
        setOpen={setOpen}
    >
        <div className="w-[400px]">
            <div className="pb-8">
                {`${client.firstName} ${client.lastName}`}
            </div>
            <div className="w-full flex justify-end">
                {client.isActive === true && (
                    <Button onClick={() => {
                        blockCurrentClient(false);
                    }} >
                        {t('clients:button_block')}
                    </Button>
                )}
                {client.isActive === false && (
                    <Button onClick={() => {
                        blockCurrentClient(true);
                    }}>
                        {t('clients:button_unblock')}
                    </Button>
                )}
            </div>
        </div>
    </BaseDialog>
}
