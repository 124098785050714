import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AlertBanner({ 
  title, 
  text
}) {
    const {t} = useTranslation();

  return (
    <div className="w-full z-30 bg-indigo-600 text-white flex items-center justify-between pt-6 pb-2 pl-8 pr-10">
      <div className="flex items-center space-x-2">
        <svg
          className="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
            clipRule="evenodd"
          />
        </svg>
        <span className="font-semibold">{title}</span>
      </div>
      <div className="flex-1 text-center pr-1">
        {text}
      </div>
      <div className="flex items-center space-x-2">
        <Link to='https://t.me/weexpertIT' target="_blank">
        <Button variant="secondary" className="bg-white text-indigo-600 hover:bg-gray-100 min-w-32">
          {t("banner_button")}
        </Button>
       </Link>
      </div>
    </div>
  )
}