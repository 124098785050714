import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { bankUpload } from "@/service/weexpertService";
import { FileIcon } from "@radix-ui/react-icons";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export const FileSelector = (props) => {
  const { t } = useTranslation();
  const { setFile, file, id } = props;
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (file) {
      toast({
        title: t("alerts:success_wait_for_download"),
        description: t("alerts:success_wait_for_download"),
      });
      bankUpload(id, file)
        .then((response) => {
          if (response.status === 200) {
            toast({
              title: t("alerts:succes"),
              description: t("alerts:succes_upload"),
            });
            setFile(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }
        })
        .catch((err) => {
          toast({
            title: t("alerts:toast_error_counterparty_title"),
            description: "alerts:toast_error_counterparty_description",
            variant: "destructive",
          });
        });
    }
  };

  return (
    <div className="w-full flex justify-between pt-4">
      <div>
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          className="block text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
                   file:rounded-sm file:border-0 file:text-sm file:font-semibold
                   file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
      </div>
      <div>
        <Button
          className={file ? `text-sm` : `bg-gray-500 hover:bg-gray-500`}
          onClick={handleFileUpload}
        >
          <div className="text-sm">Upload mbank file</div>
          <div className="">
            <FileIcon className="w-3 h-3 ml-4" />
          </div>
        </Button>
      </div>
    </div>
  );
};
