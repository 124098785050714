import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "../ui/button";
import { approveAnnex, editAnnex, editContract, getAnnexPDF, getUser } from "@/service/weexpertService";
import { toast } from "../ui/use-toast";
import { useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Loader } from "@/components/ProtectedRoute"
import AnnexCreatePopup from "../popups/annex.create.popup";

const resolveName = (field) => {
  switch (field) {
    case 'grossAmount': return 'Kwota brutto'
    case 'endDate': return 'Data konca'
  }
}
const resolveOldValue = (field, contract) => {
  switch (field) {
    case 'grossAmount': return (contract.grossAmount).toFixed(2)
    case 'endDate': return format(new Date(contract.endDate), 'dd.MM.yyyy')
  }
}

const resolveNewValue = (field, value) => {
  switch (field) {
    case 'grossAmount': return parseFloat(value).toFixed(2)
    case 'endDate': return format(new Date(value), 'dd.MM.yyyy');
  }
}

const getView = (change, contract) => {
  const { field, value } = change;
  return <div className="w-3/6 flex justify-end pl-4 pr-4">
    {resolveName(field)}: <span className="line-through ml-1 mr-1">  {resolveOldValue(field, contract)} </span>  {resolveNewValue(field, value)}
  </div>
}
export const AnnexView = (props) => {
  const { data: user, isLoading, isFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const { t } = useTranslation();
  const { contract } = props;
  const queryClient = useQueryClient();

  const annexesArrray = contract.annexes.map((annex, index) => {
    const changes = JSON.parse(annex.changesAsJson);
    const color = annex.state == 'Accepted' ? 'bg-green-200' : 'bg-yellow-200'

    const userRole = user.data.roles

    return (
      <div key={index + annex.state} className="pb-[94px]">
        <Accordion className="bg-white h-[2px] mt-0 rounded-md" type="single" collapsible>
          <AccordionItem className="bg-white border border-gray-200 rounded-md" value={`item`}>
            <AccordionTrigger className={`px-2 h-12 no-underline hover:no-underline border-b-0 ${color} rounded`}>
              <div className="w-11/12 flex justify-between items-center">
                <div>{annex.title}</div>
                <div>{annex.state === "Pending" ? t("invoices:status_in_progress") : t("invoices:status_complete")}</div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="w-full bg-white px-2 relative z-2 rounded-md">
              <div className="flex justify-end pt-4">
                <Button className="mr-1" variant="outline"
                  onClick={() => {
                    toast({
                      title: t("generation_pdf_started")
                    })
                    getAnnexPDF(annex.id, contract.contractId)
                      .then(() => {
                        toast({
                          title: t("generation_pdf_success")
                        })
                      })
                      .catch((res) => {
                        Sentry.captureException(res);
                        toast({
                          title: t("generation_pdf_error"),
                          variant: "destructive"
                        })
                      })
                  }}
                >PDF
                </Button>
                {userRole !== 'CLIENT' &&
                  <AnnexCreatePopup
                    text={t("button_change")}
                    annex={annex}
                    initialContract={contract}
                    callback={(contractId, state, changes, acceptedDate, signatory, representativeCompany) => {
                      return editAnnex(contractId, annex.id, state, changes, acceptedDate, signatory, representativeCompany)
                    }}
                  />}
              </div>
              {changes.map((change, changeIndex) => {
                const paddingTopClass = changeIndex === 0 ? 'pt-4' : 'pt-0';
                const paddingBottomClass = changeIndex === changes.length - 1 ? '' : 'pb-2';
                return (
                  <div
                    className={`${paddingTopClass} ${paddingBottomClass} my-auto flex justify-end`}
                    key={changeIndex}
                  >
                    {getView(change, contract)}
                  </div>
                );
              })}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    )
  });
  if (isLoading || !isFetched) {
    return <Loader />
  }
  return (
    <>
      {annexesArrray}
    </>
  );
}
