import { Box } from '@mui/material';
import { Button } from "@/components/ui/button";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import ContractorsForm, { CONTRACTORS_FORM_ACTION_VIEW } from '../components/contractors/ContractorsForm';
import { getContractorById, getUser } from "../service/weexpertService";
import { assignClientToContractors, unassignClientFromContractors } from '../service/weexpertService';
import AssignClientTablePopup from '@/components/popups/user.assign.clients.popup';
import RelatedClientsTable from "@/components/clients/RelatedClientsTable";
import { ROLES } from '@/constants';
import { useQuery } from 'react-query';

const CounterpartyViewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [contractor, setContractor] = useState(null);
  const [refreshData, setRefreshData] = useState(false);

  const { data: user, isLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const handleRefreshData = () => {
    setRefreshData(prevRefreshData => !prevRefreshData);
  };

  const handleNavigateToEditContractor = (id) => {
    navigate(`/admin/contractors/details/edit/${id}`);
  };

  useEffect(() => {
    if (id) {
      getContractorById(id)
        .then(res => {
          if (res.data === null) {
            navigate('/404')
          }
          setContractor(res.data);
        }).then(() => {
          setLoading(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            navigate('/404')
          } else {
            console.error("Error:", error);
          }
        });
    }

  }, [id, refreshData]);

  return (
    <Box>
      {loading || isLoading ? (
        <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center ">
              <div className="text-4xl ">
                {t('counterparties:details_capture')}
              </div>
              <div>
                {user.data.roles !== ROLES.CLIENT &&
                  (<Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => { handleNavigateToEditContractor(id); }}>
                    {t('button_change')}
                  </Button>)
                }
              </div>
            </div>
          </div>
          <div className='w-full py-8'>
            <ContractorsForm
              action={CONTRACTORS_FORM_ACTION_VIEW}
              NIP={contractor.NIP}
              companyType={contractor.counterpartyType}
              companyName={contractor.name}
              companyOrigin={contractor.origin}
              companyCountry={contractor.country}
              postIndex={contractor.postIndex}
              mail={contractor.email}
              city={contractor.city}
              address={contractor.address}
              currentStatus={contractor.active}
            />
          </div>

          {contractor.clients && (<>
            <div className='w-full flex justify-between items-center pb-8'>
              <div className="w-full text-xl font-medium ">
                {t("clients")}
              </div>
              <AssignClientTablePopup
                header={t('assign_client_to_counterparty')}
                person={contractor}
                handleRefreshData={handleRefreshData}
                onSelect={(clientId) => {
                  console.log(clientId)
                  assignClientToContractors(contractor.id, clientId).then((res) => {
                    handleRefreshData()
                  })
                }}
                onUnselect={(clientId) => {
                  unassignClientFromContractors(contractor.id, clientId).then(() => {
                    handleRefreshData()
                  })
                }}
                initialSelection={contractor.clients.map(e => e.id)}
              />
            </div>
            <div className='w-full pb-16'>
              <RelatedClientsTable entity={contractor} counterparty={true} handleRefreshData={handleRefreshData} />
            </div>
          </>
          )}
        </div>
      )}
    </Box>
  )
}
export default CounterpartyViewPage;
