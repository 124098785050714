import { getClients, getReportVat } from "@/service/weexpertService";
import dayjs from "dayjs";
import { DownloadIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Combobox } from "../ui/combobox";
import { BaseDialog } from "./dialog";
import { Button } from "../ui/button";
import { SelectWithAlert } from "../ui/select-with-alert";

export const InvoiceClientReportPopup = () => {

  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const currentDate = dayjs();
  const initialMonth = currentDate.month() + 1;
  const initialYear = currentDate.year();
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    getClients(1000, 0)
      .then((result) => {
        setClients(result.data.clients
          .map(client => {
            return {
              label: `${client.firstName} ${client.lastName}`,
              value: client.id
            }
          })
        );
      });
  }, [])

  return <BaseDialog
    dialogTitle={t("invoices:pdf_modal_capture")}
    buttonText={t("invoices:vat_report_capture")}
    open={open}
    setOpen={setOpen}
  >
    <div className="w-[500px] pt-6">
        <Combobox
          className={'w-full'}
          label={t('clients')}
          value={selectedClients}
          placeholder={t('selected')}
          options={clients}
          onChange={(newSelected) => {
            setSelectedClients(newSelected)
          }}
        />
      <SelectWithAlert value={month} onChangeValue={(e) => setMonth(e)}
        label={t("invoices:vat_placeholder_month")}
        options={[...Array(12).keys()].map(i => {
          return {
            value: i + 1,
            label: i + 1
          };
        })}
      />
      <SelectWithAlert value={year} onChangeValue={(e) => setYear(e)}
        label={t("invoices:vat_placeholder_year")}
        options={[...Array(12).keys()].map(i => {
          return {
            value: i + 2023,
            label: i + 2023
          };
        })}
      />
      <div className="w-full flex flex-col items-end">
      <Button className='mt-4' onClick={() => {
        getReportVat(selectedClients.value, month, year)
      }} ><DownloadIcon className="h-[16px] mr-2" />{t("common:download")}</Button>
      </div>
    </div>
  </BaseDialog>
}
