import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog";
import { Button } from "../ui/button";
import { useNavigate } from 'react-router-dom';
import { deleteUser } from "@/service/weexpertService";

export const UserDeletePopup = (props) => {

    const { t } = useTranslation();
    const { user } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleRedirectToAdminPage = () => {
        navigate('/admin/administration');
    };

    const deleteCurrentUser = () => {
        if (user && user.id) {
            const id = user.id
            deleteUser(id)
                .then((response) => {
                    console.log(response)
                    //setAllertMessage(alertSuccess)
                    setTimeout(() => {
                        handleRedirectToAdminPage();
                        setOpen(false);
                    }, 1200);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        // setAllertMessage(alertErrorSend)
                        console.log('Ошибка: Неверные данные, код 400');
                    } else {
                        console.error(error);
                    }
                });
        }
    };

    return <BaseDialog
        dialogTitle={t("users:delete_question")}
        buttonText={t("button_delete")}
        open={open}
        setOpen={setOpen}
    >
        <div className="w-[500px] pt-2">
            <div className=" text-base ">
                {`${user.firstName} ${user.lastName}`}
            </div>
            <div className="w-full flex justify-end pt-4">
                <Button className='mr-2' onClick={() => setOpen(false)}>
                    {t("button_back")}
                </Button>
                <Button onClick={() => {
                    deleteCurrentUser();
                }}>
                    {t("button_delete")}
                </Button>
            </div>
        </div>
    </BaseDialog>
}
