import DownloadIcon from '@mui/icons-material/Download';
import { Box, Typography } from '@mui/material';
import { Button } from '@/components/ui/button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { COUNTRY_TYPE_MAP, GET_COUNTRY_NAME_BY_CODE, INVOICE_TYPE, INVOICE_TYPES, PAYMENT_METHOD_MAP, PAYMENT_STATUS_MAP, ROLES, TRANSACTION_TYPE_MAP } from "../constants";
import { cloneInvoice, getInvoiceById, getOneClient, getUser } from "../service/weexpertService";
import { formatDateString } from "../styles";
import ScrollToTop from '../components/ScrollToTop';
import { useTranslation } from "react-i18next";
import { InputWithAlert } from '@/components/ui/input-with-alert';
import { Textarea } from "@/components/ui/textarea";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { v4 as uuidv4 } from 'uuid';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import LanguageSelectorForPdfDowloadNew from '@/components/popups/invoice.pdf.download.popup';
import InvoiceBindPopup from '@/components/popups/invoice.bind.popup';
import IssueInvoice from '@/components/popups/Issue.invoice.popup';
import InvoiceClone from '@/components/popups/invoice.clone.popup';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

export function PositionsViewTable(props) {

  const { data } = props;
  const { t } = useTranslation();

  const columns = [
    {
      accessorKey: "name",
      header: t("invoices:positions_name"),
      cell: ({ row }) => (
        <div>
          {row.original.name}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "GTU",
      header: 'GTU',
      cell: ({ row }) => (
        <div>
          {row.original.GTU}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "pkwiu",
      header: 'PKWIU',
      cell: ({ row }) => (
        <div>
          {row.original.pkwiu}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "amount",
      header: t("invoices:positions_amount"),
      cell: ({ row }) => (
        <div>
          {row.original.amount}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "unit",
      header: t("invoices:positions_unit"),
      cell: ({ row }) => (
        <div className=' w-[56px] bg-pirpleLightBg rounded text-blueText text-center p-0' >
          {row.original.unit}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "priceNetto",
      header: t("invoices:positions_price_netto"),
      cell: ({ row }) => (
        <div>
          {parseFloat(row.original.priceNetto).toFixed(2)}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "priceNetto",
      header: t("invoices:positions_summary_netto"),
      cell: ({ row }) => (
        <div>
          {(row.original.priceNetto * row.original.amount).toFixed(2)}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "typeOfVat",
      header: t("invoices:positions_type_of_vat"),
      cell: ({ row }) => (
        <div>
          {row.original.typeOfVat}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "vat",
      header: t("invoices:positions_vat"),
      cell: ({ row }) => (
        <div>
          {(row.original.amount * row.original.vat).toFixed(2)}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "vat",
      header: t("invoices:positions_price_brutto"),
      cell: ({ row }) => (
        <div>
          {((row.original.priceNetto * row.original.amount) + (row.original.amount * row.original.vat)).toFixed(2)}
        </div>
      ),
      key: uuidv4()
    },

  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <div className="rounded-md border p-2">
      {data && (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={uuidv4()}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead className="pt-3 pb-3 bg-white"
                      key={uuidv4()}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow className=" bg-white"
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell className="relative text-sm pt-3 pb-3"
                      key={uuidv4()}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t('no_results')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

const InvoiceViewPage = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState('');
  const [refresh, setRefresh] = useState(false);
  const invoicesGetHandler = (value) => {
    setInvoice(value);
  };
  const navigate = useNavigate();
  const handleNavigateOnClick = (link) => {
    navigate(link)
  }

  useEffect(() => {
    if (id) {
      getInvoiceById(id)
        .then(res => {
          invoicesGetHandler(res.data);
          return res.data;
        })
        .then(res => {
          if (res.clientId) {
            return getOneClient(res.clientId)
              .then(res => {
              })
              .catch(error => {
                if (error.response) {
                  console.error("Ошибка при запросе к клиенту:", error.response.status);
                  console.log("Детали ошибки:", error.response.data);
                } else if (error.request) {
                  console.error("Ошибка запроса:", error.request);
                } else {
                  console.error("Неизвестная ошибка:", error.message);
                }
              });
          }
        })
        .then(() => {
          setLoading(false);
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {

            if (error.response.data && error.response.data.message === "Not found") {
              navigate('/404')
            }
          } else {
            console.error("Error:", error);
          }
          if (error.response && error.response.status === 400) {
            navigate('/404')
          } else {
            console.error("Error:", error);
          }
        });
    }
  }, [id, refresh])

  const { data: user, isLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  return (
    <Box>
      {loading || isLoading ? (
        <Box sx={{ width: "100%", height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="w-full bg-grayLightMainBg">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
              <div className='w-5/12 flex items-center '>
                <div className="ml-8 text-4xl ">
                  {t("invoices:details_title")}
                </div>
                <div className='pt-2 pl-2 text-xl font-bold'>
                  {invoice.invoiceId}
                </div>
              </div>
              <div className='w-7/12 flex justify-end'>
                <div className='pb-[10px]'>
                  {invoice && (
                    <InvoiceClone
                      role={user.data.roles}
                      id={invoice.id}
                      invoiceId={invoice.invoiceId}
                    />)
                  }
                </div>
                {(invoice && user.data.roles !== ROLES.CLIENT &&
                  (invoice.invoiceType !== 'SALES_INVOICE' && invoice.invoiceType !== 'FAKTURA_KONCOWA')) && (
                    <IssueInvoice
                      invoiceType={invoice.invoiceType}
                      id={id}
                      invoiceId={invoice.invoiceId}
                    />
                  )}
                {(invoice && invoice.invoiceType === 'FAKTURA_KONCOWA' && user.data.roles !== ROLES.CLIENT) && (
                  <InvoiceBindPopup
                    invoiceId={invoice.invoiceId}
                    clientId={invoice.client.id}
                    id={id}
                  />
                )}
                <div className='pb-[10px]'>
                  <LanguageSelectorForPdfDowloadNew
                    className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                    icon={<DownloadIcon fontSize="small" />}
                    selectedItems={[id]}
                  />
                </div>
                <div className='pb-[0px]'>
                  {(invoice && (user.data.roles !== ROLES.CLIENT || (invoice.invoiceType === INVOICE_TYPES.PROFORMA)) &&
                    <Link style={{ textDecoration: 'none' }} to={`/admin/invoices/details/edit/${id}`}>
                      <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" >
                        {t("common:button_change")}
                      </Button>
                    </Link>)}
                </div>
              </div>
            </div>
          </div>
          <div className='w-full p-8'>
            <div className='text-xl font-medium pb-6'>
              {t("common:main_information_title")}
            </div>
            <div className='w-9/12'>
              <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t("common:client")}
                    value={invoice ? `${invoice.client.firstName} ${invoice.client.lastName} ` : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:type")}
                    value={invoice ? INVOICE_TYPE(t).filter(e => e.id == invoice.invoiceType)[0].value : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:invoice_id")}
                    value={invoice ? invoice.invoiceId : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                </div>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t("invoices:date_of_creation")}
                    value={invoice ? formatDateString(invoice.createdAt) : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:date_of_sale")}
                    value={invoice ? formatDateString(invoice.domainDate) : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:status")}
                    value={invoice ? (invoice.state === 'NOT_APPROVED' ? t("invoices:status_in_progress") : t("invoices:status_complete")) : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                </div>
              </div>
              <div className='text-xl font-medium pt-14 pb-6'>
                {t("invoices:counterparty_info")}
              </div>
              <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t("common:counterparty")}
                    value={invoice ? invoice.counterparty : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:origin")}
                    value={invoice ? COUNTRY_TYPE_MAP(t)[invoice.counterpartyOrigin] ?? '' : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:positions_name")}
                    value={invoice ? invoice.counterpartyName : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label='NIP'
                    value={invoice ? invoice.counterpartyNIP : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label='Email'
                    value={invoice ? invoice.counterpartyEmail : 'N/A'}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                </div>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t("common:type")}
                    value={invoice ? invoice.counterpartyType : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:address")}
                    value={invoice ? invoice.counterpartyAddress : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:post_index")}
                    value={invoice ? invoice.counterpartyPostIndex : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:city")}
                    value={invoice ? invoice.counterpartyCity : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("common:country")}
                    value={invoice ? GET_COUNTRY_NAME_BY_CODE(invoice.counterpartyCountry) : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                </div>
              </div>
              {invoice && invoice.recipientId && (
                <>
                  <div className='text-xl font-medium pt-14 pb-6'>
                    DIFFERENT_RECIPIENT
                  </div>
                  <div className='w-full flex justify-between'>
                    <div className='w-[49%]'>
                      <InputWithAlert
                        label={t("common:counterparty")}
                        value={invoice ? invoice.recipient?.name : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label={t("common:origin")}
                        value={invoice ? COUNTRY_TYPE_MAP(t)[invoice.recipient?.origin] ?? '' : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label={t("invoices:positions_name")}
                        value={invoice ? invoice.recipient?.name : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label='NIP'
                        value={invoice ? invoice.recipient?.NIP : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label='Email'
                        value={invoice ? invoice.recipient?.email : 'N/A'}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                    </div>
                    <div className='w-[49%]'>
                      <InputWithAlert
                        label={t("common:type")}
                        value={invoice ? invoice.recipient?.counterpartyType : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label={t("common:address")}
                        value={invoice ? invoice.recipient?.address : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label={t("common:post_index")}
                        value={invoice ? invoice.recipient?.postIndex : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label={t("common:city")}
                        value={invoice ? invoice.recipient?.city : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                      <InputWithAlert
                        label={t("common:country")}
                        value={invoice ? GET_COUNTRY_NAME_BY_CODE(invoice.recipient?.country) : ''}
                        readOnly={true}
                        disabled
                        inputClassName={'h-12 mt-2 mb-4 bg-white'}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className='text-xl font-medium pt-14 pb-6'>
                {t("invoices:payment_title")}
              </div>
              <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t("invoices:transaction")}
                    value={invoice ? TRANSACTION_TYPE_MAP(t)[invoice.typeOfPayment] ?? '' : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:payment_status")}
                    value={invoice ? PAYMENT_STATUS_MAP(t)[invoice.paymentStatus] ?? '' : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:currency")}
                    value={invoice ? invoice.paymentCurrency : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:payment_account")}
                    value={invoice ? `${invoice.paymentIban}  ${invoice.paymentCurrency}` : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                </div>
                <div className='w-[49%]'>
                  <InputWithAlert
                    label={t("invoices:way_of_payment")}
                    value={invoice ? PAYMENT_METHOD_MAP(t)[invoice.wayOfPayment] ?? '' : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t("invoices:payment_due_date")}
                    value={invoice ? formatDateString(invoice.paymentDueDate) : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  <InputWithAlert
                    label={t('invoices:currency_rate_PLN')}
                    value={invoice ? invoice.paymentCurrencyRate : ''}
                    readOnly={true}
                    disabled
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                  {invoice.paymentCurrencyRateDate &&
                    <InputWithAlert
                      label={t("invoices:payment_currency_date")}
                      value={invoice && invoice.paymentCurrencyRateDate ? format(new Date(invoice.paymentCurrencyRateDate), 'dd.MM.yyyy') : ''}
                      readOnly={true}
                      disabled
                      inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                  }
                </div>
              </div>
              <div className='w-full flex justify-between'>
                <div className='w-[49%]'>
                  <div className='text-xl font-medium  pt-14 pb-6'>
                    {t("invoices:internal_comment")}
                  </div>
                  <Textarea
                    readOnly={true}
                    disabled
                    placeholder={t("invoices:internal_comment")}
                    value={invoice.internalComment ? invoice.internalComment : ''}
                    className="h-20 bg-white"
                  />
                </div>
                <div className='w-[49%]'>
                  <div className='text-xl font-medium pt-14 pb-6'>
                    {t("invoices:comment_on_invoice")}
                  </div>
                  <Textarea
                    readOnly={true}
                    disabled
                    placeholder={t("invoices:comment_on_invoice")}
                    value={invoice.externalComment ? invoice.externalComment : ''}
                    className="h-20 bg-white"
                  />
                </div>

              </div>
            </div>
            <div className='py-10'>
              <Typography sx={{ fontSize: '20px', fontWeight: '600', padding: '20px 0px 0px 0px' }}>
                {t("invoices:created_based_on")}
              </Typography>
              {invoice && invoice.fromInvoices.map((e) => {
                return (
                  <div key={e} className='pt-3'>
                    <Button className=" text-black bg-inherit hover:bg-inherit border-[0px] border-blueBorder shadow-none p-0 opacity-60 hover:opacity-100"
                      onClick={() => { handleNavigateOnClick(`/admin/invoices/details/${e.fromInvoice.id}`) }}
                    >{e.fromInvoice.invoiceId}</Button>
                  </div>
                )
              })}
            </div>
            <div className='text-xl font-medium pb-6'>
              {t("invoices:positions_title")}
            </div>
            <div className='pt-5'>
              <PositionsViewTable data={invoice.invoiceItems} />
            </div>
            <div className='text-xl font-medium pt-14 pb-6'>
              {t('invoices:summary')}
            </div>
            <div className='w-9/12'>
              <div className='w-[49%]'>
                <InputWithAlert
                  label={t("invoices:price_netto")}
                  value={`${invoice.invoiceTotalNetto} ${invoice.paymentCurrency}`}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />

                <InputWithAlert
                  label={"VAT"}
                  value={`${invoice.invoiceVAT} ${invoice.paymentCurrency}`}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
                <InputWithAlert
                  label={t('invoices:price_brutto')}
                  value={`${invoice.invoiceBrutto} ${invoice.paymentCurrency}`}
                  readOnly={true}
                  disabled
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />
              </div>
            </div>
            <ScrollToTop />
          </div>
        </div>
      )}
    </Box>
  )
};

export default InvoiceViewPage;
