import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog";
import { bankUploadExplanation } from "@/service/weexpertService";
import { BankTransactionForm } from "../forms/bank-transaction.from";
import { toast } from "../ui/use-toast";
import { useQueryClient } from "react-query";

export const BankTransactionExplanationCreatePopup = (props) => {

    const { t } = useTranslation();
    const { initialTransactionData, initialTransactionId } = props;
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient()

    const onSaved = (
        title,
        counterparty,
        date,
        account,
        currency,
        vat,
        vatAmount,
        grossAmount,
        netAmount,
        transactionType
    ) => {
        return bankUploadExplanation(
            initialTransactionId,
            title,
            counterparty,
            date,
            account,
            currency,
            vat,
            vatAmount,
            grossAmount,
            netAmount,
            transactionType
        ).then((response) => {
            if (response.status === 200) {
                toast({
                    title: t("alerts:toast_succes_invoice_create_description"),
                    description: t("alerts:toast_succes_invoice_create_description"),
                })
            }
        })
            .catch((error) => {
                if (error.response.status === 400 && error.response.data.type == 'explanation_amount_ecxeeded_transaction') {
                    toast({
                        title: t("alerts:toast_error_counterparty_title"),
                        description: error.response.data.message ?? '',
                        variant: "destructive"
                    })
                    return;
                }
                toast({
                    title: t("alerts:toast_error_counterparty_title"),
                    description: t("alerts:toast_error_invoice_description"),
                    variant: "destructive"
                })
            }).finally(() => {
                queryClient.invalidateQueries({ queryKey: ['transaction'] })
                setOpen(false)
            })
    }

    return <BaseDialog
        dialogTitle={""}
        buttonText={t("reports:create_explanation")}
        open={open}
        setOpen={setOpen}
    >
        <div className="w-[600px] pr-1 pl-1 ">
            <BankTransactionForm
                onSaved={onSaved}
                title={initialTransactionData.title}
                counterparty={initialTransactionData.contractor}
                date={initialTransactionData.bookingDate}
                account={initialTransactionData.accountNumber}
                currency={initialTransactionData.currency}
                vat={initialTransactionData.vat}
                grossAmount={initialTransactionData.amount}
                transactionType={initialTransactionData.transactionType}
            />
        </div>
    </BaseDialog>
}
