import { CONTRACT_PAY_TYPES, CONTRACT_PITS, CONTRACT_STATE, CONTRACT_STATES, CONTRACT_TAX_DEDUCTIBLES, CONTRACT_TYPE, CONTRACT_TYPES, ContractPayType, DECLARATION_LANGUAGES, REPRESENTATIVE_COMPANY_LIST, Signatories, parseNumber, transformFromIdValueToValueLabel } from "@/constants";
import { getClients, getUser } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { Search } from "../ui/combobox-search";
import { DatePicker } from "../ui/date-picker";
import { InputWithAlert } from "../ui/input-with-alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { Switch } from "../ui/switch";
import { Textarea } from "../ui/textarea";
import { getClientLabel } from "./invoice.form";
import { Alert, AlertTitle } from "../ui/alert";
import { AlertCircle } from "lucide-react";
import { format, isBefore } from "date-fns";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Loader } from "../ProtectedRoute";
export const ContractForm = (props) => {
  const {
    client,
    setClient,
    inputChecked,
    contractType,
    setContractType,
    conclusionDate,
    setConclusionDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    grossAmount,
    setGrossAmount,
    taxDeductible,
    setTaxDeductible,
    pit,
    setPit,
    subjectMetter,
    setSubjectMetter,
    student,
    setStudent,
    contractState,
    setContractState,
    signatory,
    setSignatory,
    youngDiscount,
    setYoungDiscount,
    declarationLanguage,
    setDeclarationLanguage,
    contractPayType,
    setContractPayType,
    denouementDate,
    setDenouementDate,
    dontUseStartDate,
    setDontUseStartDate,
    startText,
    setStartText,
    representativeCompany,
    setRepresentativeCompany,
    actualGrossAmount = grossAmount,
    actualEndDate = endDate,
    paidAmount = 0,
    disabled = false
  } = props;
  const { t } = useTranslation()

  const contractPayOptions = contractType == CONTRACT_TYPE.UMOWA_O_DZIELO ?
    [{
      value: ContractPayType.OneTime, label: t('contracts:one_time_payment')
    }] :
    CONTRACT_PAY_TYPES(t).filter(e => e.value !== ContractPayType.OneTime);

  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
  if (isUserLoading) {
    return <Loader />
  }

  if (!userIsFetched) {
    return <Loader />
  }

  const userRole = user.data.roles;
  return (<div className='w-5/12'>
    <div className="text-xl font-medium ">{t('main_information_title')}</div>
    <div className='w-full flex justify-between pt-6'>
      <div className='w-full'>
        {user.data.roles !== 'CLIENT' && (<Search
          fetchOption={(q) => {
            return getClients(100, 0, q)
              .then((res) => {
                return res.data.clients
                  .map(client => {
                    return {
                      label: getClientLabel(client),
                      value: client.id,
                      client: client
                    }
                  })
              })
          }}
          placeholder={t("common:client") + "*"}
          label={t("common:client") + "*"}
          value={client ? getClientLabel(client) : null}
          disabled={disabled}
          onChange={(e) => {
            setClient(e.client);
            setRepresentativeCompany(e.client.representativeCompany);
            setSignatory('');
          }}
          error={inputChecked && !client}
          errorMessage={t('empty_data_invalid')}
        />
        )}
        {user.data.roles !== 'CLIENT' && client && (<SelectWithAlert
          label={t("bills:bills_signatory")}
          disabled={disabled}
          options={Signatories[client.representativeCompany]}
          value={signatory}
          onChangeValue={setSignatory}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />)
        }
        {user.data.roles !== 'CLIENT' && <SelectWithAlert
          label={t("contracts:state_of_contract")}
          disabled={disabled}
          options={CONTRACT_STATES(t)}
          value={contractState ?? ''}
          onChangeValue={setContractState}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />}
        {contractState == CONTRACT_STATE.Ended &&
          <DatePicker
            label={t("contracts:denouement_date")}
            date={denouementDate}
            readOnly={disabled}
            defaultMonth={denouementDate}
            setDate={(date) => {
              if (!date) return
              setDenouementDate(date)
            }
            }
            error={inputChecked && !denouementDate && contractState == CONTRACT_STATE.Ended}
            errorMessage={t('empty_data_invalid')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        }
        {user.data.roles !== 'CLIENT' &&
          <SelectWithAlert
            label={"Oswiadczenie"}
            disabled={disabled}
            options={DECLARATION_LANGUAGES(t)}
            value={declarationLanguage ?? ''}
            onChangeValue={setDeclarationLanguage}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        }
        <SelectWithAlert
          label={t("common:type") + "*"}
          disabled={disabled}
          options={CONTRACT_TYPES(t)}
          value={contractType ?? ''}
          onChangeValue={(e) => {
            setContractType(e)
            setContractPayType('')
          }}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        {contractType &&
          <SelectWithAlert
            label={t("contracts:payment_type") + "*"}
            disabled={disabled}
            options={contractPayOptions}
            value={contractPayType ?? ''}
            onChangeValue={setContractPayType}
            error={inputChecked && !contractPayType}
            errorMessage={t('empty_data_invalid')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        }
        <DatePicker
          label={t("contracts:conclusion_date")}
          date={conclusionDate}
          disabled={disabled}
          defaultMonth={conclusionDate}
          setDate={(date) => {
            if (!date) return
            setConclusionDate(date)
          }
          }
          error={inputChecked && !conclusionDate}
          errorMessage={t('empty_data_invalid')}
          inputClassName={'h-12 mt-2 mb-4 '}
        />
        {!!!dontUseStartDate &&
          <DatePicker
            label={t("contracts:start_date")}
            date={startDate}
            defaultMonth={startDate}
            disabled={disabled ? disabled : { before: conclusionDate }}
            setDate={(date) => {
              if (!date) return
              setStartDate(date)
            }
            }
            error={inputChecked && !startDate || isBefore(startDate, conclusionDate)}
            errorMessage={t('format_data_invalid')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        }
        {userRole !== 'CLIENT' && (<>
          <div className="w-full text-sm font-medium py-2 mb-4 flex items-center">
            <Switch className={"mr-3"} checked={dontUseStartDate} onCheckedChange={(e) => {
              setDontUseStartDate(e);
              setStartText(undefined);
            }} />
            {t("contracts:start_date_switch_not_use")}
          </div>
          {dontUseStartDate &&
            <div className="pb-2">
              <Textarea
                disabled={disabled}
                placeholder={t("contracts:start_text")}
                value={startText ?? ''}
                onChange={(e) => setStartText(e.target.value)}
                className="h-20 bg-white"
                error={inputChecked && dontUseStartDate && !startText}
                errorMessage={t('format_data_invalid')}
              />
            </div>
          }
        </>
        )}
        <DatePicker
          label={t("contracts:end_date")}
          date={endDate}
          defaultMonth={endDate}
          setDate={(date) => {
            if (!date) return
            setEndDate(date)
          }
          }
          error={inputChecked && !endDate || isBefore(endDate, startDate)}
          disabled={disabled ? disabled : { before: startDate }}
          errorMessage={t('format_data_invalid')}
          inputClassName={'h-12 mt-2 mb-4 '}
        />
        {format(new Date(actualEndDate), 'dd.MM.yyy') != format(new Date(endDate), 'dd.MM.yyy') &&
          <InputWithAlert
            label={t("contracts:end_date") + " z annex"}
            value={format(new Date(actualEndDate), 'dd.MM.yyyy')}
            disabled={true}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        }
        <InputWithAlert
          label={t("contracts:gross_amout")}
          value={grossAmount}
          disabled={disabled}
          onChange={(e) => setGrossAmount(parseNumber(e.target.value))}
          error={inputChecked && ((+grossAmount) <= 0 || grossAmount >= 1000000.0)}
          errorMessage={grossAmount > 0 ? 'musi byc mniej niz 1000000' : ('empty_data_invalid')}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        {actualGrossAmount != grossAmount &&
          <InputWithAlert
            label={t("contracts:gross_amout") + " z annex"}
            value={actualGrossAmount}
            disabled={true}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        }
        <InputWithAlert
          label={"Zaplacono"}
          value={paidAmount}
          disabled={true}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        <SelectWithAlert
          label={t("contracts:tax_deductible")}
          options={CONTRACT_TAX_DEDUCTIBLES(t)}
          disabled={disabled}
          value={taxDeductible ?? ''}
          onChangeValue={setTaxDeductible}
          error={inputChecked && !taxDeductible}
          errorMessage={t('empty_data_invalid')}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        <SelectWithAlert
          label={"PIT"}
          disabled={disabled}
          options={CONTRACT_PITS(t)}
          value={pit ?? ''}
          onChangeValue={setPit}
          error={inputChecked && !pit}
          errorMessage={t('empty_data_invalid')}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        <SelectWithAlert
          label={t('clients:representative_company') + "*"}
          value={representativeCompany}

          disabled={disabled}
          onChangeValue={(e) => setRepresentativeCompany(e)}
          options={REPRESENTATIVE_COMPANY_LIST.map(transformFromIdValueToValueLabel)}
          inputClassName={'h-12 mt-2 mb-4 bg-white'}
        />
        <div className='text-xl font-medium pt-14 pb-6'>
          {t("contracts:subject_metter")}
        </div>
        <Textarea
          disabled={disabled}
          placeholder={t("contracts:subject_metter")}
          value={subjectMetter ?? ''}
          onChange={(e) => setSubjectMetter(e.target.value)}
          className="h-20"
        />
        {inputChecked && !subjectMetter && (<div className="mt-4">
          <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
            <AlertCircle className="h-4 w-4 !static !mr-2" />
            <AlertTitle className="m-0 !p-0">
              {t("empty_data_invalid")}
            </AlertTitle>
          </Alert>
        </div>)
        }
        {user.data.roles !== 'CLIENT' &&
          <>
            <div className="w-full text-xl font-medium py-6 ">
              {t("contracts:student")}
              <Switch className={"ml-3"} checked={student} onCheckedChange={setStudent} />
            </div>
            <div className="w-full text-xl font-medium py-6 ">
              {t("contracts:under_26")}
              <Switch className={"ml-3"} checked={youngDiscount} onCheckedChange={setYoungDiscount} />
            </div>
          </>
        }
      </div>
    </div>
  </div>)
}
