import React, { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import "../../scssStyles/SurveyStep2.scss";
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useTranslation } from "react-i18next";
import AppBarForLogin from "@/components/app-bar-for-main-login-pages/AppBarForLogin";
import { getUser, touchExpirienceStage } from "../../service/weexpertService";
import MultipleSelector from "@/components/ui/multiple-selector";
import { JOB_TITLE_OPTIONS, TOOLS_SKILLS } from "@/profile.constants";
import { DatePicker } from "@/components/ui/date-picker";

export const ProfileStepOnePage = ({ next }) => {
  const { t } = useTranslation();
  const { data: userData, isLoading: loading } = useQuery({
    queryKey: ["userMeProfile"],
    queryFn: getUser,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data) {
        const { profile } = data.data;
        if (!profile) {
          return
        }
        if (profile.jobTitles) {
          setJob(
            profile.jobTitles.map((e) => ({ value: e, label: e })),
          );
        }
        if (profile.knowleges) {
          setTools(
            profile.knowleges.map((e) => ({ value: e, label: e })),
          );
        }
        if (profile.experienceDate) {
          setExperienceDate(new Date(profile.experienceDate));
        }
      }
    },
  });
  const [jobTitles, setJob] = useState([]);
  const [tools, setTools] = useState([]);
  const [experienceDate, setExperienceDate] = useState("");
  const [inputChecked, setInputChecked] = useState(false);

  const onSaveStates = () => {
    setInputChecked(true);
    if (!jobTitles || !tools || !experienceDate) {
      return;
    }
    return touchExpirienceStage(
      jobTitles.map((e) => e.value),
      tools.map((e) => e.value),
      experienceDate,
    ).then(() => {
      if (next) next();
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={"container"}>
      <AppBarForLogin isLogined={true} userData={userData.data} />
      <div className="pt-24 pb-28">
        <div className="text-3xl font-semibold w-96 pb-6">
          Step 1/4: Your expertise
        </div>
        <div className="pb-6 text-base">
          Give us more details about your expertise and experience
        </div>
        <div className="w-1/3 mb-4">
          <MultipleSelector
            options={JOB_TITLE_OPTIONS}
            maxSelected={3}
            label={"Job titles/roles"}
            errorMessage={t("empty_data_invalid")}
            error={inputChecked && (!jobTitles || jobTitles.length == 0)}
            value={jobTitles}
            onChange={(e) => {
              console.log(e);
              setJob(e);
            }}
            placeholder={"Job titles/roles"}
            inputClassName={"h-12 my-2 bg-white"}
          />
          <MultipleSelector
            options={TOOLS_SKILLS}
            label={"Technology stack/tools"}
            placeholder={"Technology stack/tools"}
            errorMessage={t("empty_data_invalid")}
            error={inputChecked && (!tools || tools.length == 0)}
            value={tools}
            onChange={(e) => {
              setTools(e);
            }}
            inputClassName={"h-12 my-2 bg-white"}
          />
          <DatePicker
            label={"Experience (start date)"}
            errorMessage={t("empty_data_invalid")}
            value={experienceDate}
            date={experienceDate ? new Date(experienceDate) : null}
            defaultMonth={
              experienceDate ? new Date(experienceDate) : new Date()
            }
            setDate={setExperienceDate}
            error={inputChecked && !experienceDate}
            placeholder={"Experience (start date)"}
            inputClassName={"h-12 my-2 bg-white"}
          />
        </div>
      </div>
      <Progress value={25} />
      <div className="flex flex-row justify-end pt-6">
        <Button className="w-36" onClick={() => onSaveStates()}>
          Next
        </Button>
      </div>
    </div>
  );
};
