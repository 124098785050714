import { explanationTypeMap, validateOnlyLatin, vatData } from "@/constants";
import {
  batchExplanationCreate,
} from "@/service/weexpertService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Button } from "../ui/button";
import { InputWithAlert } from "../ui/input-with-alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { toast } from "../ui/use-toast";
import { BaseDialog } from "./dialog";
const defaultVat = '23%';
export const BatchExplanationCreate = (props) => {
  const { ids, onComplete } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [explanation, setExplanation] = useState("");
  const [vat, setVat] = useState(defaultVat);
  const queryClient = useQueryClient();
  const onSubmit = () => {
    batchExplanationCreate(ids, vat, explanation, title)
      .then(() => {
        toast({
          title: t("alerts:toast_succes_contract_create_title"),
          description: t("alerts:toast_succes_invoice_create_description"),
        });
      })
      .catch(() => {
        toast({
          title: t("alerts:toast_error_counterparty_title"),
          description: t("alerts:toast_error_invoice_description"),
          variant: "destructive",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: ["report_____", "transactions___"],
        });
        setTitle("");
        setExplanation("");
        setVat(defaultVat);
        setOpen(false);
        if(onComplete) {
          onComplete()
        }
      });
  };

  return (
    <BaseDialog
      dialogTitle={""}
      buttonText={t("reports:create_explanation")}
      open={open}
      setOpen={setOpen}
    >
      <div className="w-[600px] pr-1 pl-1 ">
        <div className="w-full">
          <div>
            <InputWithAlert
              label={t("reports:explanation")}
              value={title}
              onChange={(e) => {
                if(validateOnlyLatin(e.target.value)) {
                  setTitle(e.target.value)
                }
              }}
              inputClassName={"h-8"}
            />
          </div>
        </div>
        <div className="pt-2">
          <SelectWithAlert
            label={t("invoices:positions_type_of_vat")}
            value={vat}
            options={vatData.map((option, index) => ({
              value: option,
              label: option,
            }))}
            onChangeValue={(e) => setVat(e)}
            inputClassName={"h-8 text-xs p-[4px] bg-white mt-1"}
          />
        </div>
        <div className="pt-2">
          <SelectWithAlert
            label={t("reports:explanation_type")}
            value={explanation}
            options={explanationTypeMap}
            onChangeValue={(e) => setExplanation(e)}
            inputClassName={"h-8 text-xs p-[4px] bg-white mt-1"}
          />
        </div>

        <div className="w-full flex flex-col items-end">
          <Button
            className="mt-4"
            onClick={() => {
              onSubmit();
            }}
          >
            {t("button_add")}
          </Button>
        </div>
      </div>
    </BaseDialog>
  );
};
