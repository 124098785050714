import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog";
import { BankTransactionForm } from "../forms/bank-transaction.from";
import { toast } from "../ui/use-toast";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { editBankTransactionExplanation } from "@/service/weexpertService";
import { useQueryClient } from "react-query";

export const BankTransactionExplanationEditPopup = (props) => {

    const { explanationData, initialExplanationId } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient()
    const onSaved = (
        title,
        counterparty,
        date,
        account,
        currency,
        vat,
        vatAmount,
        grossAmount,
        netAmount,
        transactionType
    ) => {
        editBankTransactionExplanation(initialExplanationId, title, counterparty, date, account, currency, vat, vatAmount, grossAmount, netAmount, transactionType)
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        title: t("alerts:toast_succes_invoice_create_description"),
                        description: t("alerts:toast_succes_invoice_create_description"),
                    })
                }

            }).catch((e) => {
                toast({
                    title: t("alerts:toast_error_counterparty_title"),
                    description: "alerts:toast_error_invoice_description",
                    variant: "destructive"
                })
            })
            .finally(() => {
                queryClient.invalidateQueries({queryKey: ['transaction']})
                setOpen(false)
            })
    };

    return <BaseDialog
        dialogTitle={""}
        buttonText={<Pencil1Icon />}
        open={open}
        setOpen={setOpen}
    >
        <div className="w-[600px] pr-1 pl-1 ">
            <BankTransactionForm
                onSaved={onSaved}
                title={explanationData.title}
                counterparty={explanationData.counterparty}
                date={explanationData.date}
                account={explanationData.account}
                currency={explanationData.currency}
                vat={explanationData.vat}
                grossAmount={explanationData.grossAmount}
                transactionType={explanationData.transactionType}
                edit={true}
            />
        </div>
    </BaseDialog>
}
