import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { blueGrey } from "@mui/material/colors";
import { SUPPORTED_CURRENCIES } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { SelectWithAlert } from '@/components/ui/select-with-alert';
import { InputWithAlert } from '@/components/ui/input-with-alert';

import { AlertTitle, Alert } from '@/components/ui/alert';
import { AlertCircle } from "lucide-react";
import { Button } from '@/components/ui/button';
const grayLight = blueGrey[700];

const INCUBATOR_TYPE = 'INCUBATOR'
const CLIENT_TYPE = 'CLIENT'

const ClientBankAccount = (props) => {

    const { t } = useTranslation();
    const { isIncubator } = props

    const [error, setError] = useState();

    const isCurrencyAlreadyExists = (newCurrency, accountId) => {
        return props.bankAccounts.some((account) => account.currency === newCurrency && account.id !== accountId);
    };

    const handleAddAccount = () => {
        const newId = props.bankAccounts.length + 1;
        const defaultCurrency = 'PLN';
        let newCurrency = defaultCurrency;
        const availableCurrencies = SUPPORTED_CURRENCIES.map((currency) => currency.value);
        const existingCurrencies = props.bankAccounts.map((account) => account.currency);
        const remainingCurrencies = availableCurrencies.filter((currency) => !existingCurrencies.includes(currency));

        if (remainingCurrencies.length > 0) {
            newCurrency = remainingCurrencies[0];
        } else {

            setError(t('alerts:error_client_bank_account_no_free_currency'))
            //props.setAlertMessage(alertErrorCurrency);
            return;
        }

        props.setBankAccounts([...props.bankAccounts, {
            id: newId, account: '',
            currency: newCurrency,
            accountType: isIncubator ? INCUBATOR_TYPE : CLIENT_TYPE
        }]);
    };

    const handleRemoveAccount = (id) => {
        const updatedAccounts = props.bankAccounts.filter((acc) => acc.id !== id);
        props.setBankAccounts(updatedAccounts);
    };

    const handleCurrencyChange = (id, newCurrency) => {
        if (isCurrencyAlreadyExists(newCurrency, id)) {

            setError(t('alerts:error_client_bank_account_currency_already_declared'));
            //props.setAlertMessage(alertErrorUniqueCurrencyPaymentAccount);
            return;
        }

        const updatedAccounts = props.bankAccounts.map((acc) =>
            acc.id === id ? { ...acc, currency: newCurrency } : acc
        );
        props.setBankAccounts(updatedAccounts);
    };


    const handleAccountChange = (id, newValue) => {
        const re = /^[A-Za-z]{2}\s?\d{2}\s*\d{4}\s*\d{4}\s*\d{4}\s*\d{4}\s*\d{4}\s*\d{4}\s*$/;
        const isValid = re.test(newValue);

        const updatedAccounts = props.bankAccounts.map((account) =>
            account.id === id ? { ...account, account: newValue } : account
        );

        props.setBankAccounts(updatedAccounts);

        if (!isValid && newValue.length !== 0) {
            setError(t('alerts:error_client_bank_account_type'));
        } else {
            setError(undefined)
        }
    };

    return (
        <div className='w-full '>
            {props.bankAccounts && props.bankAccounts.map((account, index) => (
                <div key={index}className='w-full'>
                    <div className='w-full flex justify-between items-end'>
                        <div className={index === 0 ? 'w-[80%]' : 'w-[80%]'}>
                        <InputWithAlert
                            value={account.account}
                            onChange={(e) => handleAccountChange(account.id, e.target.value)}
                            label={isIncubator ? t('clients:bank_account_inqubator') : t('clients:bank_account_client')}
                            error={false}
                            errorMessage={"te"}
                            inputClassName={'h-12 mt-2 mb-4  bg-white'}
                        />
                        </div>
                        <div className={index === 0 ? 'w-[18.4%] ml-[14px]' : 'w-[12.8%] ml-[14px]'}>
                            <SelectWithAlert
                                value={account.currency}
                                options={SUPPORTED_CURRENCIES}
                                onChangeValue={(e) => handleCurrencyChange(account.id, e)}
                                inputClassName={'h-12 mt-2 mb-4  bg-white'}
                            />
                        </div>
                        {index !== 0 && (
                                <Button className="mb-6 bg-inherit hover:bg-inherit shadow-none" onClick={() => handleRemoveAccount(account.id)}>
                                    <DeleteIcon style={{ color: `${grayLight}` }} onClick={() => handleRemoveAccount(account.id)} />
                                </Button>   
                        )}    
                    </div>
                </div>

            ))}
            <Button className="mt-4" onClick={handleAddAccount}>
                {t('clients:create_client_create_payment_account_button')}
            </Button>
            {error && (<div className="mt-4">
                <Alert variant="destructive" className="relative mb-2 h-12 flex items-center justify-start !pl-[0.75rem]">
                    <AlertCircle className="h-4 w-4 !static !mr-2" />
                    <AlertTitle className="m-0 !p-0">
                        {error}
                    </AlertTitle>
                </Alert>
            </div>)
            }
        </div>
    );
};

export default ClientBankAccount;
