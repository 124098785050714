import { Loader } from "@/components/ProtectedRoute";
import { Button } from "@/components/ui/button";
import { getSpecificaiton, getSpecificaitonPDF, getUser } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { format } from "date-fns";

export const SpecificationViewPage = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();

    const { data: specification, isLoading, isFetched } = useQuery({
        queryKey: ['specification', id], queryFn: () => {
            return getSpecificaiton(id)
        }
    })

    const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
    if (isLoading || !isFetched || isUserLoading) {
        return <Loader />
    }

    const specificationItems = specification.data.specificationItems.map((item) => {
        return (
            <div className="w-full flex justify-between items-center py-2">
                <div className=" text-center mr-4 text-sm">
                    {item.order}
                </div>
                <div className="w-10/12">
                    <Textarea
                        placeholder={t("specifications:spec_text")}
                        value={item.description}
                        readonly={true}
                        className={' h-[60px] pt-5'}
                    />
                </div>
                <div className="w-1/12 ml-4">
                    <InputWithAlert
                        type="number"
                        placeholder={t("bills:gross_amount")}
                        value={item.grossAmount}
                        disabled={true}
                        inputClassName={'h-[60px] w-full '}
                    />
                </div>
                <div className="w-1/12 ml-4">
                    <InputWithAlert
                        placeholder={t("specifications:spec_end_date")}
                        type="text"
                        disabled={true}
                        value={item.endDate ? format(new Date(item.endDate), 'dd.MM.yyyy') : ""}
                        inputClassName={'h-[60px] w-full  '}
                    />
                </div>
            </div>
        )

    })

    return (
        <div className="relative bg-grayLightMainBg px-8">
            <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
                <div className="flex justify-between py-8 items-center ">
                    <div className=" w-5/12  text-4xl ">
                        {t('specifications:spec_details')}
                    </div>
                    <div className='w-6/12 flex justify-end'>
                        <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none mr-2"
                            onClick={() => {
                                toast({
                                    title: t("generation_pdf_started")
                                })
                                getSpecificaitonPDF(specification.data.id)
                                    .then(() => {
                                        toast({
                                            title: t("generation_pdf_success")
                                        })
                                    })
                                    .catch((res) => {
                                        Sentry.captureException(res);
                                        toast({
                                            title: t("generation_pdf_error"),
                                            variant: "destructive"
                                        })
                                    })
                            }}
                        >
                            PDF
                        </Button>
                        {user.data.roles !== "CLIENT" &&
                            <Link to={`/specification/edit/${id}`}>
                                <Button className=" text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                                    {t('button_change')}
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
            <div className='w-full py-4'>
                <div className='w-full md:w-12/12 lg:w-8/12 xl:w-5/12 py-8'>
                    <div className="pt-6">
                        <InputWithAlert
                            label={t('contracts:contract')}
                            placeholder={t('contracts:contract')}
                            disabled={true}
                            type="text"
                            value={specification.data.contract.contractId}
                            inputClassName={'h-[60px] w-full  mt-4'}
                        />
                    </div>
                    <div className='text-sm font-medium pt-4 pb-6'>
                        {t('specifications:spec_description')}
                    </div>
                    <Textarea
                        placeholder={t('specifications:spec_description')}
                        value={specification.data.description}
                        disabled={true}
                        className="h-20"
                    />
                    <div className='text-sm font-medium pt-4 pb-6'>
                        {t('specifications:spec_functionality')}
                    </div>
                    <Textarea
                        placeholder={t("specifications:spec_functionality")}
                        value={specification.data.functionality}
                        disabled={true}
                        className="h-20"
                    />
                </div>
            </div>
            <div className="pt-8">{t('specifications:spec_details')}</div>
            <div className='w-full pt-8 text-2xl pb-10'>
                {specificationItems}
            </div>
        </div>
    )
}
