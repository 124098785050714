import * as React from 'react';
import { Button } from "@/components/ui/button";
import { Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { changePasword } from '../../service/weexpertService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputWithAlert } from '../ui/input-with-alert';
import { rolesForUsersCreate } from '@/constants';

const ChangePass = (props) => {

  const { userData, togglePasswordChange } = props
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [consent, setConsent] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const alertMessagehandler = (state) => {
    setAlertMessage(state)
  }

  const cleanValues = (pass, newpass, confirm) => {
    pass('');
    newpass('');
    confirm('');
  };

  useEffect(() => {
    if (newPass === confirmPass && confirmPass.trim() !== '') {
      setConsent(true)
    }
    else {
      setConsent(false);
    }

  }, [newPass, confirmPass]);

  const onClickSendButtonHandler = () => {
    // push pass from back
    if (!consent) {
      alertMessagehandler(alertErrorMail);
    }
    if (consent) {
      changePasword(currentPass, newPass)
        .then((response) => {
          setAlertMessage(alertSuccess);
          setTimeout(() => {
            const responseData = response.data;
            Cookies.set('token', `${responseData.access_token}`, { expires: 1 });
            navigate('/');

            cleanValues(
              setCurrentPass,
              setNewPass,
              setConfirmPass
            );
          }, 1500);

        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            console.log('Ошибка: Неверные данные, код 400');
          } else {
            console.error(error);
          }
        });
    }
  };

  const alertErrorMail = <Alert severity="error">{t("alerts:error_password_mismatch")}</Alert>;
  const alertSuccess = <Alert severity="success">{t("alerts:success_change_data")}</Alert>;

  return (
    <>
      <div className="w-full min-h-[95vh] px-8 py-10 bg-grayLightMainBg">
        <div className='w-full flex justify-between items center pt-2 pb-4'>
          <div className="text-4xl ">
            {t("users:change_pass_title")}
          </div>
          <div>
            <Button
              className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={() => togglePasswordChange(false)} >{t("button_back")}
            </Button>
            <Button
              className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
              onClick={() => {
                onClickSendButtonHandler();
              }} >{t("button_save")}
            </Button>
          </div>
        </div>
        <div className="text-xl pt-6">
          {`${userData.firstName} ${userData.lastName}  `}
        </div>
        <div className='text-base opasity-60 pt-2'>
          {rolesForUsersCreate(t).find(e => e.value === userData.roles)?.display}
        </div>
        <div className='w-5/12 pt-8 pb-16'>
          <InputWithAlert
            label={t("users:new_pass")}
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
            errorMessage={t('empty_data_invalid')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
          <InputWithAlert
            label={t("users:confirm_pass")}
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
            errorMessage={t('empty_data_invalid')}
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      </div>
    </>
  )
}
export default ChangePass
