import * as React from 'react';
import { useState } from 'react';
import OutsideHook from '../outside-hook/OutsideHook';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import AccountInfo from '../account-components/AccountInfo';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { EnterIcon,GearIcon } from '@radix-ui/react-icons';
import { Button } from '../ui/button';
import {
  useQueryClient,
} from 'react-query'


export default function LanguageSelector(props) {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const [refCross, isShowCross, setIsShowCross] = OutsideHook(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language.toUpperCase() == 'UK' ? 'UA' : i18n.language.toUpperCase());

  const setLanguage = (language) => {
    i18n.changeLanguage(language.toLowerCase() == 'ua' ? 'uk' : language.toLowerCase())
    setCurrentLanguage(language);
  };

  const langData = ['RU', 'EN', 'UA', 'PL'];
  const langItem = langData.filter((item) => item !== currentLanguage).map((item) => (

    <DropdownMenuItem className="hover:bg-blueLightAccent text-blackText font-normal" key={item} onClick={() => {
      setLanguage(item);
    }}>{item}</DropdownMenuItem>

  ));

  const abbreviationName = (dataString) => {
    if (dataString && dataString.length > 0) {
      return dataString[0]
    } else {
      return null
    }
  }
  return (
    <div className='w-24 h-8  absolute top-5 right-5 flex justify-between items-center '>
      <DropdownMenu >
        <DropdownMenuTrigger className=' outline-none'>{currentLanguage}</DropdownMenuTrigger>
        <DropdownMenuContent className={'mt-6'}>
          <DropdownMenuLabel > {langItem}</DropdownMenuLabel>
        </DropdownMenuContent>
      </DropdownMenu>
      <div className='relative' >
        {props.isLogined && props.userData && (
          <div ref={refCross}>
            <div className='bg-blueLightAccent w-8 h-8 rounded-md flex justify-center items-center text-base font-medium text-blueLink cursor-pointer'
              ref={refCross}
              onClick={() =>
                setIsShowCross(!isShowCross)}
            >
              {abbreviationName(props.userData.firstName)}
            </div>
            {isShowCross && (
              <div className=' w-48 absolute z-30 top-14 bg-grayLightMainBg right-0 p-5 shadow-md shadow-gray-200 rounded-lg'>
                <div className='flex flex-col justify-center items-center' >
                  <div className='bg-blueLightAccent mb-2 w-8 h-8 rounded-md flex justify-center items-center text-base font-medium text-blueLink'>
                    {abbreviationName(props.userData.firstName)}
                  </div>
                  <AccountInfo nameSize={16} userData={props.userData} align={'center'} />
                  <div className='w-full h-1 border-b-2 mb-2'></div>
                </div>
                {/*TODO ---> flag for survey needed (hide account button when survey not complete) */}
                <Button className="bg-inherit hover:bg-inherit border-0	shadow-none	text-blackText"
                  onClick={() => {
                    navigate('/account')
                    setIsShowCross(false)
                  }}>
                  <GearIcon className='h-4 w-4' />
                  <div className='ml-4'>{t("users:settings")}</div>
                </Button>
                <Button className="bg-inherit hover:bg-inherit border-0	shadow-none	text-blackText"
                  onClick={() => {
                    Cookies.remove('token');
                    queryClient.invalidateQueries();
                    queryClient.clear();
                    navigate('/');
                  }} >
                  <EnterIcon className='h-4 w-4' />
                  <div className='ml-4'>{t("users:logout")}</div>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
