import * as React from "react"
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table"
import { useState, useEffect } from "react";
import { CONTRACT_STATE, CONTRACT_TAX_DEDUCTIBLE, CONTRACT_TYPE, PAGINATION_SETTINGS, RECORD_CONTRACT_TYPE } from "@/constants";
import { getContracts, } from "@/service/weexpertService";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { DataTablePagination } from "@/components/ui/table-pagination"
import { Button } from "@/components/ui/button";
import { useDebounce } from "@/components/ui/multiple-selector";
import { useQuery } from "react-query";
import { useQueryState } from "./invoices.page";
import { format } from "date-fns";
import { ContractFilter } from "@/components/popups/contract.filter.popup";
import { getUser } from "@/service/weexpertService";
import { Skeleton } from "@radix-ui/themes";
import { Badge } from "@/components/ui/badge";
import { Loader } from "lucide-react";


export function ContractsPage(props) {

  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const [sharedFilter, setSharedFilter] = useQueryState('f', 'contracts_filter')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(sharedFilter ?? '{}').rowsPerPage ?? PAGINATION_SETTINGS.contracts);
  const [filters, setFilters] = useState(JSON.parse(sharedFilter ?? '[]').f ?? []);
  const [unprocessedFilter, setUnprocessedFilter] = useState(JSON.parse(sharedFilter ?? '{}').uf ?? {})
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

  const { data: contracts, isLoading, isFetched } = useQuery({
    queryKey: ['contracts', page, rowsPerPage, debouncedSearchQuery, filters],
    queryFn: () => getContracts(rowsPerPage, page * rowsPerPage, debouncedSearchQuery, Object.values(filters))
  })

  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: pagination.pageIndex, rowsPerPage: pagination.pageSize }))
  }, [pagination])

  const columns = [
    {
      accessorKey: "contractId",
      header: t("contracts:contract"),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/contracts/${row.original.id}`}>
          {`${row.original.contractId}`}
        </Link>
      },
      key: uuidv4()
    },
    {
      accessorKey: "client",
      header: t("common:client"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/contracts/${row.original.id}`}>
          {`${row.original.client.firstName} ${row.original.client.lastName}`}
        </Link>
      ),
      key: uuidv4()
    },
    {
      header: t("common:status"),
      cell: ({ row }) => {
        const { state } = row.original;
        const display = ((w) => {
          switch (w) {
            case CONTRACT_STATE.Accepted: {
              return t("contracts:accepted");
            }
            case CONTRACT_STATE.Ended: {
              return t("contracts:ended");
            }
            case CONTRACT_STATE.Pending: {
              return t("contracts:pending");
            }
          }
        })(state)
        const cssClasses = ((w) => {
          switch (w) {
            case CONTRACT_STATE.Accepted: {
              return 'bg-emerald-600 rounded text-pirpleText text-center text-white'
            }
            case CONTRACT_STATE.Ended: {
              return 'bg-pink-700 rounded text-pink-50 text-center'
            }
            case CONTRACT_STATE.Pending: {
              return 'bg-pirpleLightBg rounded text-pirpleText text-center'
            }
          }
        })(state)
        return <div className={`capitalize w-28 ${cssClasses}`}>
            {display}
          </div>
      },
      key: uuidv4()
    },
    {
      header: t("contracts:contract_type"),
      cell: ({ row }) => {
        return row.original.contractType == CONTRACT_TYPE.UMOWA_O_DZIELO ? <Badge className={'bg-emerald-600'}> Umowa o dzielo </Badge> : <Badge > Umowa zlecenia</Badge>;
      },
      key: uuidv4()
    },
    {
      header: t("contracts:tax_deductible"),
      cell: ({ row }) => {
        const getTaxDeductibleBadge = (taxDeductible) => {
          console.log(taxDeductible)
          switch (taxDeductible) {
            case CONTRACT_TAX_DEDUCTIBLE.FIFTY_PERCENT:
              return <Badge className={"bg-emerald-600"}> KUP: 50% </Badge>
            case CONTRACT_TAX_DEDUCTIBLE.TWENTY_PERCENT:
              return <Badge className={"bg-blue-600"}> KUP: 20% </Badge>
            case CONTRACT_TAX_DEDUCTIBLE.ZERO_PERCENT:
              return <Badge className={"bg-sky-600"}> KUP: 0% </Badge>
            default: {
              return <Badge> KUP: ? </Badge>
            }
          }
        }
        return getTaxDeductibleBadge(row.original.taxDeductible)
      },
      key: uuidv4()
    },

    {
      header: t("contracts:conclusion_date"),
      cell: ({ row }) => (
        <Link className="capitalize" to={`/contracts/${row.original.id}`}>
          {format(new Date(row.original.conclusionDate ?? null), 'dd.MM.yyyy')}
        </Link>
      ),
      key: uuidv4()
    },

    {
      header: t("contracts:end_date"),
      cell: ({ row }) => {
        const endDateChanged = format(new Date(row.original.endDate), 'dd.MM.yyy') != format(new Date(row.original.actualEndDate), 'dd.MM.yyy');
        const display = endDateChanged ? <><span className="line-through"> {format(new Date(row.original.endDate), 'dd.MM.yyy')} </span> {format(new Date(row.original.actualEndDate), 'dd.MM.yyyy')}</> :
          <>{format(new Date(row.original.endDate), 'dd.MM.yyyy')}</>
        return <Link className="capitalize" to={`/contracts/${row.original.id}`}>
          {display}
        </Link>
      },
      key: uuidv4()
    },
    {
      header: t("bills:gross_amount"),
      cell: ({ row }) => {
        const grossAmountChanged = row.original.grossAmount != row.original.actualGrossAmount;
        const display = grossAmountChanged ? <><span className="line-through"> {row.original.grossAmount.toFixed(2)} </span> {row.original.actualGrossAmount.toFixed(2)}</> : <>{row.original.grossAmount.toFixed(2)}</>
        return < Link className="capitalize" to={`/contracts/${row.original.id}`}>
          {display}
        </Link >
      },
      key: uuidv4()
    }
  ]

  const table = useReactTable({
    data: isLoading ? Array(rowsPerPage).fill({}) : contracts.data.contracts,
    columns: isLoading ? columns.map(column => {
      return {
        ...column,
        cell: () => <Skeleton className={"h-4 w-[150px]"} />
      }
    }) : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: contracts?.data.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery]);

  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">{t("contracts:contracts")}</div>
            <div className="flex w-5/6">
              <Input
                placeholder={t("search")}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mr-2 ml-8 bg-white"
              />
            </div>
            {!isUserLoading && user.data.roles !== 'CLIENT' && (<ContractFilter
              key={JSON.stringify(filters)}
              currentFilter={unprocessedFilter}
              onFilterChange={
                (filters, unprocessedFilter) => {
                  setFilters(filters);
                  setUnprocessedFilter(unprocessedFilter)
                  setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: 0, rowsPerPage: pagination.pageSize }))
                  setPagination({ pageIndex: 0, pageSize: rowsPerPage })
                }
              } />)}
            {!isUserLoading && user.data.roles !== 'CLIENT' && (
              <Link to='/contracts/create'>
                <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                  {t("button_create")}
                </Button>
              </Link>
            )}
          </div>
        </div>
        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border">
            {!isLoading && (
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center m-[0px]"
                      >
                        {t("no_results")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  )
}
