import { useEffect, useRef, useState } from "react";

export default function OutsideHook(initialIsVisible) {
    const [isShow,setIsShow] = useState(initialIsVisible)
    const [isShowCross,setIsShowCross] = useState(initialIsVisible)

    const ref = useRef(null)
    const refCross =useRef(null)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)){
            setIsShow(false)
        }
    }

    const handleClickOutsideCross = (event) => {
        if (refCross.current && !refCross.current.contains(event.target)){
            setIsShowCross(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click',handleClickOutside, true)
        return () => {
            document.removeEventListener('click',handleClickOutside, true)
        }
    })
    useEffect(() => {
        document.addEventListener('click',handleClickOutsideCross, true)
        return () => {
            document.removeEventListener('click',handleClickOutsideCross, true)
        }
    })

    return [ref,isShow,setIsShow,refCross,isShowCross,setIsShowCross]
}