import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { INVOICE_TYPE, INVOICE_TYPES, PAGINATION_SETTINGS, RECORD_INVOICE_TYPE } from '../constants';
import { getInvoices, getSelectedInvoicesExel, getUser } from '../service/weexpertService';

import { InvoiceClientReportPopup } from '@/components/popups/invoice.client.report.popup';
import { InvoiceFilter } from '@/components/popups/invoice.filter.popup';
import LanguageSelectorForPdfDowloadNew from '@/components/popups/invoice.pdf.download.popup';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { useDebounce } from "@/components/ui/multiple-selector";
import { Skeleton } from "@/components/ui/skeleton";
import { DataTablePagination } from '@/components/ui/table-pagination';
import qs from "qs";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { Badge } from "@/components/ui/badge";
import Cookies from "js-cookie";

export const useQueryState = (query, cookiesKey = query) => {
  const location = useLocation()
  const history = useNavigate()

  const getSavedQuery = (query, cookiesKey) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true })[query];
    if (!search) {

      const search = qs.parse(Cookies.get(cookiesKey), { ignoreQueryPrefix: true })[query];
      if (!search) {
        return null
      }
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      const queryString = qs.stringify(
        { ...existingQueries, [query]: search },
        { skipNulls: true }
      )
      if (!location.search)
        history(`${location.pathname}?${queryString}`)
      return search
    }
    return search
  }

  const setQuery = useCallback(
    value => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      const queryString = qs.stringify(
        { ...existingQueries, [query]: value },
        { skipNulls: true }
      )

      Cookies.set(cookiesKey, `${queryString}`);
      console.log(queryString)
      history(`${location.pathname}?${queryString}`)

    },
    [history, location, query]
  )

  return [
    getSavedQuery(query, cookiesKey),
    setQuery,
  ]
}



const InvoicesPage = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(null);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [sharedFilter, setSharedFilter] = useQueryState('f', 'invoice_filter')

  const [page, setPage] = useState(JSON.parse(sharedFilter ?? '{}').page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(sharedFilter ?? '{}').rowsPerPage ?? PAGINATION_SETTINGS.invoices);
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });
  const [filters, setFilters] = useState(JSON.parse(sharedFilter ?? '[]').f ?? []);
  const [unprocessedFilter, setUnprocessedFilter] = useState(JSON.parse(sharedFilter ?? '{}').uf ?? {})
  const [selectedItems, setSelectedItems] = useState([]);

  const columns = [
    {
      id: "select",
      header: () => (
        <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedItems.length === invoices?.data?.invoices?.length && selectedItems.length !== 0}
          onCheckedChange={() => handleSelectAllChange()}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedItems.includes(row.getValue("id"))}
          onCheckedChange={() => handleCheckboxChange(row.getValue("id"))}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false
    },
    {
      accessorKey: "id",
      header: t("invoices:document"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"block w-full h-full"}>
            {row.original.invoiceId}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "client",
      header: t("common:client"),
      cell: ({ row }) => (
        <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
          {row.original.client.firstName} {row.original.client.lastName}
        </Link>
      ),
      key: uuidv4()
    },

    {
      accessorKey: "Invoicetype",
      header: t("common:type"),
      cell: ({ row }) => {
        const getClassNameInvoiceType = (invoiceType) => {
          switch (invoiceType) {
            case INVOICE_TYPES.FAKTURA_KONCOWA:
              return 'bg-green-600'
            case INVOICE_TYPES.PREPAID_INVOICE:
              return 'bg-emerald-600'
            case INVOICE_TYPES.SALES_INVOICE:
              return 'bg-indigo-700'
            case INVOICE_TYPES.PROFORMA:
              return 'bg-sky-400'
            default:
              return 'bg-red-500'
          }
        }
        return <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
          <Badge className={getClassNameInvoiceType(row.original.invoiceType)}>
            {RECORD_INVOICE_TYPE[row.original.invoiceType](t)}
          </Badge>
        </Link>
      },
      key: uuidv4()
    },
    {
      accessorKey: "counterparty",
      header: t("common:counterparty"),
      cell: ({ row }) => (
        <div className="capitalize">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {row.original.counterparty}
          </Link>
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "state",
      header: t("common:status"),
      cell: ({ row }) => (
        // TODO FIX THIS SHIT
        <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
          <div className={`capitalize w-24 ${row.getValue("state") === 'NOT_APPROVED' ? 'bg-pirpleLightBg rounded text-pirpleText text-center' : 'bg-greenBgLite rounded text-center text-greenText'}`}>
            {row.getValue("state") === 'NOT_APPROVED' ? t("invoices:status_in_progress") : t("invoices:status_complete")}
          </div>
        </Link>
      ),
      key: uuidv4()
    },

    {
      accessorKey: "date of creation",
      header: t("invoices:date_of_creation"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {format(new Date(row.original.createdAt), 'dd/MM/yyyy')}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "date of sale",
      header: t("invoices:date_of_sale"),
      cell: ({ row }) => {
        return <div className="inline-block w-full h-full">

          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {format(new Date(row.original.domainDate), 'dd/MM/yyyy')}
          </Link>
        </div>
      },
      key: uuidv4()
    },
    {
      accessorKey: "total brutto",
      header: t("invoices:price_brutto"),
      cell: ({ row }) => {
        return <div className="inline-block	w-full h-full">
          <Link to={`/admin/invoices/details/${row.original.id}`} className={"capitalize block w-full h-full"}>
            {parseFloat(row.original.invoiceBrutto).toFixed(2)} {row.original.paymentCurrency}
          </Link>
        </div>
      },
      key: uuidv4()
    }

  ]

  const { data: invoices, isLoading } = useQuery({ queryKey: ['invoice', page, filters, rowsPerPage, debouncedSearchQuery], queryFn: () => getInvoices(rowsPerPage, page * rowsPerPage, debouncedSearchQuery, Object.values(filters)) });

  const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : prevSelectedItems.concat(itemId);
    });
  };

  const handleSelectAllChange = () => {
    if (selectedItems.length == invoices?.data?.invoices.length) {
      setSelectedItems([])
      return
    }
    setSelectedItems(invoices?.data?.invoices.map((item) => item.id));
  };

  const handleDownloadDelectedInvoicesToExel = (invoicesArray) => {
    getSelectedInvoicesExel(invoicesArray)
  }

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: pagination.pageIndex, rowsPerPage: pagination.pageSize }))
  }, [pagination])



  const table = useReactTable({
    data: isLoading ? Array(rowsPerPage).fill({}) : invoices.data.invoices,
    columns: isLoading ? columns.map(column => {
      return {
        ...column,
        cell: () => <Skeleton className={"h-4 w-[150px]"} />
      }
    }) : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: isLoading ? 0 : invoices.data?.total,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  useEffect(() => {
    if (debouncedSearchQuery) {
      setPage(0);
    }
  }, [debouncedSearchQuery]);

  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">{t('invoices:title')}</div>
            <div className="flex w-5/6">
              <Input
                placeholder={t("invoices:search")}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mr-2 ml-8 bg-white"
              />

              <>
                {selectedItems.length >= 1 && !isUserLoading && (
                  <LanguageSelectorForPdfDowloadNew
                    selectedItems={selectedItems}
                  />
                )}
                {selectedItems.length >= 1 && !isUserLoading && user.data.roles !== 'CLIENT' && (
                  <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                    onClick={() => handleDownloadDelectedInvoicesToExel(selectedItems)}
                  >
                    Excel
                  </Button>
                )}
              </>
              {!isUserLoading && user.data.roles !== "CLIENT" && (<InvoiceClientReportPopup />)}

              <Link to='/admin/invoices/create'>
                <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                  {t('invoices:create_title')}
                </Button>
              </Link>

              {!isUserLoading && user.data.roles !== 'CLIENT' && (<InvoiceFilter
                key={JSON.stringify(filters)}
                currentFilter={unprocessedFilter}
                onFilterChange={
                  (filters, unprocessedFilter) => {
                    setFilters(filters);
                    setUnprocessedFilter(unprocessedFilter)
                    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: 0, rowsPerPage: pagination.pageSize }))
                    setPagination({ pageIndex: 0, pageSize: rowsPerPage })
                  }
                } />)}
            </div>
          </div>
        </div>

        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination table={table} />
        </div>
      </div>
    </>

  );
}

export default InvoicesPage;
