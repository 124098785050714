import { Loader } from "@/components/ProtectedRoute";
import RelatedClientsTable from "@/components/clients/RelatedClientsTable";
import { Button } from "@/components/ui/button";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { deleteSharedClientPool, getSharedClientPool } from "@/service/weexpertService";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

export default function SharedClientPool() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: sharedPool, isLoading } = useQuery({ queryKey: 'sharedClientPool', queryFn: () => getSharedClientPool(id), retry: false })
  if (isLoading) {
    return <Loader />
  }
  return <>
    <div className="relative bg-grayLightMainBg px-8">
      <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
        <div className="flex justify-between py-8 items-center ">
          <div className="text-4xl ">
            {t("client_access_transfer")}
          </div>
          <div>
            <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
              deleteSharedClientPool(id)
                .then(() => {
                  navigate('/admin/user/client-shared-pool')
                  toast({
                    title: t('success')
                  })
                })
            }}>
              {t('button_delete')}
            </Button>
          </div>
        </div>
      </div>
      <div className='w-full py-8'>
        <div className='w-1/3'>
          <InputWithAlert
            label={t('name')}
            readOnly={true}
            value={sharedPool.data.pool.name ?? ''}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
        <div className='w-1/3'>
          <InputWithAlert
            label={t('author')}
            readOnly={true}
            value={`${sharedPool.data.pool.author.firstName} ${sharedPool.data.pool.author.lastName}`}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
        <div className='w-1/3'>
          <InputWithAlert
            label={t('assistant')}
            readOnly={true}
            value={`${sharedPool.data.pool.user.firstName} ${sharedPool.data.pool.user.lastName}`}
            disabled
            inputClassName={'h-12 mt-2 mb-4 bg-white'}
          />
        </div>
      </div>
      <div className='w-full pb-16'>
        <RelatedClientsTable entity={sharedPool.data} showSelect={false} counterparty={false} handleRefreshData={() => {}} showAction={false} />
      </div>
    </div >

  </>;
}
