import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { PAGINATION_SETTINGS, ROLES } from '../constants.jsx';
import { getClientIncomeReport, getClients, getClientsExcel  } from '../service/weexpertService';
import { useTranslation } from 'react-i18next';
import ClientFilter from '@/components/popups/client.filter.popup.jsx';
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { DataTablePagination } from '@/components/ui/table-pagination.jsx';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { Input } from '@/components/ui/input.jsx';
import { Button } from '@/components/ui/button.jsx';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';
import { useDebounce } from "@/components/ui/multiple-selector";
import { Badge } from '@/components/ui/badge.jsx';
import { format } from 'date-fns';

import { Checkbox } from '@/components/ui/checkbox';
import ClientRestorePasswordPopup from '@/components/popups/client.password-restore.popup.jsx';
import { useQueryState } from './invoices.page.jsx';

const ClientsPage = (props) => {

  const { t } = useTranslation();

  const [sharedFilter, setSharedFilter] = useQueryState('f', 'client_filter')
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [page, setPage] = useState(JSON.parse(sharedFilter ?? '{}').page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(sharedFilter ?? '{}').rowsPerPage ?? PAGINATION_SETTINGS.clients);

  const [filters, setFilters] = useState(JSON.parse(sharedFilter ?? '[]').f ?? []);
  const [unprocessedFilter, setUnprocessedFilter] = useState(JSON.parse(sharedFilter ?? '{}').uf ?? {});

  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });

  const { data: clients, isLoading } = useQuery({ queryKey: ['clients', page, rowsPerPage, debouncedSearchQuery, filters], queryFn: () => getClients(rowsPerPage, page * rowsPerPage, debouncedSearchQuery, Object.values(filters)) })

  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: pagination.pageIndex, rowsPerPage: pagination.pageSize }))
  }, [pagination])


  const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : prevSelectedItems.concat(itemId);
    });
  };

  const handleSelectAllChange = () => {
    if (selectedItems.length == clients?.data?.clients.length) {
      setSelectedItems([])
      return
    }
    setSelectedItems(clients?.data?.clients.map((item) => item.id));
  };


  const columns = [
    {
      id: "select",
      header: () => (
        <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedItems.length === clients?.data?.clients?.length}
          onCheckedChange={() => handleSelectAllChange()}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedItems.includes(row.original.id)}
          onCheckedChange={() => handleCheckboxChange(row.original.id)}
          aria-label="Select row"
        />
      },
      enableSorting: false,
      enableHiding: false
    },
    {
      accessorKey: "name",
      header: t('name_last_name'),
      cell: ({ row }) => {
        return (<Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block"}>
          {row.original.firstName} {row.original.lastName}
        </Link>)
      },
      key: uuidv4()
    },
    {
      accessorKey: "email",
      header: 'Email',
      cell: ({ row }) => (
        <Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block"}>
          {row.original.email}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "phone",
      header: t('phone'),
      cell: ({ row }) => (
        <Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block"}>
          {row.original.phone}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "status",
      header: t('status'),
      cell: ({ row }) => (
        <Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block"}>
          {row.original.isActive ?
            (<Badge className={"bg-green-500"}>{t('active')}</Badge>) :
            (<Badge className={"bg-rose-500"}>{t('unactive')}</Badge>)
          }
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "dateOfCreation",
      header: t('created'),
      cell: ({ row }) => (
        <Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block"}>
          {format(new Date(row.original.createdAt), 'dd/MM/yyyy')}
        </Link>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "assistant",
      header: t('assistant'),
      cell: ({ row }) => (
        <Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block capitalize"}>
          {row.original.user?.firstName} {row.original.user?.lastName}
        </Link>
      ),
      key: uuidv4()
    },
    {
      header: 'Onboarding',
      cell: ({ row }) => {
        return (<Link to={`/admin/client/details/${row.original.id}`} className={"w-full h-full block"}>
          <Checkbox checked={!!row.original.clientProfile} />
        </Link>)
      },
      key: uuidv4()
    }
  ]

  const table = useReactTable({
    data: isLoading ? Array(rowsPerPage).fill({}) : clients.data.clients,
    columns: isLoading ? columns.map((column) => { return { ...column, cell: () => <Skeleton /> } }) : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: clients?.data.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery]);

  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">
              {t("clients")}
            </div>
            <div className="flex w-5/6">
              <Input
                placeholder={t('search')}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mr-2 ml-8 bg-white"
              />
              {[ROLES.SUPER_ADMIN, ROLES.ASSISTANT].includes(props.userData.roles) &&
                (<Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                  onClick={() => {
                    getClientsExcel()
                  }}
                >
                  {t("clients")} Excel
                </Button>)
              }

              {[ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.HEAD_OF_DEPARTMENT].includes(props.userData.roles) &&
                (<Link to='/admin/create/client'>
                  <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" data-testid="create_client_button">
                    {t('clients:button_add')}
                  </Button>
                </Link>)
              }
              {[ROLES.SUPER_ADMIN].includes(props.userData.roles) &&
                (
                  <Button className=" mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" data-testid="create_client_button"
                    onClick={() => {
                      getClientIncomeReport()
                        .then((res) => {
                          toast({
                            title: t('alerts:success')
                          })
                        })
                    }}
                  >
                    Income report
                  </Button>
                )
              }


              {selectedItems.length > 0 && (
                <ClientRestorePasswordPopup clientIds={selectedItems} />
              )}
              {props.userData && (
                <ClientFilter
                  key={JSON.stringify(filters)}
                  onFilterChanged={(filters, unprocessedFilter) => {
                    setFilters(filters);
                    setUnprocessedFilter(unprocessedFilter)
                    setSharedFilter(JSON.stringify({ f: filters, uf: unprocessedFilter, page: 0, rowsPerPage: pagination.pageSize }))
                    setPagination({ pageIndex: 0, pageSize: rowsPerPage })
                  }}
                  currentFilter={unprocessedFilter}
                  userData={props.userData} />
              )}
            </div>
          </div>
        </div>

        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border p-2">
            {(
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No result
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination key={page + 'page'} table={table} />
        </div>
      </div>
    </>
  )
}
export default ClientsPage;
