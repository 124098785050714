import * as React from 'react';
import { Button } from '@/components/ui/button';
import { Alert } from "@mui/material";
import { useState, useEffect } from 'react';
import { newUser } from '../service/weexpertService';
import { useNavigate } from 'react-router-dom';
import { USERS_FORM_ACTION_EDIT } from '../components/users/UsersForm';
import { rolesForUsersCreate, validateOnlyLatin, validatePhone, validateEmail } from '../constants';
import UsersForm from '../components/users/UsersForm';
import { useTranslation } from 'react-i18next';

const UserCreatePage = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/admin/administration');
  };

  const [isFormValid, setIsFormValid] = useState(false);
  const [inputChecked, setInputChecked] = useState(false);
  const [alertMessage, setAllertMessage] = useState('');
  const [inputValueName, setInputValueName] = useState('');
  const [inputValueSurname, setInputValueSurname] = useState('');
  const [mail, setMail] = useState('');
  const [tel, setTel] = useState('');
  const [rolesUser, setRolesUser] = useState('');

  const cleanValues = (name, lastname, email, tel) => {
    name('');
    lastname('');
    email('');
    tel("");
  };

  const setAndValidateName = (newValue) => {
    const isValid = validateOnlyLatin(newValue)
    if (!isValid && newValue.length !== 0) {
      setAllertMessage(alertErrorIncorrectText)
    }
    else {
      setAllertMessage('')
    }
    setInputValueName(newValue)
  };

  const setAndValidateSurname = (newValue) => {
    const isValid = validateOnlyLatin(newValue)
    if (!isValid && newValue.length !== 0) {
      setAllertMessage(alertErrorIncorrectText)
    }
    else {
      setAllertMessage('')
    }
    setInputValueSurname(newValue)
  };

  const setAndValidatePhone = (newValue) => {
    const isValid = validatePhone(newValue)
    if (!isValid && newValue.length !== 0) {
      setAllertMessage(alertErrorTel)
    }
    else {
      setAllertMessage('')
    }
    setTel(newValue)
  };

  const setAndValidateEmail = (newValue) => {
    const isValid = validateEmail(newValue);
    setMail(newValue)
    if (!isValid && newValue.length !== 0) {
      setAllertMessage(alertErrorMail)
    } else
      setAllertMessage('')
  };

  useEffect(() => {
    if (
      alertMessage === '' &&
      inputValueName.trim() !== '' &&
      inputValueSurname.trim() !== '' &&
      tel.trim() !== '' &&
      mail.trim() !== '' &&
      rolesUser.trim() !== ''

    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    alertMessage,
    inputValueName,
    inputValueSurname,
    tel,
    mail,
    rolesUser
  ]);

  const onClickSendButtonHandler = () => {
    if (!isFormValid) {
      setAllertMessage(alertErrorSend);
      setInputChecked(true);
    }
    else {
      newUser(inputValueName, inputValueSurname, tel, mail, rolesUser)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setAllertMessage(alertSuccess);
            setTimeout(() => {
              setAllertMessage('');
              cleanValues(
                setInputValueName,
                setInputValueSurname,
                setMail,
                setTel
              );
            }, 1500)

            setInputChecked(false);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (
              error.response.data &&
              error.response.data.length &&
              error.response.data[0].email === "email alredy taken"
            ) {
              setAllertMessage(alredyTaken);
            } else {
              setAllertMessage(alertFail);
            }
            console.log('Ошибка: Неверные данные, код 400');

          } else {
            console.error(error);
          }
        });
    }
  };

  const alertErrorIncorrectText = <Alert severity="error">{t("alerts:error_words_lang_wrong_input")}</Alert>;
  const alertErrorMail = <Alert severity="error">{t("alerts:error_format_email")}</Alert>;
  const alertSuccess = <Alert severity="success">{t("alerts:success_change_data")}</Alert>;
  const alertFail = <Alert severity="error">{t("alerts:error_send_fail")}</Alert>;
  const alertErrorTel = <Alert severity="error">{t("alerts:error_phone_validation")}</Alert>;
  const alertErrorSend = <Alert severity="error">{t("alerts:error_invoice_state")}</Alert>;
  const alredyTaken = <Alert severity="error">{t("alerts:error_mail_allready_token")}</Alert>;

  return (
    <div className="relative bg-grayLightMainBg px-8">
      <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
        <div className="flex justify-between py-8 items-center ">
          <div className=" w-5/12  text-4xl ">
            {t("users:new_user_title")}
          </div>
          <div className='w-6/12 flex justify-end'>
            <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
              handleNavigate();
            }} >
              {t("button_back")}
            </Button>
            <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
              onClickSendButtonHandler();
            }}>
              {t("button_add")}
            </Button>
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div className='w-full py-4'>{alertMessage}</div>
        <UsersForm
          action={USERS_FORM_ACTION_EDIT}
          inputChecked={inputChecked}
          firstName={inputValueName}
          setFirstName={setAndValidateName}
          lastName={inputValueSurname}
          setLastName={setAndValidateSurname}
          email={mail}
          setEmail={setAndValidateEmail}
          phone={tel}
          setPhone={setAndValidatePhone}
          role={rolesUser}
          setRole={setRolesUser}
          rolesData={rolesForUsersCreate}
          user={true}
          mailEdit={true}
        />
      </div>
    </div>
  )
}
export default UserCreatePage;
