import { BaseDialog } from "./dialog"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { laguagesForPdfDownload } from "@/constants";
import { Button } from "../ui/button";
import { DownloadIcon } from "@radix-ui/react-icons";
import { getInvoiceById, getSelectedInvoicePdf, getSelectedInvoicesPdf } from "@/service/weexpertService";
import { SelectWithAlert } from "../ui/select-with-alert";
import { toast } from "../ui/use-toast";

export default function LanguageSelectorForPdfDowloadNew({ selectedItems, className, icon }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const [mainLanguage, setMainLanguage] = useState('pl');
  const [optionalLanguage, setOptionalLanguage] = useState(null);
  useEffect(() => {
    if (mainLanguage === optionalLanguage && mainLanguage !== null) {
      setOptionalLanguage((prevOptionLanguage) => (prevOptionLanguage === 'pl' ? 'en' : 'pl'));
    }
  }, [mainLanguage, optionalLanguage]);

  const handleChangeMainLanguage = (newMainLanguage) => {
    if (optionalLanguage === newMainLanguage) {
      setOptionalLanguage((prevOptionLanguage) => (prevOptionLanguage === 'pl' ? 'en' : 'pl'));
    }
    setMainLanguage(newMainLanguage);
  };

  const handleChangeOptionLanguage = (newOptionLanguage) => {
    if (mainLanguage === newOptionLanguage) {
      setMainLanguage((prevMainLanguage) => (prevMainLanguage === 'pl' ? 'en' : 'pl'));
    }
    setOptionalLanguage(newOptionLanguage);
  };

  return <BaseDialog
    dialogTitle={t("invoices:pdf_modal_capture")}
    buttonText={"PDF"}
    open={open}
    setOpen={setOpen}
    className={className}
    icon={icon}
  >
    <div className="w-[500px] flex flex-col items-end ">
      <div className="w-full flex  py-8 justify-between">
        <SelectWithAlert inputClassName='h-12 my-2 w-[49%]' value={mainLanguage} onChangeValue={(e) => {
          handleChangeMainLanguage(e)
        }}
          options={laguagesForPdfDownload(t).map(i => {
            return {
              value: i.id,
              label: i.value
            };
          })}
        />
        <SelectWithAlert inputClassName='h-12 my-2 w-[49%]' value={optionalLanguage} onChangeValue={(e) => {
          handleChangeOptionLanguage(e)
        }}
          options={laguagesForPdfDownload(t).map(i => {
            return {
              value: i.id,
              label: i.value
            };
          })}
        />
      </div>
      <Button onClick={() => {
        if (selectedItems.length === 1) {
          return getInvoiceById(selectedItems[0])
            .then(result => {
              const { invoiceId, invoiceType, id } = result.data;
              getSelectedInvoicePdf(id, mainLanguage, optionalLanguage, invoiceType, invoiceId)
              setOpen(false)

            }).catch(() => {
              toast({
                title: "Oops something went wrong",
                description: "Downdloading of pdf failed, try again or contact support",
                variant: 'destructive'
              })
            })
        }
        getSelectedInvoicesPdf(selectedItems, mainLanguage, optionalLanguage)
        setOpen(false)
      }} ><DownloadIcon className="h-[16px] mr-2" /> {t("common:download")}</Button>
    </div>
  </BaseDialog>
}
