import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultipleSelector from "../ui/multiple-selector";
import { BaseDialog } from "./dialog"
import { Button } from "@/components/ui/button"
import { getBills, getClients, getContracts } from "@/service/weexpertService";
import { DatePicker } from "../ui/date-picker";
import { CheckIcon } from "@radix-ui/react-icons";
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";
import MultipleSelectorWithSearch from "../ui/multiple-selector-with-search";
import { REPRESENTATIVE_COMPANY_LIST, transformFromIdValueToValueLabel } from "@/constants";


export default function BillFilter(props) {

    const { t } = useTranslation();
    const { currentFilter } = props
    const [open, setOpen] = useState(false)

    const [filters, setFilters] = useState([]);
    const [unprocessedFilters, setUnprocessedFilter] = useState({
        selectedClients: [],
        selectedBillsId: [],
        dateOfCreateFrom: null,
        dateOfCreateTo: null,

    });

    const [selectedClients, setSelectedClients] = useState(currentFilter?.selectedClients ?? []);
    const [selectedBillsId, setSelectedBillsId] = useState(currentFilter?.selectedBillsId ?? []);
    const [representativeCompany, setRepresentativeCompany] = useState(currentFilter?.representativeCompany ?? []);

    const [dateOfCreateFrom, setDateOfCreateFrom] = useState(currentFilter.dateOfCreateFrom ? new Date(currentFilter.dateOfCreateFrom) : null);
    const [dateOfCreateTo, setDateOfCreateTo] = useState(currentFilter.dateOfCreateTo ? new Date(currentFilter.dateOfCreateTo) : null);
    const [dateOfCreatePeriod, setDateOfCreatePeriod] = useState([dateOfCreateFrom, dateOfCreateTo]);

    const [dateOfConclusionFrom, setDateOfConclusionFrom] = useState(currentFilter.dateOfConclusionFrom ? new Date(currentFilter.dateOfConclusionFrom) : null);
    const [dateOfConclusionTo, setDateOfConclusionTo] = useState(currentFilter.dateOfConclusionTo ? new Date(currentFilter.dateOfConclusionTo) : null);
    const [dateOfConclusionPeriod, setDateOfConclusionPeriod] = useState([dateOfConclusionFrom, dateOfConclusionTo]);

    const transformAutocompleteValueToFilter = (valueArray, field) => {
        return {
            field: field,
            value: valueArray.map(e => e.id)
        }
    }


    useEffect(() => {
        setDateOfCreatePeriod([dateOfCreateFrom, dateOfCreateTo])
    }, [dateOfCreateFrom, dateOfCreateTo])

    useEffect(() => {
        setDateOfConclusionPeriod([dateOfConclusionFrom, dateOfConclusionTo])
    }, [dateOfConclusionFrom, dateOfConclusionTo])

    useEffect(() => {
        const clientsFilter = transformAutocompleteValueToFilter(selectedClients, 'client');
        const billsIdFilter = transformAutocompleteValueToFilter(selectedBillsId, 'contractId');
        const representativeCompanyFilter = transformAutocompleteValueToFilter(representativeCompany, 'representativeCompany')

        setUnprocessedFilter({
            selectedClients,
            selectedBillsId,
            dateOfCreateFrom,
            dateOfCreateTo,
            dateOfConclusionFrom,
            dateOfConclusionTo,
            representativeCompany
        })
        setFilters({
            client: clientsFilter,
            contractId: billsIdFilter,
            representativeCompany: representativeCompanyFilter,
            createdAt: {
                field: 'createdAt',
                value: dateOfCreatePeriod.filter(e => e).length == 2 ?
                    dateOfCreatePeriod.filter(e => e).map(e => format(e, 'yyyy-MM-dd')) : []
            },
            conclusionDate: {
                field: 'conclusionDate',
                value: dateOfConclusionPeriod.filter(e => e).length == 2 ?
                    dateOfConclusionPeriod.filter(e => e).map(e => format(e, 'yyyy-MM-dd')) : []
            },
        });
    }, [
        selectedClients,
        selectedBillsId,
        dateOfConclusionPeriod,
        dateOfCreatePeriod,
        representativeCompany
    ]);

    const isFilterNotEmpty = (filterData) => {
        return (Object.values(filterData).filter(e => e && (e && e.length > 0)).length > 0)
    }

    return (
        <BaseDialog
            dialogTitle={t('filter')}
            buttonText={t('filter')}
            open={open}
            setOpen={setOpen}
            icon={isFilterNotEmpty(currentFilter) ? <CheckIcon /> : null}
            ommitStyles={isFilterNotEmpty(currentFilter)}
        >
            <div className="w-[600px] pt-1 pb-[80px] px-2">
                <MultipleSelectorWithSearch
                    className="min-h-12"
                    placeholder={t("selected")}
                    badgeClassName="bg-emerald-100 text-black"
                    value={selectedClients}
                    label={t("clients")}
                    onChange={(newSelected) => {
                        setSelectedClients(newSelected)
                    }}
                    fetchOption={(q) => {
                        return getClients(10, 0, q)
                            .then((result) => {
                                return result.data.clients
                                    .map(client => {
                                        return {
                                            label: `${client.firstName} ${client.lastName}`,
                                            value: `${client.firstName} ${client.lastName}`,
                                            id: client.id
                                        }
                                    })
                            });
                    }}
                />
                <MultipleSelectorWithSearch
                    className="min-h-12"
                    placeholder={t("selected")}
                    badgeClassName="bg-emerald-100 text-black"
                    value={selectedBillsId}
                    label={t("bills:contract_id")}
                    onChange={(newSelected) => {
                        setSelectedBillsId(newSelected)
                    }}
                    fetchOption={(q) => {
                        return getContracts(10, 0, q)
                            .then((result) => {
                                return result.data.contracts.map(e => {
                                    return {
                                        label: e.contractId,
                                        value: e.contractId,
                                        id: e.id
                                    }
                                })
                            })
                    }}
                />
                <div>
                    <MultipleSelector
                        className="min-h-10 mt-1 mb-2"
                        placeholder={t('selected')}
                        badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
                        value={representativeCompany}
                        label={t("representative_company")}
                        onChange={(newSelected) => {
                            setRepresentativeCompany(newSelected)
                        }}
                        options={REPRESENTATIVE_COMPANY_LIST
                            .map(transformFromIdValueToValueLabel)
                            .map(item => {
                                return {
                                    id: item.value,
                                    value: item.label,
                                    label: item.label
                                }
                            })
                        }
                    />
                </div>

                <div className="flex justify-between pb-4">
                    <div className="w-[48%]">
                        <DatePicker date={dateOfCreateFrom}
                            defaultMonth={dateOfCreateFrom ?? new Date()}
                            setDate={(e) => setDateOfCreateFrom(e)}
                            label={t("invoices:date_of_creation_from")}
                        />
                    </div>
                    <div className="w-[48%]">
                        <DatePicker date={dateOfCreateTo}
                            defaultMonth={dateOfCreateTo ?? new Date()}
                            setDate={(e) => setDateOfCreateTo(e)}
                            label={t("invoices:date_of_creation_to")}
                        />
                    </div>

                </div>
                <div className="flex justify-between pb-4">
                    <div className="w-[48%]">
                        <DatePicker date={dateOfConclusionFrom}

                            defaultMonth={dateOfConclusionFrom ?? new Date()}
                            setDate={(e) => setDateOfConclusionFrom(e)}
                            label={t("bills:conclusion_date_from")}
                        />
                    </div>
                    <div className="w-[48%]">
                        <DatePicker date={dateOfConclusionTo}
                            defaultMonth={dateOfConclusionTo ?? new Date()}
                            setDate={(e) => setDateOfConclusionTo(e)}
                            label={t("bills:conclusion_date_to")}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col items-end">
                <div className="w-full flex justify-end">
                    <Button className="mr-4" variant="outline" onClick={() => {
                        props.onFilterChanged([], {})
                        setOpen(false)
                    }}>{t("common:button_clean_filter")}</Button>
                    <Button onClick={() => {
                        props.onFilterChanged(filters, unprocessedFilters);
                        setOpen(false)
                    }}>{t('button_save')}
                    </Button>
                </div>
            </div>
        </BaseDialog>
    );
}
