import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";

const AccountInfo = (props) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    if (props.userData) {
      setLoading(false)
    }

  }, [props.userData])

  return (
    <>
      {loading ? (
        <Box >
          <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="grey" />
          </Stack>
        </Box>
      ) : (
        <div className="w-full text-center	">
          <div className="text-base mb-2">{`${props.userData.firstName}  ${props.userData.lastName}`}</div>
          <div className="text-sm opacity-70 pb-2">
            {props.userData.roles === 'SUPER_ADMIN' ? t("users:SUPER_ADMIN") : (props.userData.roles === 'ADMIN' ? t("users:ADMIN") : (props.userData.roles === 'HEAD_OF_DEPARTMENT' ? t("users:HEAD_OF_DEPARTMENT") : (props.userData.roles === 'ASSISTANT' ? t("users:ASSISTANT") : t('client'))))}
          </div>
        </div>
      )}
    </>

  )

}
export default AccountInfo
