import { Loader } from "@/components/ProtectedRoute"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "@/components/ui/use-toast";
import { SpecificationForm } from "@/components/forms/specifications.form"
import { editSpecification, getSpecificaiton, getUser } from "@/service/weexpertService"
import { format } from "date-fns";

export const SpecificationEditPage = (props) => {
    const { id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { data: specification, isLoading, isFetched } = useQuery({
        queryKey: ['specification', id], queryFn: () => {
            return getSpecificaiton(id)
        }
    })

    const { data: user, isLoading: isUserLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })

    const onSaved = (
        contract,
        description,
        functionality,
        signatory,
        stages
    ) => {
        if (!contract
            || !description
            || !functionality || stages.length < 1) {
            return
        }
        return editSpecification({
            specificationId: id,
            contract: contract.id,
            description,
            functionality,
            signatory,
            stages: stages.map(e => {
                const processedId = typeof e.id == 'number' ? e.id : null
                return {
                    ...e,
                    id: processedId,
                    order: +e.order,
                    grossAmount: +e.grossAmount,
                    endDate: e.endDate ? format(new Date(e.endDate), 'yyyy-MM-dd') : null
                }
            })
        })
            .then(response => {
                if (response.status === 200) {
                    toast({
                        title: t("alerts:success"),
                        description: t("alerts:success_change_data"),
                    })
                    navigate('/specifications/')
                }
            })
            .catch(() => {
                toast({
                    title: t("alerts:toast_error_invoice_title"),
                    description: "alerts:toast_error_invoice_description",
                    variant: "destructive"
                })
            })
    }
    if (isLoading || !isFetched) {
        return <Loader />
    }
    if (isUserLoading) {
        return <Loader />
    }
    if (user.data.roles === 'CLIENT') {
        navigate('/404')
    }

    return (
        <SpecificationForm
            onSaved={onSaved}
            action="edit"
            initialData={specification.data}
        />
    );
}
