import React from "react";
import { useState, useEffect } from "react";
import AppBarForLogin from "../app-bar-for-main-login-pages/AppBarForLogin";
import { Link, useParams } from 'react-router-dom';
import { restorePasswordComplete } from '../../service/weexpertService';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import logo_login from '../../assets/img/logo_full.png';
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Alert, AlertDescription} from "@/components/ui/alert";
import { Checkbox } from "@/components/ui/checkbox";

export const RestorePass = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path);
    };
    useEffect(() => {
        if (Cookies.get('token')) {
            Cookies.remove('token');
           
        }
    }, []);
    const { parameter } = useParams();

    const [alertMessage, setAllertMessage] = useState('');
    const alertMessagehandler = (value) => {
        setAllertMessage(value)
    }

    const [consent, setConsent] = useState(false);
    const [confirmCode, setConfirmCode] = useState('');

    useEffect(() => {
        setConfirmCode(parameter);
    }, [parameter]);

    const [pass, setPass] = useState('');
    const setPassHandler = (event) => {
        setPass((prev) => {
            prev = event.target.value;
        
            return prev;
        });
    };

    const [confirmPass, setConfirmPass] = useState('');
    const handleInputConfirmPass = (event) => {
        const value = event.target.value;
        setConfirmPass(value);
     
    };

    useEffect(() => {
        if (pass === confirmPass) {
            alertMessagehandler('')
        }
        else {
            alertMessagehandler(t("alerts:error_password_mismatch"))
        }

    }, [pass, confirmPass]);

    const [redirect, setRedirect] = useState(false)

    useEffect(() => {
        if (redirect) {
            handleNavigate('/')
        }
    }, [redirect, handleNavigate])

    const onClickSendButtonHandler = () => {
        if (consent && pass !== '' && confirmPass !== '' ) {
            try {
                restorePasswordComplete(pass, confirmCode)
                    .then((response) => {
                        alertMessagehandler(t("alerts:success_send"));
                        setTimeout(() => {
                            setRedirect(true);
                        }, 1300)
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 400) {
                            alertMessagehandler(t("alerts:error_send_fail"));
                        } else {
                            console.error(error);
                        }
                    });
            } catch (e) {
                console.log(e);

            }
        } else if (!consent) {
            alertMessagehandler(t("survey:error_agree"));
        } else {
            alertMessagehandler(t('alerts:toast_error_invoice_description'));
         
        }
    };

    return (
        <>

            <AppBarForLogin />
            <form  onSubmit={(e) => {e.preventDefault();}} className="flex flex-row justify-between	">
                <div className="h-screen flex justify-center w-3/6 bg-[url('../src/assets/img/login_bg.png')] bg-no-repeat	bg-cover bg-center	items-center text-white">
                    <div className=" w-4/6">
                        <div className="text-6xl py-10	">
                        {t("login:tagline_capture")}
                        </div>
                        <div className="text-lg	opacity-70		">
                        {t("login:tagline_description")}
                        </div>
                    </div>
                </div>
                <div className="h-screen w-3/6 flex flex-col justify-center items-center">
                    <div><img src={logo_login} alt="" /></div>
                    <div className="pb-8 opacity-70">{t("login:set_password")}</div>
                    <div className="w-3/6 pb-4">
                        <div className="pb-1">
                            <Label htmlFor="email">{t("login:login_label")}</Label>
                        </div>
                        <Input
                            type='password'
                            value={pass}
                            onChange={setPassHandler}
                            className="h-12"
                        />
                    </div>
                    <div className="w-3/6 pb-4">
                        <div className="pb-1">
                            <Label htmlFor="email">{t("users:confirm_pass")}</Label>
                        </div>
                        <Input
                            type='password'
                            value={confirmPass}
                            onChange={handleInputConfirmPass}
                            className="h-12"
                        />
                    </div>
                    {alertMessage && (
                        <div className="w-3/6 pb-4">
                            <Alert variant="destructive">
                                <AlertCircle className="h-4 w-4" />

                                <AlertDescription>
                                    {alertMessage}
                                </AlertDescription>
                            </Alert>
                        </div>
                    )}
                    <div className="w-3/6 flex pb-5 flex-row justify-start items-start space-x-2">
                        <Checkbox id="terms" onClick={() => setConsent(!consent)} />
                        <label
                            htmlFor="terms"
                            className="text-sm font-regular leading-none peer-disabled:cursor-not-allowed peer-disabled opacity-70"
                        >
                            {t("login:policy_message_1")} <Link className="font-medium" to="https://weexpert.io/terms-conditions/" target="_blank">{t("login:terms")}</Link>  {t("login:policy_message_2")} <Link className="font-medium" to="https://weexpert.io/privacy-policy/" target="_blank">{t("login:policy_link")}</Link> 
                        </label>
                    </div>
                    <div className="pt-5 w-3/6 flex flex-row justify-start items-center ">
                        <Button type="submit" className='w-full' onClick={() => {
                            onClickSendButtonHandler();
                        }}>{t("users:restore")}</Button>
                    </div>
                </div>
            </form>
        </>
    );
}
