import i18n from "i18next";
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "ru",
//    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    defaultNS: "common",
    ns: ['invoices', 'translation', 'clients', 'alerts', 'common', 'users','navigation','counterparties', 'constants','survey','login','contracts','bills','specifications','reports'],
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backendOptions: [{
      /* options for secondary backend */
      loadPath: '/locales/{{lng}}/{{ns}}.json' // http load path for my own fallback
    }]
  });

export default i18n;
