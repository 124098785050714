import { Button } from "@/components/ui/button";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { SelectWithAlert } from "@/components/ui/select-with-alert";
import { toast } from "@/components/ui/use-toast";
import {
  REPRESENTATIVE_COMPANY_LIST,
  transformFromIdValueToValueLabel,
} from "@/constants";
import {
  getBankReportXLSX,
  getReport,
  getUser,
  performBankReportCheck,
} from "@/service/weexpertService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tabs } from "@/components/ui/tabs";
import { format } from "date-fns";
import { Loader } from "lucide-react";
import { FileSelector } from "./file-selector.component";

const RelatedTalbes = (props) => {
  const { t } = useTranslation();
  const { raportId } = props;

  return (
    <>
      <Tabs defaultValue="unmatched_transaction">
        <div className="relative">
          <div className="sticky left-0 top-16 z-10 w-9/12 bg-grayLightMainBg">
            <div className="flex justify-between py-8 items-center bg-grayLightMainBg">
              <div className="relative flex justify-between w-full bg-grayLightMainBg">
                <Button asChild variant="outline">
                  <Link to={`/report/${raportId}/unmatched-transactions`}>
                    {t("reports:unmatched_transaction")}
                  </Link>
                </Button>
                <Button asChild variant="outline">
                  <Link to={`/report/${raportId}/unmatched-invoices`}>
                    {t("reports:unmatched_invoices")}
                  </Link>
                </Button>

                <Button asChild variant="outline">
                  <Link to={`/report/${raportId}/matched-transactions`}>
                    {t("reports:matchet_transaction")}
                  </Link>
                </Button>
                <Button asChild variant="outline">
                  <Link to={`/report/${raportId}/matched-invoices`}>
                    {t("reports:matched_invoices")}
                  </Link>
                </Button>
                <Button asChild variant="outline">
                  <Link to={`/report/${raportId}/all-transactions`}>
                    {t("reports:report_transactions")}
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </>
  );
};

const ReportViewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { data: report, isLoading } = useQuery({
    queryKey: ["report", id],
    queryFn: () => getReport(id),
    retry: false,
  });

  const { data: user, isLoading: isUserLoading } = useQuery({
    queryKey: "userMe",
    queryFn: getUser,
    retry: false,
  });

  const reportData = report?.data?.report;
  const userRole = user?.data.roles;
  if (userRole === "CLIENT") {
    navigate("/404");
  }
  return (
    <>
      {isUserLoading ? (
        <Loader />
      ) : (
        <div className="w-full relative bg-grayLightMainBg px-8">
          <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
            <div className="flex justify-between pt-8  items-center ">
              <div className=" w-5/12  text-4xl ">
                {t("reports:report_title") + ":"} {id}
              </div>
              <div>
                <div className="flex justify-between">
                  <Button
                    className="mr-4"
                    onClick={() => {
                      toast({
                        title: "wait for check to be performed",
                      });
                      getBankReportXLSX(id).then(() => {
                        toast({
                          title: t(
                            "alerts:toast_succes_invoice_create_description",
                          ),
                          description: t(
                            "alerts:toast_succes_invoice_create_description",
                          ),
                        });
                      });
                    }}
                  >
                    {t("reports:download_report")}
                  </Button>

                  <Button
                    className="mr-4"
                    onClick={() => {
                      toast({
                        title: "wait for check to be performed",
                      });
                      performBankReportCheck(id)
                        .then((response) => {
                          if (response.status === 200) {
                            toast({
                              title: t(
                                "alerts:toast_succes_invoice_create_description",
                              ),
                              description: t(
                                "alerts:toast_succes_invoice_create_description",
                              ),
                            });
                          }
                        })
                        .catch((error) => {
                          toast({
                            title: t("alerts:toast_error_counterparty_title"),
                            description: t(
                              "alerts:toast_error_invoice_description",
                            ),
                            variant: "destructive",
                          });
                        });
                    }}
                  >
                    {t("reports:perform_match")}
                  </Button>
                  <Link to="/reports" className="mr-4">
                    <Button className="min-w-20">{t("button_back")}</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {!isLoading && (
            <>
              <div className="pt-16">
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t("counterparties:name")}
                    value={reportData.title}
                    disabled={true}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t("reports:date_from")}
                    value={format(new Date(reportData.fromDate), "dd.MM.yyyy")}
                    disabled={true}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <InputWithAlert
                    label={t("reports:date_to")}
                    value={format(new Date(reportData.toDate), "dd.MM.yyyy")}
                    disabled={true}
                  />
                </div>
                <div className="w-4/12 pb-4">
                  <SelectWithAlert
                    label={t("clients:representative_company")}
                    value={reportData.representativeCompany}
                    options={REPRESENTATIVE_COMPANY_LIST.map(
                      transformFromIdValueToValueLabel,
                    )}
                    inputClassName={"h-12 mt-2 mb-4 bg-white"}
                    disabled={true}
                  />
                </div>
              </div>
              {userRole !== "ASSISTANT" && userRole !== "CLIENT" && (
                <div className="w-4/12 pt-2 pb-2 ">
                  <FileSelector setFile={setFile} file={file} id={id} />
                </div>
              )}
              <RelatedTalbes raportId={id} />
            </>
          )}
        </div>
      )}
    </>
  );
};
export default ReportViewPage;
