import React from "react";
import { useParams } from "react-router-dom";
import { getNewOneFreelancer } from "@/service/weexpertService";
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { formatDateString } from "@/styles";
import { useQueryClient } from "react-query";
import { InputWithAlert } from "@/components/ui/input-with-alert";
import { Link } from "react-router-dom";
import { formatDistanceStrict, parseISO } from "date-fns";

function NewFreelancerViewComponentw({ userData, id }) {
  const profile = userData?.profile;
  const { t } = useTranslation();
  const [freelancerProfile, setFrilancerProfile] = useState(true);
  const [jobTitle, setJobTitle] = useState(profile.jobTitles.join(", "));
  const [experience, setExperience] = useState("");
  const [selectedProgrammingLanguages, setSelectedProgrammingLanguages] =
    useState(profile.knowleges.join(", "));
  const [languages, setLanguages] = useState(profile.languages || []);
  const [links, setLinks] = useState(profile.links || []);

  const languagesContent = languages.map((lang, index) => (
    <div key={index} className="pb-1">
      <InputWithAlert
        value={`${lang.code}: ${lang.proficiency}`}
        placeholder={t("survey:placeholder_languages")}
        disabled={true}
        inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
      />
    </div>
  ));

  const linksContent = (
    <div>
      {links.map((link, index) => (
        <div key={index} className="my-2">
          <span className=" text-sm font-medium">{link.platform}: </span>
          <Link
            to={link.link}
            className="text-blue-500 underline"
            target="_blank"
          >
            {link.link}
          </Link>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (profile && profile.experienceDate) {
      const experienceDate = parseISO(profile.experienceDate);
      const now = new Date();
      const experienceText = formatDistanceStrict(experienceDate, now, {
        addSuffix: false,
      });
      setExperience(experienceText);
    }
  }, [profile]);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex justify-between pt-[10px] ">
        <div className="w-6/12 pl-8">
          <div className="w-72 text-4xl">
            {`${profile && profile.client.firstName} ${profile && profile.client.lastName}`}
          </div>
          <div className="w-full relative flex flex-row justify-between items-end">
            <div className="w-72 ">
              <div className="pt-8 flex justify-between items-center">
                <Button
                  className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none ${freelancerProfile ? "opacity-70" : ""}`}
                  onClick={() => setFrilancerProfile(false)}
                >
                  {t("users:main_information")}
                </Button>
                <Button
                  className={`text-blackText text-sm bg-transparent hover:bg-transparent shadow-none ${freelancerProfile ? "" : "opacity-70"}`}
                  onClick={() => setFrilancerProfile(true)}
                >
                  {t("users:freelancer_profile")}
                </Button>
              </div>
            </div>
          </div>
          <div className="w-72 relative mt-2 h-1 bg-blueBorder">
            <div
              className={`absolute w-6/12 h-full ${freelancerProfile ? "right-0" : "left-0"} top-0 bg-blueLink`}
            ></div>
          </div>
          {freelancerProfile && profile ? (
            <div className="w-9/12 pt-10">
              <InputWithAlert
                label={t("survey:label_job_title")}
                value={jobTitle}
                placeholder={t("survey:label_job_title")}
                disabled={true}
                inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
              />
              <InputWithAlert
                label={t("survey:label_experience")}
                value={experience}
                placeholder={t("survey:label_experience")}
                disabled={true}
                inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
              />

              <InputWithAlert
                label={t("survey:label_programming_languages")}
                value={selectedProgrammingLanguages}
                placeholder={t("survey:placeholder_languages")}
                disabled={true}
                inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
              />
              <InputWithAlert
                label={"Hourly rate"}
                value={profile.hourlyRate}
                placeholder={"Hourly rate"}
                disabled={true}
                inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
              />
              <InputWithAlert
                label={"Monthly rate"}
                value={profile.monthlyRate}
                placeholder={"Monthly rate"}
                disabled={true}
                inputClassName={"h-12 my-2 focus-visible:border-[0px]"}
              />
              <div className="pb-1">
                <div className="text-sm font-medium">
                  {t("survey:placeholder_languages")}
                </div>
                {languagesContent}
              </div>
              <div className="pb-10">
                <div className="text-sm font-medium">{"Links"}</div>
                {linksContent}
              </div>
            </div>
          ) : (
            <div className="w-full mt-0">
              <div className=" pt-10 flex ">
                <div className="w-6/12">
                  <div className="pb-2">{t("name")}</div>
                  <div className="mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60">
                    {profile && profile.client.firstName}
                  </div>
                  <div className="pb-2">{t("phone")}</div>
                  <div className="mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60">
                    {profile && profile.client.phone}
                  </div>
                  <div className="pb-2">
                    {t("clients:create_client_placeholder_date_of_birth")}
                  </div>
                  <div className="mr-2 mb-3 p-3 bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60">
                    {profile && formatDateString(profile.client.dateOfBirth)}
                  </div>
                </div>
                <div className="w-6/12 ml-2">
                  <div className="pb-2 pl-2">{t("last_name")}</div>
                  <div className=" p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60">
                    {profile && profile.client.lastName}
                  </div>
                  <div className="pb-2 pl-2">Email</div>
                  <div className="p-3 mb-3  bg-grayBg rounded-lg border-[1px] border-grayBorder box-border overflow-hidden opacity-60">
                    {profile && profile.client.email}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <Link to={"/candidates"}>
            <Button className="mr-10 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
              назад
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
export default function CandidatePage(props) {
  const { id } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["freelacer", id],
    queryFn: () => getNewOneFreelancer(id),
  });
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <div className="pt-10">error'</div>;
  }
  return (
    <div className="pt-10">
      <NewFreelancerViewComponentw id={id} userData={data.data} />
    </div>
  );
}
