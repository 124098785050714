import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table"
import * as React from "react";
import { memo } from "react";
import { v4 as uuidv4 } from 'uuid';
import { UnpinClientFromContractor } from '../admin-page-components/admin-modal-components/modal-pages-for-option-administration/ActionInMapUnassignClient';
import { formatDateString } from "../../styles";
import { useTranslation } from 'react-i18next';
import ScrollToTop from '../ScrollToTop';
import { unassignClientFromContractors } from "@/service/weexpertService";
import UnpinClientFromUser from "../admin-page-components/administration-tables-controls-components/ActionDeleteClientFromUser";
import { Checkbox } from "../ui/checkbox";
import { useState } from 'react'
const RelatedClientsTable = memo((props) => {

  const { t } = useTranslation();
  const { entity, handleRefreshData, counterparty, onSelectChanged, showAction = true, showSelect = false } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const handleSelectAllChange = () => {
    if (selectedItems.length == entity.clients.length) {
      setSelectedItems([])
      return
    }
    setSelectedItems(entity.clients.map((item) => item.id));
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : prevSelectedItems.concat(itemId);
    });
  };
  React.useEffect(() => {
    if (onSelectChanged)
      onSelectChanged(selectedItems)
  }, [selectedItems]);


  const columns = [
    {
      id: "select1",
      header: () => (
        showSelect ?
          <Checkbox className='flex flex-row items-center justify-center py-0'
            checked={selectedItems.length === entity.clients.length && selectedItems.length !== 0}
            onCheckedChange={() => handleSelectAllChange()}
            aria-label="Select all"
          /> : <></>
      ),
      cell: ({ row }) => {
        return showSelect ?
          <Checkbox className='flex flex-row items-center justify-center py-0'
            checked={selectedItems.includes(row.original.id)}
            onCheckedChange={() => handleCheckboxChange(row.original.id)}
            aria-label="Select row"
          /> : <></>
      },
      enableSorting: false,
      enableHiding: false
    },

    {
      accessorKey: "name",
      header: t("name"),
      cell: ({ row }) => (
        <div>
          {row.original.firstName + ' ' + row.original.lastName}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "email",
      header: "email",
      cell: ({ row }) => (
        <div>
          {row.original.email}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "phone",
      header: t("phone"),
      cell: ({ row }) => (
        <div>
          {row.original.phone}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "status",
      header: t("status"),
      cell: ({ row }) => (
        <div>
          {row.original.isActive ? "true" : "false"}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "dateOfCreation",
      header: t("created"),
      cell: ({ row }) => (
        <div>
          {formatDateString(row.original.createdAt)}
        </div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "actions",
      header: t("action"),
      cell: ({ row }) => (
        showAction ? <>
          {counterparty ? (
            <UnpinClientFromContractor
              header={t("unassign_client_to_counterparty")}
              subHeader={row.original.name}
              onConfirm={() => {
                unassignClientFromContractors(entity.id, row.original.id).
                  then(() => {
                    handleRefreshData()
                  })
              }}
            />
          ) : (
            <UnpinClientFromUser
              handleRefreshData={handleRefreshData}
              name={row.original.firstName + ' ' + row.original.lastName}
              id={row.original.id}
              selfData={row}
            />
          )}
        </> : <></>
      )
    }

  ]
  const table = useReactTable({
    data: entity.clients ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full rounded-md border p-2 h-[300px] box-border overflow-y-scroll	">
      {entity && (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={uuidv4()}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead className="pt-3 pb-3"
                      key={uuidv4()}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell className="relative text-sm pt-3 pb-3"
                      key={uuidv4()}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className='pt-4 text-center'
                  colSpan={columns.length}

                >
                  {t("no_data")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      <ScrollToTop />
    </div>
  )
})
export default RelatedClientsTable
